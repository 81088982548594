import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Immutable from "immutable";

import Modal from "~/components/Modal";
import Button from "~/components/Button";
import PhotoGalleryContainer from "~/routes/Order/components/OrderActions/PhotoGallery";
import { addAttachment, deleteAttachment } from "~/routes/Order/modules/Order";
import AttachmentThumbnail from "~/routes/Order/components/OrderActions/AttachmentGallery/components/AttachmentThumbnail";
import LoaderContainer from "~/components/Loader";

class AttachmentGallery extends Component {
  static propTypes = {
    orderId: PropTypes.number,
    hasPhotoService: PropTypes.bool,
    isFetching: PropTypes.bool,
    hasFetched: PropTypes.bool,
    isUploading: PropTypes.bool,
    attachments: PropTypes.instanceOf(Immutable.List),
    addAttachments: PropTypes.func,
    addAttachment: PropTypes.func,
    deleteAttachment: PropTypes.func
  };

  static defaultProps = {};

  state = {
    modalIsOpen: false,
    attachmentId: 0,
    files: []
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  onChangeFileUpload = e => {
    const files = Array.from(e.target.files);
    Promise.all(
      files.map(f => {
        f.isUploading = true;
        return this.props.addAttachment(this.props.orderId, f).then(r => {
          f.isUploaded = true;
          this.setState({
            files: this.state.files.filter(f => !f.isUploaded)
          });
          return r;
        });
      })
    );
    this.setState({
      files: this.state.files.concat(files)
    });
  };

  onCancelModal = () => {
    this.setState({
      modalIsOpen: false,
      attachmentId: 0
    });
  };

  onClickFileUpload = () => {
    this.inputRef.current.click();
  };

  onDeleteAttachment = id => {
    console.log("onDeleteAttachment", id);
    this.setState({
      modalIsOpen: true,
      attachmentId: id
    });
  };

  onYesDeleteAttachment = () => {
    this.setState({
      modalIsOpen: false
    });
    this.props.deleteAttachment(this.props.orderId, this.state.attachmentId).then(() => {
      this.setState({
        attachmentId: 0
      });
    });
  };

  render() {
    if (this.props.isFetching || !this.props.hasFetched) {
      return <LoaderContainer />;
    }
    const attachments = this.props.attachments.filter(attachment => !attachment.get("title").startsWith("WBP_"));
    return (
      <div>
        <h2>Bijlages</h2>
        {this.props.hasPhotoService && <PhotoGalleryContainer />}
        {this.props.hasPhotoService && <h2>Overige documenten</h2>}
        <div className="mb-3 AttachmentGallery__Images">
          <Modal isOpen={this.state.modalIsOpen} onAccept={this.onYesDeleteAttachment} onCancel={this.onCancelModal}>
            <div>
              {this.state.attachmentId && this.state.modalIsOpen && (
                <div className="mb-3">
                  Weet u zeker dat u bestand &quot;
                  {this.props.attachments.find(a => a.get("id") === this.state.attachmentId).get("title")}&quot; wil
                  verwijderen?
                </div>
              )}
            </div>
          </Modal>
          {attachments.size === 0 && this.state.files.length === 0 && (
            <div className="alert alert-warning mb-3">Er zijn geen bijlages</div>
          )}
          {this.state.files.map(
            file =>
              !file.isUploaded && (
                <AttachmentThumbnail
                  key={file.name}
                  isUploading={file.isUploading}
                  type={file.type}
                  title={file.name}
                  src={URL.createObjectURL(file)}
                />
              )
          )}
          {attachments.map(attachment => (
            <AttachmentThumbnail
              key={attachment.get("id")}
              onDelete={this.onDeleteAttachment}
              deleteAttachment={this.props.deleteAttachment}
              isDeleting={this.state.attachmentId === attachment.get("id")}
              id={attachment.get("id")}
              title={attachment.get("title")}
              type={attachment.get("fileType")}
              srcParams="&height=48&width=48&zc=1&ttl=123456789fileType=auto"
              src={`/download/?id=${attachment.get("id")}`}
            />
          ))}
        </div>
        <input
          type="file"
          name="files"
          multiple
          style={{ display: "none" }}
          ref={this.inputRef}
          onChange={this.onChangeFileUpload}
        />
        <Button onClick={this.onClickFileUpload} disabled={this.props.isUploading}>
          <i className="fa fa-fw fa-cloud-upload mr-2" />
          Toevoegen
        </Button>
      </div>
    );
  }
}

export default connect(
  state => ({
    isUploading: state.order.getIn(["order", "isUploading"]),
    isFetching: state.order.getIn(["order", "isFetching"]),
    hasFetched: state.order.getIn(["order", "hasFetched"]),
    hasPhotoService: !!state.order.getIn(["order", "data", "extraService", "photo"], false),
    attachments: state.order.getIn(["order", "data", "attachments"], Immutable.List())
  }),
  {
    addAttachment,
    deleteAttachment
  }
)(AttachmentGallery);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { IndexLink, Link } from "react-router";
import { connect } from "react-redux";
import classes from "./Header.module.scss";
import Immutable from "immutable";
import { createSelector } from "reselect";

import SearchControl from "./components/SearchControl";
import Modal from "../../../../components/Modal";
import PrintCard from "../../../../components/Actions/PrintCard";
import { toggleModal } from "../../../../store/modules/App";
import { findOrderIdById } from "../../../../routes/Order/modules/Order";
import BarCodeScannerContainer from "~/layouts/AppLayout/components/Header/components/BarCodeScanner";
import { buildUrl } from "~/lib/RouteUtil";

// @TODO css media query for short/long title is better than using state?

export const AdminHeader = ({ findOrderIdById }) => (
  <div className="layout-row flex">
    <div className={`header-dropdown-menu ${classes.title}`}>Beheer</div>
    <div className="flex" />

    <div className={`header-dropdown-menu ${classes.photoService}`}>
      <div>
        <BarCodeScannerContainer findOrderIdById={findOrderIdById} />
      </div>
    </div>

    <div className={classes.buttons}>
      <Link to="/auth/logout">
        <div className="material-icons">power_settings_new</div>
      </Link>
    </div>
  </div>
);
AdminHeader.propTypes = {
  findOrderIdById: PropTypes.func.isRequired
};

export class AppHeader extends Component {
  static propTypes = {
    tags: PropTypes.object, // immutable.list
    userName: PropTypes.string, // immutable.list
    isAdministrator: PropTypes.bool,
    printRevivaLabelFeatureIsActive: PropTypes.bool,
    printTrunkrsLabelFeatureIsActive: PropTypes.bool,
    printEfCardFeatureIsActive: PropTypes.bool,
    printEfCardModalIsOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    findOrderIdById: PropTypes.func.isRequired,
    deliveryExpiredCount: PropTypes.number
  };

  state = {
    printEfCardModalIsOpen: false,
    hideMenu: true
  };

  onOpenEfCardModal = e => {
    this.props.toggleModal("printEfCard");
    this.onHideMenu();
    e.preventDefault();
    return false;
  };

  onCancelEfCardModal = () => {
    this.props.toggleModal("printEfCard");
  };

  onHideMenu = () => {
    this.setState({ hideMenu: true });
  };

  onShowMenu = () => {
    this.setState({ hideMenu: false });
  };

  onBarCodeDetected = result => {
    console.log("onBarCodeDetected", result);
  };

  render() {
    const { printEfCardModalIsOpen, tags, isAdministrator, deliveryExpiredCount } = this.props;

    return (
      <div className="layout-row flex">
        <div className={`header-dropdown-menu ${classes.title}`}>
          <div>
            <Link
              to="/order/t[unprocessed]"
              activeClassName="route-active"
              className={tags.includes("unprocessed") ? "route-active" : ""}
            >
              Nieuwe orders
            </Link>
          </div>
        </div>

        <div className={`header-dropdown-menu ${classes.title}`}>
          <div>
            <Link
              to="/order/t[processed]"
              activeClassName="route-active"
              className={tags.includes("processed") ? "route-active" : ""}
            >
              Verwerkte orders
            </Link>
          </div>
        </div>

        <div className={`header-dropdown-menu ${classes.title}`}>
          <SearchControl />
        </div>

        <div className={`header-dropdown-menu ${classes.title}`}>
          <div>
            <Link
              to="/order/t[deliveryexpired]"
              activeClassName="route-active"
              className={tags.includes("deliveryexpired") ? "route-active" : ""}
            >
              Verlopen bezorgingen ({deliveryExpiredCount})
            </Link>
          </div>
        </div>

        <div className="flex" />

        <div className={`header-dropdown-menu ${classes.photoService}`}>
          <div>
            <BarCodeScannerContainer findOrderIdById={this.props.findOrderIdById} />
          </div>
        </div>

        <div
          className={`header-dropdown-menu ${classes.extra_menu}`}
          onMouseEnter={this.onShowMenu}
          onMouseLeave={this.onHideMenu}
        >
          Extra <i className="fa fa-chevron-down" />
          <ul
            className={classes.header_dropdown_menu_ul}
            style={{ display: this.state.hideMenu ? "none" : "block" }}
            onMouseLeave={this.onHideMenu}
          >
            {this.props.printEfCardFeatureIsActive && <li onClick={this.onOpenEfCardModal}>Print EF kaart</li>}
            {this.props.printRevivaLabelFeatureIsActive && (
              <li onClick={this.onToggleRegisterRevivaModal}>
                <Link to="/shipment/reviva">Aanmelden bij Reviva</Link>
              </li>
            )}
            {this.props.printTrunkrsLabelFeatureIsActive && (
              <li onClick={this.onToggleRegisterTrunkrsModal}>
                <Link to="/shipment/trunkrs">Aanmelden bij Trunkrs</Link>
              </li>
            )}
            {__DEV__ && (
              <li onClick={this.onToggleCreateOrderModal}>
                <Link to={buildUrl({ id: "create" })}>Order inschieten</Link>
              </li>
            )}
          </ul>
        </div>

        <div className={`header-dropdown-menu ${classes.title}`}>
          <div className={classes.buttons}>
            {isAdministrator ? (
              <Link to="/admin">
                <div className="material-icons">settings</div>
              </Link>
            ) : null}
            <Link to="/auth/logout">
              <div className="material-icons">power_settings_new</div>
            </Link>
          </div>
        </div>

        <Modal
          isOpen={printEfCardModalIsOpen}
          onCancel={this.onCancelEfCardModal}
          title="Print EF kaart"
          buttons={false}
        >
          <PrintCard onAfterSubmit={this.onCancelEfCardModal} />
        </Modal>
      </div>
    );
  }
}

export const Header = ({
  printEfCardModalIsOpen,
  printEfCardFeatureIsActive,
  printRevivaLabelFeatureIsActive,
  printTrunkrsLabelFeatureIsActive,
  toggleModal,
  findOrderIdById,
  tags,
  deliveryExpiredCount,
  isAdminView,
  userName,
  isAdministrator
}) => (
  <div className={classes.container}>
    <div className={classes.appbar}>
      <div className={`${classes.buttons} ${classes.titleIcon}`}>
        <IndexLink to="/order/t[unprocessed]">
          <div className="material-icons">home</div>
        </IndexLink>
      </div>

      {isAdminView ? (
        <AdminHeader findOrderIdById={findOrderIdById} />
      ) : (
        <AppHeader
          printRevivaLabelFeatureIsActive={printRevivaLabelFeatureIsActive}
          printTrunkrsLabelFeatureIsActive={printTrunkrsLabelFeatureIsActive}
          printEfCardFeatureIsActive={printEfCardFeatureIsActive}
          printEfCardModalIsOpen={printEfCardModalIsOpen}
          toggleModal={toggleModal}
          tags={tags}
          deliveryExpiredCount={deliveryExpiredCount}
          isAdministrator={isAdministrator}
          userName={userName}
          findOrderIdById={findOrderIdById}
        />
      )}
    </div>
  </div>
);

Header.propTypes = {
  userName: PropTypes.string,
  isAdministrator: PropTypes.bool,
  printEfCardModalIsOpen: PropTypes.bool,
  printEfCardFeatureIsActive: PropTypes.bool,
  printRevivaLabelFeatureIsActive: PropTypes.bool,
  printTrunkrsLabelFeatureIsActive: PropTypes.bool,
  isAdminView: PropTypes.bool,
  tags: PropTypes.object, // immutable.list
  deliveryExpiredCount: PropTypes.number, // immutable.list
  orderId: PropTypes.number,
  titleLong: PropTypes.string,
  titleShort: PropTypes.string,
  titleTransient: PropTypes.string,
  toggleModal: PropTypes.func,
  findOrderIdById: PropTypes.func
};

const selectFeatureIsActive = createSelector(
  [state => state.app.features, (state, featureId) => featureId],
  (features, featureId) => {
    const feature = features.find(f => f.get("title") === featureId);
    if (feature) {
      return feature.get("extraAttribute_28088961");
    }
    return false;
  }
);

export default connect(
  state => ({
    printRevivaLabelFeatureIsActive: selectFeatureIsActive(state, "printRevivaLabel"),
    printTrunkrsLabelFeatureIsActive: selectFeatureIsActive(state, "printTrunkrsLabel"),
    printEfCardFeatureIsActive: selectFeatureIsActive(state, "printEfCard"),
    printEfCardModalIsOpen: state.app.getIn(["modals", "printEfCard"], false),
    userName: state.auth.getIn(["user", "name"], "[user]"),
    isAdministrator: state.auth.getIn(["user", "isAdministrator"], false),
    isAdminView: state.location.get("pathname").startsWith("/admin"),
    tags: state.location.getIn(["params", "tags"], new Immutable.List()),
    deliveryExpiredCount: state.order.getIn(["deliveryexpired", "data", 0, "count"], 0),
    orderId: 0,
    titleLong: state.app.get("titleLong"),
    titleShort: state.app.get("titleShort"),
    titleTransient: state.app.get("titleTransient")
  }),
  {
    toggleModal,
    findOrderIdById
  }
)(Header);

import Immutable from "immutable";
import moment from "lib/moment";
import request from "lib/Request";
import api from "lib/Api";

const INITIALIZE = "@app/auth/INITIALIZE";

const REQUEST_LOGIN = "@app/auth/REQUEST_LOGIN";
const RECEIVE_LOGIN = "@app/auth/RECEIVE_LOGIN";
const LOGIN_FAILED = "@app/auth/LOGIN_FAILED";
const REQUEST_LOGOUT = "@app/auth/REQUEST_LOGOUT";
const RECEIVE_LOGOUT = "@app/auth/RECEIVE_LOGOUT";
const LOGOUT_FAILED = "@app/auth/LOGOUT_FAILED";
const REQUEST_RESET = "@app/auth/REQUEST_RESET";
const RECEIVE_RESET = "@app/auth/RECEIVE_RESET";
const RESET_FAILED = "@app/auth/RESET_FAILED";

const REFRESH_TOKEN = "@app/auth/REFRESH_TOKEN";
const REFRESH_TOKEN_RECEIVE = "@app/auth/REFRESH_TOKEN_RECEIVE";
const REFRESH_TOKEN_FAILED = "@app/auth/REFRESH_TOKEN_FAILED";

export const refreshToken = token => dispatch => {
  dispatch({ type: REFRESH_TOKEN, payload: token });
  return api("/auth/token")
    .create({ token })
    .then(result => {
      const tokenStorage = sessionStorage;
      tokenStorage.setItem("token", JSON.stringify(result));
      dispatch({ type: INITIALIZE, payload: result });
    })
    .catch(err => {
      dispatch({ type: REFRESH_TOKEN_FAILED, payload: err });
    });
};

const getError = err => {
  let error = err.message;
  try {
    error = JSON.parse(err.res.text).message;
  } catch (e) {
    if (err.res && err.res.text) {
      error = err.res.text;
    }
    if (err.body && err.body.message) {
      error = err.body.message;
    }
  }
  return error;
};

/**
 *
 * @param {string} login
 * @param {string} password
 * @param {boolean} stayLoggedIn
 *
 * @returns {Function}
 */
export const requestLogin = (login, password, stayLoggedIn) => dispatch => {
  dispatch({
    type: REQUEST_LOGIN,
    payload: {
      login,
      password,
      stayLoggedIn
    }
  });

  // if (login === 'what' && password === 'thekingsquare') {
  //   dispatch({
  //     type: RECEIVE_LOGIN,
  //     payload: {
  //       created: moment(),
  //       expires: moment().add(356, 'days'),
  //       token: 'WHAT',
  //       stayLoggedIn
  //     }
  //   });
  //   return;
  // }

  return request
    .post("/gateway/api/auth/login")
    .type("form")
    .send({ login, password, stayLoggedIn })
    .then(result => {
      if (result.header["content-type"].indexOf("application/json") !== 0) {
        throw new Error("Invalid response");
      }
      const tokenStorage = localStorage; // (stayLoggedIn ? localStorage : sessionStorage);
      tokenStorage.setItem("token", JSON.stringify(result.body));
      dispatch({
        type: RECEIVE_LOGIN,
        payload: {
          ...result.body,
          stayLoggedIn
        }
      });
    })
    .catch(err => {
      dispatch({
        type: LOGIN_FAILED,
        payload: getError(err)
      });
    });
};

/**
 * @param {string} login
 *
 * @returns {function(*)}
 */
export const requestReset = login => dispatch => {
  dispatch({
    type: REQUEST_RESET,
    payload: { login }
  });
  return request
    .post("/gateway/api/auth/reset")
    .type("json")
    .send({ login })
    .then(res => {
      dispatch({
        type: RECEIVE_RESET,
        payload: res.body || { success: false }
      });
    })
    .catch(err => {
      dispatch({
        type: RESET_FAILED,
        payload: getError(err)
      });
    });
};

/**
 *
 * @returns {function(*)}
 */
export const requestLogout = () => dispatch => {
  dispatch({
    type: REQUEST_LOGOUT
  });

  localStorage.removeItem("token");
  sessionStorage.removeItem("token");

  return request
    .get("/gateway/api/auth/logout")
    .noCache()
    .then(
      result => {
        dispatch({
          type: RECEIVE_LOGOUT
        });
        // crude refresh
        // window.location = '/';
        // ...
      },
      err => {
        let error;
        try {
          error = JSON.parse(err.response.text).message;
        } catch (e) {
          error = err.message;
        }
        dispatch({
          type: LOGOUT_FAILED,
          payload: error
        });

        // dispatch(throwError(err));
      }
    );
};

const LOGOUT = "@app/auth/LOGOUT";
export const logout = () => ({
  type: LOGOUT,
  payload: request.post("/gateway/api/auth/logout").noCache()
});

/**
 *
 * @returns {{type: string, payload: {token}}}
 */
export const initialize = () => {
  if (__DEV_STUBBED__) {
    return {
      type: INITIALIZE,
      payload: {
        token: "fake_dev_token",
        created: moment().toDate(),
        expires: moment()
          .add(356, "days")
          .toDate()
      }
    };
  }

  const NOT_NEO = {
    type: INITIALIZE,
    payload: {}
  };

  // is there a spoon?
  let spoon = sessionStorage.getItem("token");
  if (__DEBUG__ && spoon) {
    console.log("Auth.Token::sessionStorage", spoon);
  }
  if (!spoon) {
    spoon = localStorage.getItem("token");
    if (__DEBUG__ && spoon) {
      console.log("Auth.Token::localStorage", spoon);
    }
  }

  console.log(spoon);
  if (!spoon) {
    if (__DEBUG__) {
      console.log("Auth.Token::null", spoon);
    }
    // there is no spoon
    return NOT_NEO;
  }

  console.log(spoon);
  const neo = JSON.parse(spoon);

  if (!neo.token || !neo.expires) {
    if (__DEBUG__) {
      console.log("Auth.Token is not a valid token object");
    }
    // so its not neo.. next iteration
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    return NOT_NEO;
  }

  // // neo knowing neo
  // if (moment(neo.expires).isBefore(moment())) {
  //   if (__DEBUG__) {
  //     console.log('Auth.Token has expired');
  //   }
  //   // neo did not realise himself on time..
  //   localStorage.removeItem('token');
  //   sessionStorage.removeItem('token');
  //   return NOT_NEO;
  // }

  // is neo.. wahey!
  return {
    type: INITIALIZE,
    payload: neo
  };
};

// Initial State
export const State = Immutable.Record({
  isLoggingIn: false,
  isLoggingOut: false,
  isLoggedOut: false,
  isResetting: false,
  isReset: false,
  error: null,
  user: Immutable.Map(),
  url: "",
  resetError: null,
  logoutError: null,
  token: null,
  created: null,
  expires: null
});

// Action handlers
function clearState(state) {
  return new State();
}
export const actionHandlers = {
  [LOGOUT](state, { status, payload }) {
    switch (status) {
      case "pending":
        return state.set("isLoggingOut", true).set("isLoggedOut", false);
      case "success":
        return state
          .set("isLoggingOut", false)
          .set("logoutError", null)
          .set("token", null);
      case "error":
        return state
          .set("isLoggingOut", false)
          .set("isLoggedOut", true)
          .set("logoutError", payload);
    }
  },
  [REQUEST_LOGIN](state, { payload }) {
    return clearState(state).set("isLoggingIn", true);
  },
  [REFRESH_TOKEN_RECEIVE](state, { payload }) {
    return clearState(state)
      .set("isLoggingIn", false)
      .set("token", payload.token)
      .set("url", payload.url)
      .set("user", Immutable.fromJS(payload.user))
      .set("created", payload.created)
      .set("expires", payload.expires);
  },

  [RECEIVE_LOGIN](state, { payload }) {
    return clearState(state)
      .set("isLoggingIn", false)
      .set("token", payload.token)
      .set("url", payload.url)
      .set("user", Immutable.fromJS(payload.user))
      .set("created", payload.created)
      .set("expires", payload.expires);
  },

  [INITIALIZE](state, { payload }) {
    // there is no token
    if (!payload.token) {
      return clearState(state);
    }

    return clearState(state)
      .set("isLoggingIn", false)
      .set("token", payload.token)
      .set("url", payload.url)
      .set("user", Immutable.fromJS(payload.user))
      .set("created", payload.created)
      .set("expires", payload.expires);
  },

  [LOGIN_FAILED](state, { payload }) {
    return clearState(state)
      .set("isLoggingIn", false)
      .set("error", payload);
  },

  [REQUEST_LOGOUT](state, { payload }) {
    return clearState(state).set("isLoggingOut", true);
  },

  [RECEIVE_LOGOUT](state, { payload }) {
    return clearState(state)
      .set("isLoggingOut", false)
      .set("isLoggedOut", true);
  },

  [LOGOUT_FAILED](state, { payload }) {
    return clearState(state.set("isLoggingOut", false)).set("error", payload);
  },

  [REQUEST_RESET](state, { payload }) {
    return state
      .set("isResetting", true)
      .set("isReset", false)
      .set("error", "");
  },

  [RECEIVE_RESET](state, { payload }) {
    return state
      .set("isResetting", false)
      .set("isReset", payload.success)
      .set("error", "");
  },

  [RESET_FAILED](state, { payload }) {
    return state
      .set("isResetting", false)
      .set("isReset", false)
      .set("error", payload);
  }
};

// reducer
export function reducer(state = new State(), action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  } else {
    return state;
  }
}

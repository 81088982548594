import Immutable from "immutable";
import api from "lib/Api";

// ------------------------------------
// Actions
// ------------------------------------

export const REGISTER_SHIPMENT = "@shipment/REGISTER";

// ------------------------------------
// Action Generators
// ------------------------------------

// ------------------------------------
// Initial State
// ------------------------------------
const State = new Immutable.Record({});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {};

// ------------------------------------
// Selectors
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

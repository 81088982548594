import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import { propTypes as ReduxFormPropTypes } from "redux-form";
import { Link } from "react-router";

import { FormHorizontal, Input } from "../../../components/Form/Bootstrap";
import Button from "../../../components/Button";
import classes from "./LoginView.module.scss";
import Loader from "~/components/Loader/Loader";
import LoaderContainer from "~/components/Loader";

// TODO fix for generic style; current button is not useable button
export class LoginView extends React.Component {
  static propTypes = {
    ...ReduxFormPropTypes,
    requestLogin: PropTypes.func.isRequired,
    isLoggedOut: PropTypes.bool.isRequired,
    error: PropTypes.string
  };

  handleSubmit = data => this.props.requestLogin(data.login, data.password, true);

  renderError() {
    if (this.props.token && this.props.isAuthExpired) {
      return (
        <div className={`row ${classes.error}`}>
          <div className="col-xs-12">
            <div className="alert alert-danger">Uw sessie is verlopen</div>
          </div>
        </div>
      );
    }
    if (this.props.isLoggedOut) {
      return (
        <div className={`row ${classes.error}`}>
          <div className="col-xs-12">
            <div className="alert alert-success">U bent uitgelogd</div>
          </div>
        </div>
      );
    }

    if (!this.props.authError) {
      return null;
    }
    return (
      <div className={`row ${classes.error}`}>
        <div className="col-xs-12">
          <div className="alert alert-danger">{this.props.authError}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <FormHorizontal name={this.props.form} onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <div className={classes.icon}>
          <img src="/img/boeketcadeau2014/sprite/logo.png" alt="BoeketCadeau" />
        </div>

        <div className={`layout-column ${classes.container}`}>
          <Input type="text" name="login" placeholder="Gebruikersnaam" />
          <Input type="password" name="password" placeholder="Wachtwoord" />
          <Button
            type="submit"
            style={{ padding: "10px", height: 36, marginRight: 0 }}
            disabled={this.props.pristine || this.props.submitting}
          >
            {this.props.submitting ? <LoaderContainer color="white" spinnerSize={16} /> : "Inloggen"}
          </Button>
          {this.renderError()}
        </div>

        <div className={`layout-column ${classes.centered}`}>
          <Link to="/auth/reset">Wachtwoord vergeten?</Link>
        </div>
      </FormHorizontal>
    );
  }
}

export default hot(module)(LoginView);

import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";

import moment, { isAfterTodayTwelvePm } from "~/lib/moment";
import Immutable from "~/lib/Immutable";
import Paper from "../../../components/Paper";
import Loader, { SmallLoader } from "../../../components/Loader";
import Button from "../../../components/Button";
import EditableBlock from "./EditableBlock";
import { Input, Select, TextArea, FormSection, SubmissionError } from "../../../components/Form";
import { buildUrl, getParams } from "~/lib/RouteUtil";
import OrderItems from "./OrderDetail/OrderItems";
import TransportButtons from "./OrderDetail/TransportButtons";
import SendMailButton from "./OrderDetail/SendMailButton";
import CardButton from "./OrderDetail/CardButton";
import TransportFeedbackMails from "./OrderDetail/TransportFeedbackMails";
import { PAYMENT_METHOD_IDS_ON_ACCOUNT } from "~/lib/constants";
import InternalLog from "./OrderDetail/InternalLog";

import "./OrderDetail.scss";
import classes from "./OrderDetail.module.scss";

import ExtraAttributeToggle from "./OrderDetail/ExtraAttributeToggle";

const buildActionUrl = action => buildUrl({ action: action });

export const Wrapper = props => (
  <div className={classes.Wrapper}>
    <Paper className={`${classes.OrderDetail} ${props.className}`} title2={props.orderId}>
      {props.children}
    </Paper>
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.any,
  orderId: PropTypes.any,
  className: PropTypes.any
};

export const ActionButtons = props => {
  const isFlorist = props.order.get("treatment") === "Collega bloemist";
  // const orderId = props.order.get('id');

  return (
    <div>
      <div className="layout-row margin-bottom-5">
        <Button onClick={props.onPrintOrder} disabled={props.printing.get("order", false)} className="flex-1">
          {props.printing.get("order", false) ? <SmallLoader /> : null}
          Order printen
        </Button>
        <Button linkTo={buildActionUrl("sendToEuroFloristEmail")} className="flex-1">
          Euroflorist (email)
        </Button>
      </div>
      <div className="layout-row margin-bottom-5">
        <Button linkTo={buildActionUrl(isFlorist ? "viewFlorist" : "sendToColleague")} className="flex-1">
          {isFlorist ? "Bekijk collega bloemist" : "Collega bloemist"}
        </Button>
        <Button className="flex-1" disabled={props.isRegioImporting} onClick={props.sendToRegio}>
          {props.isRegioImporting ? <SmallLoader /> : null}
          Omgeving printen
        </Button>
      </div>
      <div className="layout-row margin-bottom-5">
        <Button className="flex-1" disabled={props.isEuroFloristImporting} onClick={props.onEuroFloristImport}>
          {props.isEuroFloristImporting ? <SmallLoader /> : null}
          Euroflorist (import)
        </Button>
        <Button className="flex-1" disabled={props.isEuroFlorist2Importing} onClick={props.onEuroFloristImport2}>
          {props.isEuroFlorist2Importing ? <SmallLoader /> : null}✓ Euroflorist (import)
        </Button>
      </div>
    </div>
  );
};

ActionButtons.propTypes = {
  printing: PropTypes.object, // immutable.map
  isPrinting: PropTypes.bool.isRequired,
  sendToRegio: PropTypes.func.isRequired,
  isRegioImporting: PropTypes.bool.isRequired,
  onPrintOrder: PropTypes.func.isRequired,
  isEuroFloristImporting: PropTypes.bool.isRequired,
  onEuroFloristImport: PropTypes.func.isRequired,
  isEuroFlorist2Importing: PropTypes.bool.isRequired,
  onEuroFloristImport2: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
};

export class Address extends React.Component {
  getCountryCodeOptions = () =>
    this.props.countryCodes
      .keySeq()
      .map(key => ({
        value: key,
        label: this.props.countryCodes.get(key)
      }))
      .toArray();

  render() {
    return (
      <FormSection name={this.props.name} className="address">
        <div className="address-streetgroup">
          <Input placeholder="Straat" name="street" editing={this.props.editing} />
          <Input placeholder="Straatnummer" name="streetNumber" editing={this.props.editing} />
        </div>
        <div className="address-locationgroup">
          <Input placeholder="Postcode" name="zipcode" editing={this.props.editing} />
          <Input placeholder="Plaats" name="city" editing={this.props.editing} />
        </div>
        {this.props.editing ? (
          <Select
            placeholder="Land"
            name="countryCode"
            editing={this.props.editing}
            options={this.props.editing ? this.getCountryCodeOptions() : []}
          />
        ) : (
          <Input name="country" editing={false} />
        )}
      </FormSection>
    );
  }
}

Address.propTypes = {
  name: PropTypes.string.isRequired,
  countryCodes: PropTypes.object, // immutable
  editing: PropTypes.bool
};

export const confirm = props => {
  toastr.confirm(
    <div style={{ margin: "-5%", position: "relative", top: 0, left: 0, textAlign: "left", width: "320px" }}>
      <h3 style={{ marginTop: 0, marginBottom: "5px", padding: "5px" }}>{props.title}</h3>
      <div
        style={{
          textAlign: "center",
          padding: "5px",
          paddingTop: "20px",
          paddingBottom: "20px",
          marginBottom: "5px"
        }}
      >
        {props.message}
      </div>
    </div>,
    {
      onOk: () => (props.onOk ? props.onOk() : () => {}),
      onCancel: () => (props.onCancel ? props.onCancel() : () => {})
    }
  );
};

export const toastError = (title, err) => {
  if (!err) {
    return;
  }
  toastr.error(title, err.body ? err.body.message : err.message);
};

export class OrderDetail extends React.Component {
  doFinalised = (orderId, message) => {
    // console.log("doFinalised", orderId);
    if (message) {
      toastr.success("Order is verwerkt", message);
    }
    const params = getParams();
    // console.log(orderId, params.id);
    if (orderId !== params.id) {
      // already editing something else so do not navigate away
      return;
    }
    const url = buildUrl({ id: -1, action: -1 });
    browserHistory.push(url.replace(/\/-1/gim, ""));
    this.props.scrollToTop();
  };

  print = (jobType, orderId) => {
    const printerId = this.props.configuredPrinters.get(jobType);

    if (printerId === "_popup") {
      window.open(
        `/print/${orderId}/${jobType}/${printerId}`,
        `${jobType}print`,
        "width=500,height=500,scrollbars=yes,toolbar=no"
      );
      return Promise.resolve(false);
    }

    if (!printerId || !this.props.availablePrinters.find(item => item.get("id") === printerId)) {
      confirm({
        title: "Configuratie fout",
        message: (
          <div>
            Er is geen of een onjuist printer geconfigureerd.
            <br />
            Wil je naar de locatie configuratie?
          </div>
        ),
        onOk: () => browserHistory.push("/admin/location")
      });
      return Promise.reject(new Error("Configuration error"));
    }
    return this.props
      .print(orderId, jobType, printerId)
      .then(success => ({
        success,
        orderId
      }))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        throw err;
      });
  };

  onPrintOrder = () =>
    this.print("order", this.props.orderId)
      .then(result => {
        console.log("result", result);
        if (result.success === false) {
          return;
        }
        this.doFinalised(result.orderId, 'Order is als "Geprint" in behandeling genomen');

        this.props.findOrders(
          this.props.location.getIn(["params", "tags"]),
          this.props.location.getIn(["params", "page"], 1),
          this.props.location.getIn(["query", "q"], ""),
          true
        );
      })
      .catch(err => {
        toastError("Kan de Order niet printen!", err);
      });

  jobTypes = {
    card1: "Kaartje 1",
    card2: "Kaartje 2",
    dynalogic: "Dynalogic label",
    postnl: "Post NL label",
    zorgeloosbezorgd: "ZorgeloosBezorgd label",
    redjepakketje: "Redjepakketje label",
    reviva: "Reviva label",
    trunkrs: "Trunkrs label",
    regio: "Regio label"
  };

  doAddToPrintQueue = jobType => {
    if (jobType === "dynafix") {
      jobType = "dynalogic";
    }
    return this.print(jobType, this.props.orderId)
      .then(result => {
        if (result.success === false) {
          return;
        }
        toastr.success(`${this.jobTypes[jobType]} is in de printer wachtrij`);
      })
      .catch(err => {
        toastError(`Kan de ${this.jobTypes[jobType]} niet printen!`, err);
      });
  };

  onPrintCard1 = () => this.doAddToPrintQueue("card1");

  onPrintCard2 = () => this.doAddToPrintQueue("card2");

  onSendToRegio = () => {
    const { orderId } = this.props;
    return this.props
      .sendToRegio(orderId, { id: orderId })
      .then(() =>
        this.print("order", orderId)
          .then(result => {
            this.doFinalised(result.orderId, "Order is als in behandeling door Regio geprint");
          })
          .catch(err => {
            toastError("Kan de Order niet printen!", err);
          })
      )
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        toastError("Kan niet als Regio markeren!", err);
        throw new SubmissionError({ _error: err.message });
      });
  };

  onEuroFloristImport1 = () =>
    this.props
      .sendToEuroFlorist(this.props.orderId)
      .then(data => {
        if (data.data.url) {
          window.open(data.data.url, "efImport", "width=550,height=800,scrollbars=yes,toolbar=no");
        }
        toastr.success("Order is in nieuwe popup in Euroflorist geimporteerd");
      })
      .catch(err => {
        if (err.errors && !__PROD__) {
          confirm({
            title: "Euroflorist importeren",
            message: err.errors.join("<br />")
          });
        }

        // eslint-disable-next-line no-console
        console.log(err);
        toastError("Kan niet naar EuroFlorist versturen!", err);
        throw new SubmissionError({ _error: err.message });
      });

  onEuroFloristImport2 = () => {
    const { orderId } = this.props;
    confirm({
      title: "Euroflorist markeren",
      message: "Weet u zeker dat u deze order als verwerkt en afgehandeld door Euroflorist wilt markeren?",
      onOk: () => {
        // eslint-disable-next-line no-console
        console.log("OK: clicked");
        this.props
          .markAsEuroFlorist(orderId)
          .then(() => {
            this.doFinalised(orderId, `Order ${orderId} als afgehandeld door EuroFlorist gemarkeerd`);
          })
          .catch(err => {
            toastError("Kan niet als afgehandeld door Euroflorist markeren", err);
            // eslint-disable-next-line no-console
            console.log(err);
            throw new SubmissionError({ _error: err.message });
          });
      },
      // eslint-disable-next-line no-console
      onCancel: () => console.log("CANCEL: clicked")
    });
  };

  renderActionButtons = () => (
    <ActionButtons
      printing={this.props.printing}
      location={this.props.location}
      order={this.props.order}
      isPrinting={this.props.isPrinting}
      onPrintOrder={this.onPrintOrder}
      sendToRegio={this.onSendToRegio}
      isRegioImporting={this.props.isRegioImporting}
      onEuroFloristImport={this.onEuroFloristImport1}
      isEuroFloristImporting={this.props.isEuroFloristImporting}
      onEuroFloristImport2={this.onEuroFloristImport2}
      isEuroFlorist2Importing={this.props.isEuroFlorist2Importing}
    />
  );

  onClickCamera = () => {
    browserHistory.push(buildActionUrl("addPhoto"));
  };

  render() {
    if (!this.props.orderId) {
      return <div className={`${classes.OrderDetail} ${this.props.className}`} />;
    }

    if (this.props.apiError) {
      return (
        <Wrapper {...this.props}>
          <div className="alert alert-danger">
            {this.props.apiError.message}
            {process.env.NODE_ENV === "development" && (
              <div>
                <details>
                  <pre>{this.props.apiError.stack}</pre>
                </details>
              </div>
            )}
          </div>
        </Wrapper>
      );
    }

    if (this.props.isFetching || !this.props.order || !this.props.order.size) {
      return (
        <Wrapper {...this.props}>
          <Loader text="Even geduld a.u.b. Gegevens worden geladen..." />
        </Wrapper>
      );
    }

    const { order } = this.props;

    const addressType = order.getIn(["recipient", "addressType"], "");
    const deliveryDateTimeString = !order.get("deliveryDateTime")
      ? ""
      : addressType === "church"
      ? "kerkdienst: "
      : "uitvaart: ";

    const deliveryStatus = this.props.attributes
      .filter(attr => attr.get("id") === 23276052)
      .getIn([0, "values"], new Immutable.Map());

    const activeTransporter = this.props.transporters.find(t => t.get("title") === order.get("activeTransporter"));

    return (
      <Wrapper {...this.props}>
        {this.renderActionButtons()}

        <div>
          {this.props.hasInternalLogEntries && (
            <div className="OrderDetail__OrderInformation__HasNotes alert alert-danger">
              <i className="fa fa-exclamation-triangle mr-3" />
              <div>Let op dit order heeft interne notities</div>
            </div>
          )}
          <div className="layout-row layout-align-start-stretch">
            <div className="flex-1 layout-column">
              <h3 className="OrderDetails_Block_title">Orderinformatie</h3>
              <div>
                Ordernummer: {order.get("orderNumber")} {order.get("secondChance", false) ? " (2nd Chance)" : ""}
              </div>
              <div>Besteldatum: {moment(order.get("orderDate")).format("dddd D MMMM YYYY")}</div>
            </div>
            <div className="flex-1 layout-column layout-align-end">
              {this.props.isMourning ? <div className={classes.deliveryTimeframe}>ROUW ORDER</div> : null}
              {this.props.isEveningDelivery ? <div className={classes.deliveryTimeframe}>AVOND LEVERING</div> : null}
              {this.props.order.get("hasPhotoService", false) ? (
                <div className={classes.extraServicePhoto} onClick={this.onClickCamera}>
                  FOTO SERVICE <i className="fa fa-fw fa-camera" data-tip="Foto toevoegen" />
                </div>
              ) : null}

              {activeTransporter && (
                <div className={classes.deliveryTransport}>
                  {activeTransporter.get("title", activeTransporter.get("id", "")).toUpperCase()}
                </div>
              )}
            </div>
          </div>
          <br />

          <EditableBlock
            isNew={this.props.isNew}
            title={`Bezorgdatum: ${moment(order.get("deliveryDate")).format("dddd D MMMM YYYY")}`}
            form="deliveryDate"
            initialValues={{ id: order.get("id"), deliveryDate: order.get("deliveryDate") }}
            className="normal red"
          >
            {this.props.isEditingDeliveryDate ? (
              <Input
                placeholder="Bezorgdatum"
                name="deliveryDate"
                type="date"
                isValidDate={isAfterTodayTwelvePm}
                editing={this.props.isEditingDeliveryDate}
              />
            ) : null}
          </EditableBlock>
          {order.get("deliveryDateTime") ? (
            <EditableBlock
              isNew={this.props.isNew}
              title={`Datum/tijd: ${deliveryDateTimeString}${order.get("deliveryDateTime")}`}
              form="deliveryDateTime"
              initialValues={{ id: order.get("id"), deliveryDateTime: order.get("deliveryDateTime") }}
              className="normal bold red"
            >
              {this.props.isEditingDeliveryDateTime ? (
                <Input
                  placeholder="Datum/tijd"
                  name="deliveryDateTime"
                  editing={this.props.isEditingDeliveryDateTime}
                />
              ) : null}
            </EditableBlock>
          ) : null}
          {order.get("customer").get("anonymous") ? (
            <div className={classes.Anonymous}>Deze order anoniem versturen.</div>
          ) : null}
        </div>

        <div>
          <h3>Bestelling</h3>
          <OrderItems order={order} />
        </div>

        {this.renderCardBlock()}

        <InternalLog orderId={order.get("id")} />

        <div className="margin-bottom-5">
          <h3>Extra opties</h3>
          <ExtraAttributeToggle
            title="Uitsluiten gastenboek uitnodiging ?"
            attributeId={28840131}
            orderId={this.props.orderId}
            value={this.props.order.get("excludeFromGuestbookInvitation")}
          />
          <ExtraAttributeToggle
            title="Toch foto service ?"
            attributeId={29836110}
            orderId={this.props.orderId}
            value={this.props.order.get("attribute_29836110")}
          />
        </div>

        <EditableBlock
          isNew={this.props.isNew}
          title="Klant opmerking"
          form="comment"
          initialValues={{ id: order.get("id"), comment: order.get("comment") }}
        >
          {this.props.isEditingComment ? (
            <TextArea placeholder="Opmerking" name="comment" editing={this.props.isEditingComment} />
          ) : (
            <div className={classes.CommentText}>
              {order.get("comment") ? order.get("comment") : <i>Geen opmerking</i>}
            </div>
          )}
        </EditableBlock>

        <EditableBlock
          isNew={this.props.isNew}
          title={`Bezorgadres (${order.get("recipient").get("type")})`}
          initialValues={{ id: order.get("id"), recipient: order.get("recipient").toJS() }}
          form="recipient"
        >
          {
            <div>
              <Input placeholder="Bedrijfsnaam" editing={this.props.isEditingRecipient} name="recipient.company" />
              <Input placeholder="Naam" editing={this.props.isEditingRecipient} name="recipient.name" />
              <Address
                editing={this.props.isEditingRecipient}
                name="recipient.address"
                countryCodes={this.props.countryCodes}
              />
              <Input
                placeholder="Telefoonnummer"
                nonEditingPrefix="Tel: "
                editing={this.props.isEditingRecipient}
                name="recipient.phoneNumber"
              />
            </div>
          }
        </EditableBlock>

        <TransportButtons onPrint={this.doAddToPrintQueue} order={this.props.order} />

        <EditableBlock
          isNew={this.props.isNew}
          title="Opdrachtgever"
          form="customer"
          initialValues={{ id: order.get("id"), customer: order.get("customer").toJS() }}
        >
          {
            <div>
              <Input placeholder="Bedrijfsnaam" editing={this.props.isEditingCustomer} name="customer.companyTitle" />
              <div className="customerName">
                <Select
                  placeholder="Aanhef"
                  editing={this.props.isEditingCustomer}
                  name="customer.name.gender"
                  options={[{ value: "U", label: "" }, { value: "M", label: "Dhr." }, { value: "F", label: "Mevr." }]}
                />
                <Input placeholder="Initialen" editing={this.props.isEditingCustomer} name="customer.name.initials" />
                <Input
                  placeholder="Tussenvoegsel"
                  editing={this.props.isEditingCustomer}
                  name="customer.name.middleName"
                />
                <Input placeholder="Achternaam" editing={this.props.isEditingCustomer} name="customer.name.lastName" />
              </div>
              <Address
                editing={this.props.isEditingCustomer}
                name="customer.address"
                countryCodes={this.props.countryCodes}
              />
              <Input
                placeholder="Telefoonnummer"
                nonEditingPrefix="Tel: "
                editing={this.props.isEditingCustomer}
                name="customer.phoneNumber"
              />
              <Input
                placeholder="Referentie"
                nonEditingPrefix="Referentie: "
                editing={this.props.isEditingCustomer}
                name="customer.reference"
              />
              <Input
                placeholder="Factuur e-mailadres"
                nonEditingPrefix="Factuur e-mailadres: "
                editing={this.props.isEditingCustomer}
                name="customer.invoiceEmailAddress"
              />
            </div>
          }
        </EditableBlock>

        <div className={classes.separator} />

        <EditableBlock
          isNew={this.props.isNew}
          title="E-mailadres opdrachtgever"
          initialValues={{
            id: order.get("id"),
            customer: { emailAddress: order.get("customer").get("emailAddress") }
          }}
          form="customerEmail"
        >
          {
            <div>
              {this.props.isEditingCustomerEmail ? (
                <Input
                  placeholder="E-mailadres opdrachtgever"
                  editing={this.props.isEditingCustomerEmail}
                  name="customer.emailAddress"
                  type="email"
                />
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${order.get("customer").get("emailAddress")}`}
                >
                  {order.get("customer").get("emailAddress")}
                </a>
              )}
            </div>
          }
        </EditableBlock>

        {!order.get("customer").get("invoiceEmailAddress") ? null : (
          <EditableBlock
            isNew={this.props.isNew}
            title="Factuur e-mail adres"
            initialValues={{
              id: order.get("id"),
              customer: {
                invoiceEmailAddress: order.get("customer").get("invoiceEmailAddress")
              }
            }}
            form="customerInvoiceEmailAddress"
          >
            {
              <div>
                {this.props.isEditingCustomerInvoiceEmailAddress ? (
                  <Input
                    placeholder="Factuur e-mail adres"
                    editing={this.props.isEditingCustomerInvoiceEmailAddress}
                    name="customer.invoiceEmailAddress"
                    type="email"
                  />
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${order.get("customer").get("invoiceEmailAddress")}`}
                  >
                    {order.get("customer").get("invoiceEmailAddress")}
                  </a>
                )}
              </div>
            }
          </EditableBlock>
        )}

        <div>
          <h3>Betaalwijze</h3>
          <div className="flex-1 layout-row layout-align-center-center">
            <div className="flex-2 OrderDetails__PaymentMethod">
              {order
                .get("paymentMethod")
                .get("icon")
                .indexOf("none") === -1 && (
                <span>
                  <img
                    className="OrderDetails__PaymentMethod__Icon"
                    src={order.get("paymentMethod").get("icon")}
                    style={{ verticalAlign: "middle" }}
                    alt=""
                  />
                  &nbsp;
                </span>
              )}
              {order.get("paymentMethod").get("title")}
            </div>

            {PAYMENT_METHOD_IDS_ON_ACCOUNT.indexOf(order.getIn(["paymentMethod", "id"])) !== -1 && (
              <div className="flex-1">
                <SendMailButton mailType="NotOnAccount">Niet op rekening</SendMailButton>
              </div>
            )}
          </div>
        </div>

        <div>
          <h3>Betaalstatus</h3>
          {order.get("isPaid") ? (
            <div className={classes.BoldGreen}>Betaald</div>
          ) : (
            <div className={classes.BoldRed}>Niet betaald</div>
          )}
        </div>

        <div>
          <h3>Behandeling</h3>
          <div>
            {order.get("treatment")}{" "}
            {order.get("treatment") === "Euroflorist" ? order.getIn(["transport", "euroflorist", "efid"], "") : ""}
          </div>
        </div>

        <div>
          <EditableBlock
            isNew={this.props.isNew}
            title="Bezorgstatus"
            initialValues={{
              id: order.get("id"),
              deliveryStatus: order.get("deliveryStatus"),
              deliveryStatusNr: order.get("deliveryStatusNr")
            }}
            form="deliveryStatus"
          >
            {this.props.isEditingDeliveryStatus ? (
              <div>
                <Select
                  placeholder="Bezorgstatus"
                  editing={this.props.isEditingDeliveryStatus}
                  name="deliveryStatus"
                  options={deliveryStatus}
                />
                <Input
                  placeholder="Huisnummer"
                  editing={this.props.isEditingDeliveryStatus}
                  name="deliveryStatusNr"
                  type="text"
                />
              </div>
            ) : (
              <div>
                {deliveryStatus.get(order.get("deliveryStatus"))}{" "}
                {order.get("deliveryStatus") === "deliveredAtNeighbour" ? order.get("deliveryStatusNr") : ""}
              </div>
            )}
          </EditableBlock>
        </div>

        {this.renderActionButtons()}

        <div className="layout-row margin-bottom-5">
          <Button linkTo={buildActionUrl("attachments")} className="flex-1">
            Bijlages
          </Button>
          <a
            className="flex-1 button-link"
            target="_blank"
            rel="noopener noreferrer"
            href={`${this.props.kmtUrl}/kmt/order/?action=edit&id=${this.props.order.get("id")}`}
          >
            <i className="fa fa-edit" /> Bewerk deze order in het KMT
          </a>
        </div>
        <TransportFeedbackMails order={this.props.order} />
      </Wrapper>
    );
  }

  renderCardBlockCard = () => (
    <EditableBlock
      isNew={this.props.isNew}
      title="Tekst kaartje"
      initialValues={{ id: this.props.order.get("id"), card: this.props.order.get("card").toJS() }}
      form="card"
    >
      {this.props.isEditingCardText ? (
        <TextArea placeholder="Tekst kaartje" name="card.text" editing={this.props.isEditingCardText} />
      ) : (
        <div className={classes.CardText}>{this.props.order.getIn(["card", "text"])}</div>
      )}
    </EditableBlock>
  );

  renderCardBlockRibbon = () => {
    const isMourning = this.props.order.get("isMourning", false);
    return (
      <EditableBlock
        isNew={this.props.isNew}
        title={isMourning ? "Rouwlint" : "Lint"}
        initialValues={{
          id: this.props.order.get("id"),
          card: {
            id: this.props.order.getIn(["card", "id"]),
            text: {
              left: this.props.order.getIn(["card", "left"]),
              right: this.props.order.getIn(["card", "right"])
            }
          }
        }}
        form="card"
      >
        {this.props.isEditingCardText ? (
          <div>
            <Input maxLength={60} placeholder="Lint 1" editing={this.props.isEditingCardText} name="card.text.left" />
            <Input maxLength={60} placeholder="Lint 2" editing={this.props.isEditingCardText} name="card.text.right" />
          </div>
        ) : (
          <div>
            <div className={classes.CardText}>{this.props.order.getIn(["card", "left"])}</div>
            <div className={classes.CardText}>{this.props.order.getIn(["card", "right"])}</div>
          </div>
        )}
      </EditableBlock>
    );
  };

  renderCardBlock = () => {
    const isRibbon = this.props.order.getIn(["card", "id"]) === 20012781;

    if (this.props.order.getIn(["card", "text"], null) === null) {
      return (
        <div className="alert alert-info no-card">
          <i>Er zit geen kaartje bij de bestelling.</i>
          <br />
          <button className="no-card__button" style={{ display: "none" }}>
            Gratis kaart toevoegen
          </button>
        </div>
      );
    }

    const isTextTooLong = isRibbon
      ? false
      : this.props.order.getIn(["card", "text"]).replace(/[^\n]+/gim, "").length >= 10;

    return (
      <div className="layout-column">
        {!isRibbon ? this.renderCardBlockCard() : this.renderCardBlockRibbon()}

        {isTextTooLong && (
          <div className="alert alert-danger">Kaart tekst bevat teveel regels om te kunnen printen (max 10).</div>
        )}
        {this.props.hasBcCard && (
          <div className="OrderDetail__CardDetails__HasBcCard  alert alert-warning">
            <i className="fa fa-exclamation-triangle mr-3" />
            <div>Let op dit order heeft een BoeketCadeau kaart</div>
          </div>
        )}
        <div className="layout-row margin-bottom-5 gutter-5">
          <CardButton
            title="Print kaartje 1"
            disabled={isTextTooLong}
            onClick={this.onPrintCard1}
            printing={this.props.printing.get("card1", false)}
            printed={this.props.order.getIn(["printJobs", "card1"], Immutable.List()).last()}
          />

          <CardButton
            title="Print kaartje 2"
            disabled={isTextTooLong}
            onClick={this.onPrintCard2}
            printing={this.props.printing.get("card2", false)}
            printed={this.props.order.getIn(["printJobs", "card2"], Immutable.List()).last()}
          />
        </div>
      </div>
    );
  };
}

OrderDetail.propTypes = {
  kmtUrl: PropTypes.string,
  attributes: PropTypes.object, // immutable.list
  isNew: PropTypes.bool,
  location: PropTypes.object,
  configuredPrinters: PropTypes.object, // immutable.map
  availablePrinters: PropTypes.object, // immutable.map
  isEveningDelivery: PropTypes.bool,
  isMourning: PropTypes.bool,
  isTransportRjp: PropTypes.bool,
  isTransportZorgeloosBezorgd: PropTypes.bool,
  isTransportPostNl: PropTypes.bool,
  isTransportDynalogic: PropTypes.bool,
  isTransportRegio: PropTypes.bool,
  isEditingDeliveryDate: PropTypes.bool,
  isEditingDeliveryDateTime: PropTypes.bool,
  isEditingCardText: PropTypes.bool,
  isEditingComment: PropTypes.bool,
  isEditingRecipient: PropTypes.bool,
  isEditingCustomer: PropTypes.bool,
  isEditingCustomerEmail: PropTypes.bool,
  isEditingCustomerInvoiceEmailAddress: PropTypes.bool,
  isEditingDeliveryStatus: PropTypes.bool,
  isPrinting: PropTypes.bool,
  isPrintingCard1: PropTypes.bool,
  isPrintingCard2: PropTypes.bool,
  sendToRegio: PropTypes.func,
  isRegioImporting: PropTypes.bool,
  isEuroFloristImporting: PropTypes.bool,
  isEuroFlorist2Importing: PropTypes.bool,
  isTransportReviva: PropTypes.bool,
  print: PropTypes.func,
  printing: PropTypes.object,
  printCard: PropTypes.func,
  findOrders: PropTypes.func,
  sendToEuroFlorist: PropTypes.func,
  markAsEuroFlorist: PropTypes.func,
  scrollToTop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  setEditing: PropTypes.func,
  className: PropTypes.any,
  countryCodes: PropTypes.any, // immutable
  order: PropTypes.any, // immutable
  orderId: PropTypes.any,
  isFetching: PropTypes.any,
  onEditSubmit: PropTypes.func,
  error: PropTypes.any,
  schemas: PropTypes.object, // immutable.map
  apiError: PropTypes.any,
  hasInternalLogEntries: PropTypes.bool,
  hasBcCard: PropTypes.bool,
  transporters: PropTypes.instanceOf(Immutable.List).isRequired
};

export default hot(module)(OrderDetail);

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import locationReducer from "./modules/Location";
import { reducer as appReducer } from "./modules/App";
import { reducer as authReducer } from "../routes/Auth/modules/Auth";
import { reducer as floristReducer } from "./modules/Florist";

export const makeRootReducer = asyncReducers =>
  combineReducers({
    location: locationReducer,
    auth: authReducer,
    app: appReducer,
    form: formReducer,
    ...asyncReducers,
    florist: floristReducer,
    toastr: toastrReducer
  });

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;

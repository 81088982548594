export const getParams = pathname => {
  const params = {};
  const parts = (pathname || document.location.pathname).replace(/^\//, "").split("/");
  parts.forEach(part => {
    // 'test'.match(/^te(st)/)
    let matches = part.match(/^q\[(.+?)\]$/i); // eslint-disable-line no-useless-escape
    if (matches) {
      params.query = matches[1];
    }

    // 'test'.match(/^te(st)/)
    matches = part.match(/^t\[([\w,]+)\]$/i); // eslint-disable-line no-useless-escape
    if (matches) {
      params.tags = matches[1].split(",");
    }
    if (!params.tags || !params.tags.length) {
      // params.tags = ['unprocessed'];
    }
    matches = part.match(/^p\[([\d]+)\]$/); // eslint-disable-line no-useless-escape
    if (matches) {
      params.page = parseInt(matches[1], 10);
    }
    matches = part.match(/^(\d+)$/);
    if (matches) {
      params.id = parseInt(matches[1], 10);
    }
    // console.log(document.location);
    // if (document.location.query && document.location.query.q) {
    //   params.query = document.location.query.q;
    // }
  });
  return params;
};

export const buildUrl = newParams => {
  newParams = newParams || {};

  const parts = ["/order"];

  const params = getParams();

  if (newParams.tags) {
    params.tags = newParams.tags;
  }
  if (params.tags && params.tags.length > 0) {
    parts.push(`t[${params.tags.join(",")}]`);
  }

  if (newParams.query) {
    params.query = newParams.query;
  }
  if (params.query) {
    parts.push(`q[${params.query}]`);
  }

  if (newParams.page) {
    params.page = newParams.page;
  }
  if (params.page) {
    parts.push(`p[${params.page}]`);
  }

  if (newParams.id || newParams.id === false) {
    params.id = newParams.id;
  }
  if (params.id !== false) {
    parts.push(params.id);
  }

  if (newParams.action) {
    params.action = newParams.action;
  }
  if (params.action) {
    parts.push(params.action);
  }

  return `${parts.join("/")}`;
};

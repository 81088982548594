// We only need to import the modules necessary for initial render

import AuthedLayout from "../layouts/AuthedLayout";
import AppLayout from "../layouts/AppLayout";
import ModalLayout from "../layouts/ModalLayout";

import AuthRoute, { RequireAuthRoute } from "./Auth";

// todo async loading of routes; i.e. async loadable chunks
import OrderRoute from "./Order";
// import StudioRoute from "./Studio";
import ShipmentRoute from "./Shipment";
import PrintRoute from "./Print";
import AdminRoute from "./Admin";

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = store => ({
  path: "/",
  indexRoute: { onEnter: (nextState, replace) => replace("/order/t[unprocessed]") },
  // component: PrintModalLayout,
  childRoutes: [
    {
      component: AuthedLayout,
      childRoutes: [
        {
          component: AppLayout,
          indexRoute: RequireAuthRoute(store, OrderRoute(store)),
          childRoutes: [
            RequireAuthRoute(store, OrderRoute(store)),
            // RequireAuthRoute(store, StudioRoute(store)),
            RequireAuthRoute(store, ShipmentRoute(store)),
            RequireAuthRoute(store, AdminRoute(store))
          ]
        },
        {
          childRoutes: [RequireAuthRoute(store, PrintRoute(store))]
        }
      ]
    },
    {
      component: ModalLayout,
      childRoutes: [AuthRoute(store)]
    }
  ]
});

export default createRoutes;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import QRious from "qrious";
import JsBarcode from "jsbarcode";

import ModuleView from "../../../components/ModuleView/ModuleView";
import { Select, Input } from "../../../components/Form";
import Paper from "../../../components/Paper/Paper";

const BARCODE_TYPES = [
  "QR",
  "CODE128",
  "CODE128A",
  "CODE128B",
  "CODE128C",
  {
    value: "CODE128 as GS1-128/EAN-128",
    options: {
      format: "CODE128",
      ean128: true
    }
  },
  "UPC",
  "EAN8",
  "EAN8 + EAN2",
  "EAN8 + EAN5",
  "EAN13",
  "EAN13 + EAN2",
  "EAN13 + EAN5",
  "CODE39",
  {
    value: "CODE39 (mod43)",
    options: {
      format: "CODE39",
      mod43: true
    }
  },
  "ITF14",
  "MSI",
  "MSI10",
  "MSI11",
  "MSI1010",
  "MSI1110",
  "pharmacode",
  "codabar"
].map(value => {
  if (value.value) {
    if (!value.label) {
      value.label = value.value;
    }
    return value;
  }
  return {
    value,
    label: value.toUpperCase()
  };
});

const BARCODE_TYPE_IDS = BARCODE_TYPES.map(v => v.value);

class BarCodeGeneratorView extends Component {
  static propTypes = {
    types: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOf(BARCODE_TYPE_IDS).isRequired
      })
    ).isRequired,
    type: PropTypes.oneOf(BARCODE_TYPE_IDS).isRequired,
    barcode: PropTypes.string,
    barcode2: PropTypes.string,
    strokeWidth: PropTypes.number.isRequired,
    dimension: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }).isRequired
  };

  static defaultProps = {
    barcode: "Boeket 01",
    barcode2: ""
  };

  state = {
    err: null
  };

  componentWillReceiveProps(props) {
    if (
      this.props.type !== props.type ||
      this.props.barcode !== props.barcode ||
      this.props.barcode2 !== props.barcode2 ||
      this.props.dimension.width !== props.dimension.width ||
      this.props.dimension.height !== props.dimension.height ||
      this.props.strokeWidth !== props.strokeWidth
    ) {
      this.renderBarCode({
        type: props.type,
        dimension: props.dimension,
        barcode: props.barcode,
        barcode2: props.barcode2,
        strokeWidth: props.strokeWidth
      });
    }
  }

  renderBarCode = ({ type, dimension, barcode, barcode2, strokeWidth }) => {
    if (type === "QR") {
      // eslint-disable-next-line no-new
      new QRious({
        element: this.canvasRef,
        padding: 0,
        value: barcode,
        size: dimension.width
      });
      return;
    }
    let options = {};
    BARCODE_TYPES.forEach(t => {
      if (t.value === type && t.options) {
        options = t.options;
        const parts = t.value.split("+");
        if (parts.length === 2) {
        }
      }
    });

    const config = {
      width: strokeWidth,
      height: dimension.height,
      ...options
    };

    try {
      const isEanPlus = type.indexOf("+ EAN") !== -1;
      if (isEanPlus) {
        const parts = type.split(" + ");
        JsBarcode("#bc-generator-barcode-canvas")
          [parts[0].toUpperCase()](barcode, { text: barcode, ...config })
          .blank(20)
          [parts[1].toUpperCase()](barcode2, {
            text: barcode2,
            height: 85,
            textPosition: "top",
            fontSize: 16,
            ...config
          })
          .render();
      } else {
        JsBarcode("#bc-generator-barcode-canvas", barcode, {
          format: type,
          displayValue: true,
          ...config
        });
      }
    } catch (err) {
      this.setState({ err });
      return;
    }
    this.setState({ err: null });
  };

  setCanvasRef = el => {
    this.canvasRef = el;
  };

  render() {
    const isEanPlus = this.props.type.indexOf("+ EAN") !== -1;
    const valueLabel = isEanPlus ? "Waarde 1" : "Waarde";
    const style = { display: this.state.err ? "none" : "inherit" };
    return (
      <ModuleView title="Code Generator">
        <Paper>
          <Select name="type" options={this.props.types} label="Type" />
          <Input type="text" name="barcode" onChange={this.onChange} placeholder={valueLabel} label={valueLabel} />
          {isEanPlus && (
            <Input type="text" name="barcode2" onChange={this.onChange} placeholder="Waarde 2" label="Waarde 2" />
          )}
          {this.props.type === "QR" && <Input type="number" name="width" placeholder="I am Groot" label="Grootte" />}
          {this.props.type !== "QR" && (
            <Input type="number" name="strokeWidth" placeholder="Lijn Dikte" label="Lijn Dikte" />
          )}
          {this.props.type !== "QR" && <Input type="number" name="height" placeholder="Hoogte" label="Hoogte" />}
          <div className="form-group row">
            <div className="offset-sm-3 col-sm-9 col-xs-12">
              <canvas id="bc-generator-barcode-canvas" ref={this.setCanvasRef} style={style} />
              {this.state.err && (
                <div className="alert alert-danger">
                  {this.state.err && this.state.err.message ? this.state.err.message : this.state.err}
                </div>
              )}
            </div>
          </div>
        </Paper>
      </ModuleView>
    );
  }
}

const selectFormValue = formValueSelector("barCode");

export default connect(state => {
  const type = selectFormValue(state, "type") || "QR";
  const dimension = {
    width: selectFormValue(state, "width") || 150,
    height: selectFormValue(state, "height") || 150
  };
  return {
    form: "barCode",
    initialValues: {
      type: "QR",
      barcode: "https://www.boeketcadeau.nl/bezorger-gemist",
      barcode2: "",
      strokeWidth: 2,
      width: 150,
      height: 150
    },
    strokeWidth: selectFormValue(state, "strokeWidth") || 2,
    barcode: selectFormValue(state, "barcode") || "",
    barcode2: selectFormValue(state, "barcode2") || "",
    type,
    dimension,
    types: BARCODE_TYPES
  };
})(reduxForm()(BarCodeGeneratorView));

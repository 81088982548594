import PropTypes from "prop-types";
import React from "react";
import LoginView from "../components/LoginView";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reduxForm } from "redux-form";
import { requestLogin } from "../modules/Auth";
import { browserHistory } from "react-router";
import { isAuthExpired } from "../../Auth";

let idx = 0;

export class LoginContainer extends React.Component {
  componentDidMount() {
    if (!this.props.isAuthExpired && this.props.token) {
      this._onAuthenticated();
    }
  }

  componentWillReceiveProps(props) {
    if (!props.isAuthExpired) {
      this._onAuthenticated();
    }
  }

  _onAuthenticated = () => {
    idx++;

    if (idx >= 10) {
      console.log("idx", idx);
      return;
    }

    const { location } = this.props;
    window.setTimeout(() => {
      if (location.state && location.state.nextPathname && location.state.nextPathname !== "/") {
        console.log("redirecting to correct location", location);
        browserHistory.replace(location.state.nextPathname);
        // this.props.dispatch(routeReplace());
        return;
      }
      browserHistory.replace("/order/t[unprocessed]");
      // this.props.dispatch(routeReplace('/'));
    }, 1);
  };

  render() {
    if (!this.props.isAuthExpired && this.props.token) {
      return null;
    }
    return <LoginView {...this.props} />;
  }
}

LoginContainer.propTypes = {
  authError: PropTypes.any,
  isLoggedOut: PropTypes.bool,
  token: PropTypes.string,
  isAuthExpired: PropTypes.bool,
  location: PropTypes.object
};

const isAuthExpiredSelector = createSelector(
  [state => state],
  auth => {
    if (!auth) {
      return true;
    }
    return isAuthExpired(auth);
  }
);

export default connect(
  state => ({
    authError: state.auth.get("error"),
    isLoggedOut: state.auth.get("isLoggedOut"),
    token: state.auth.get("token"),
    title: state.app.get("titleLong"),
    isAuthExpired: isAuthExpiredSelector(state.auth)
  }),
  {
    requestLogin
  }
)(
  reduxForm({
    form: "login",
    validate: data => {
      const errors = {};
      if (!data) {
        return errors;
      }
      if (!data.login) {
        errors.login = "Dit veld is vereist";
      }
      if (data.login && data.login.length < 4) {
        errors.login = "Uw gebruikersnaam is te kort";
      }
      if (!data.password) {
        errors.password = "Dit veld is vereist";
      }
      if (data.password && data.password.length < 4) {
        errors.password = "Uw wachtwoord is te kort";
      }
      return errors;
    }
  })(LoginContainer)
);

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import createStore from "./store/createStore";
import AppContainer from "./containers/AppContainer";

const store = createStore();

const MOUNT_NODE = document.getElementById("root");
MOUNT_NODE.setAttribute("notranslate", true);

ReactDOM.render(
  <Provider store={store}>
    <AppContainer store={store} />
  </Provider>,
  MOUNT_NODE
);

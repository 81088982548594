import React, { Component } from "react";
// import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";
import { propTypes as reduxFormPropTypes, reduxForm, SubmissionError } from "redux-form";

import { Wrapper } from "~/routes/Order/components/OrderDetail";
import { create as createOrder } from "~/routes/Order/modules/Order";
import SchemaField from "~/components/SchemaField";
import PropTypes from "prop-types";
import Button from "~/components/Button";
import { SmallLoader } from "~/components/Loader";
import { buildUrl } from "~/lib/RouteUtil";
import { browserHistory } from "react-router";

const schema = Immutable.fromJS({
  type: "object",
  properties: {
    cart: {
      type: "string",
      title: "Cart",
      minLength: 1
    }
  },
  required: ["cart"]
});

class OrderCreateContainer extends Component {
  static propTypes = {
    ...reduxFormPropTypes
  };

  static defaultProps = {};

  static childContextTypes = {
    schema: PropTypes.object, // immutable.map
    t: PropTypes.func // immutable.map
  };

  getChildContext() {
    return {
      t: this.props.t,
      schema
    };
  }

  onSubmit = data =>
    this.props
      .createOrder(data.cart)
      .then(res => {
        console.log("Created");
        console.log(res);
        browserHistory.push(buildUrl({ id: res.id }));
      })
      .catch(err => {
        throw new SubmissionError({ _error: err.message });
      });

  render() {
    console.log(this.props);
    if (!__DEV__) {
      return null;
    }
    return (
      <Wrapper {...this.props}>
        <h2 className="p-3">Order inschieten</h2>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate className="mb-3">
          <div className="alert alert-info m-3">
            Creeer een nieuwe bestelling middels het inschieten van een nieuwe cart data object
          </div>
          <div className="container-fluid">
            <SchemaField name="cart" inputType="textarea" placeholder="{ ...JSON... }" rows={20} />

            <div style={{ textAlign: "center" }}>
              <Button type="submit" className="col-sm-8" disabled={this.props.submitting || this.props.pristine}>
                {this.props.submitting && <SmallLoader />}
                {!this.props.submitting && this.props.error && (
                  <i className="fa fa-exclamation-circle" title={this.props.error}>
                    &nbsp;
                  </i>
                )}
                Order inschieten
              </Button>
            </div>

            {this.props.error && (
              <div>
                <br />
                <div className="alert alert-danger layout-row">
                  <div>
                    <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                  </div>
                  <div>
                    <strong>Er is een fout opgetreden</strong>
                    <br />
                    {this.props.error}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </Wrapper>
    );
  }
}

export default connect(
  state => ({}),
  {
    createOrder
  }
)(
  reduxForm({
    form: "createOrder"
  })(OrderCreateContainer)
);

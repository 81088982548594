import { hot } from "react-hot-loader";
import React, { Component } from "react";
import PropTypes from "prop-types";

import "./PhotoCameraContainer.scss";

class PhotoCameraContainer extends Component {
  static propTypes = {
    onAcceptPhoto: PropTypes.func,
    isAcceptingPhoto: PropTypes.bool
  };

  static defaultProps = {
    onAcceptPhoto: null
  };

  state = {
    processing: false,
    hasPhoto: false,
    error: null
  };

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.audioRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    if (!navigator.mediaDevices) {
      // non secure context
      this.setState({ error: new Error("Non secure context (must be https)") });
      return;
    }

    window.addEventListener("keydown", this.onKeyDown);

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: this.videoRef.current.offsetWidth * 2,
          height: this.videoRef.current.offsetHeight * 2
        },
        facingMode: "environment"
      })
      .then(stream => {
        const video = this.videoRef.current;
        video.srcObject = stream;
        video.onloadedmetadata = function(e) {
          video.play();
        };
      })
      .catch(err => {
        this.setState({ error: err });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = e => {
    // Space
    if (e.keyCode === 32) {
      e.preventDefault();
      this.onTakePhoto();
    }
    // Enter
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onAcceptPhoto();
    }
    // Escape
    if (e.keyCode === 27) {
      e.preventDefault();
      this.onRefusePhoto();
    }
  };

  onAcceptPhoto = () => {
    if (!this.props.onAcceptPhoto) {
      this.setState({
        hasPhoto: false
      });
      this.videoRef.current.play();
      return;
    }
    this.setState({
      processing: true
    });
    Promise.resolve(this.props.onAcceptPhoto(this.canvasRef.current.toDataURL("image/jpeg")))
      .then(() => {
        this.setState({
          hasPhoto: false,
          processing: false
        });
        this.videoRef.current.play();
      })
      .catch(err => {
        console.log("Could not upload photo");
        this.setState({
          processing: false,
          error: err
        });
      });
  };

  onRefusePhoto = () => {
    this.setState({
      hasPhoto: false,
      processing: false
    });
    this.videoRef.current.play();
  };

  onTakePhoto = () => {
    if (this.state.hasPhoto || !this.videoRef.current) {
      return;
    }

    this.videoRef.current.pause();

    // play clickity click
    this.audioRef.current.play();

    // save image
    const canvas = this.canvasRef.current;
    canvas.width = this.videoRef.current.offsetWidth * 2;
    canvas.height = this.videoRef.current.offsetHeight * 2;

    const context = canvas.getContext("2d");
    context.fillStyle = "#AAA";
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.drawImage(this.videoRef.current, 0, 0, canvas.width, canvas.height);

    setTimeout(() => {
      this.videoRef.current.style.opacity = 0.4;
    }, 100);

    setTimeout(() => {
      this.videoRef.current.style.opacity = 1;
      this.setState({
        hasPhoto: true
      });
    }, 200);
  };

  render() {
    if (this.state.error) {
      return <div className="alert alert-danger">{this.state.error.message}</div>;
    }

    const { hasPhoto } = this.state;

    return (
      <div>
        <audio ref={this.audioRef} src="/audio/camera-shutter-click-01.mp3" style={{ display: "none" }} />
        <div className="WebCam__TakePhoto__Container">
          <video
            ref={this.videoRef}
            className="WebCam__Stream"
            id="webcam_video_stream"
            style={{ maxWidth: "100%", minHeight: 480 }}
          />
          <canvas ref={this.canvasRef} className="WebCam__Stream__Canvas" />
          <div className="WebCam__ButtonBar" style={{ width: hasPhoto && !this.state.processing ? 350 : "inherit" }}>
            <button
              style={{ display: hasPhoto ? "block" : "none" }}
              className="WebCam__ButtonBar__Button WebCam__ButtonBar__Button--accept"
              onClick={this.onAcceptPhoto}
              disabled={this.state.processing}
            >
              {this.state.processing ? <i className="fa fa-spin fa-cog" /> : <i className="fa fa-check" />}
            </button>
            <button
              style={{ display: hasPhoto ? "none" : "block" }}
              className="WebCam__ButtonBar__Button WebCam__ButtonBar__Button--take"
              onClick={this.onTakePhoto}
              disabled={this.state.processing}
            >
              <i className="fa fa-camera" />
            </button>
            <button
              style={{ display: hasPhoto && !this.state.processing ? "block" : "none" }}
              className="WebCam__ButtonBar__Button WebCam__ButtonBar__Button--cancel"
              onClick={this.onRefusePhoto}
              disabled={this.state.processing}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default hot(module)(PhotoCameraContainer);

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import wordwrap from "locutus/php/strings/wordwrap";

import Loader from "../../../../components/Loader";
import { findById as findFloristById } from "../../../../store/modules/Florist";
import moment from "../../../../lib/moment";
import { price } from "../../../../lib/Formatters";

export const OrderItems = ({ items }) => {
  if (!items || !items.size) {
    return null;
  }
  let total = 0;
  return (
    <div>
      <div className="form-group row">
        <div className="col-xs-6">Product</div>
        <div className="col-xs-2">Aantal</div>
        <div className="col-xs-2">Prijs p/s</div>
        <div className="col-xs-2">Totaal</div>
      </div>
      {items.map(item => {
        const itemTotal = parseInt(item.get("quantity"), 10) * parseFloat(item.get("pricePerProduct"));
        total += itemTotal;
        return (
          <div className="form-group row" key={item}>
            <div className="col-xs-6">{item.get("title")}</div>
            <div className="col-xs-2">{item.get("quantity")}</div>
            <div className="col-xs-2">{price(item.get("pricePerProduct"))}</div>
            <div className="col-xs-2">{price(itemTotal)}</div>
          </div>
        );
      })}
      <div className="form-group row">
        <div className="col-xs-6">Totaal</div>
        <div className="col-xs-2">&nbsp;</div>
        <div className="col-xs-2">&nbsp;</div>
        <div className="col-xs-2">{price(total)}</div>
      </div>
    </div>
  );
};
OrderItems.propTypes = {
  items: PropTypes.object // Immutable.List
};

export class ViewFlorist extends React.Component {
  componentDidMount() {
    if (this.props.floristId) {
      this.props.findFloristById(this.props.floristId);
    }
  }

  componentWillReceiveProps(props) {
    if (props.floristId && props.floristId !== this.props.floristId) {
      this.props.findFloristById(props.floristId);
    }
  }

  renderRow = (label, value) => (
    <div className="form-group row">
      <div className="col-sm-2 col-xs-12">{label}</div>
      <div className="col-sm-10 col-xs-12">{value}</div>
    </div>
  );

  render() {
    if (this.props.isFetching) {
      return (
        <div>
          <h2>Bekijk collega bloemist</h2>
          <Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />
        </div>
      );
    }

    const { florist } = this.props;
    const { order } = this.props;

    const deliveryDate = moment(order.get("deliveryDate"));

    return (
      <div>
        <h2>Bekijk collega bloemist</h2>

        {this.renderRow("Naam", florist.get("name"))}

        {this.renderRow("Contactpersoon", florist.get("contactPersonName"))}

        {this.renderRow(
          "E-mailadres",
          <a href={`mailto:${florist.get("emailAddress")}`} target="_blank">
            {florist.get("emailAddress")}
          </a>
        )}

        {this.renderRow("Telefoon", florist.get("phoneNumber"))}

        {this.renderRow(
          "Adres",
          <div>
            <div>{`${florist.getIn(["address", "street"], "")} ${florist.getIn(["address", "streetNumber"], "")}`}</div>
            <div>{`${florist.getIn(["address", "zipcode"], "")} ${florist.getIn(["address", "countryCode"], "")}`}</div>
          </div>
        )}

        {this.renderRow("Rekening nummer", florist.get("accountNumber"))}

        <h2>Order informatie</h2>

        {this.renderRow("Bezorgdatum", <strong>{deliveryDate.format("dddd D MMMM YYYY")}</strong>)}
        {this.renderRow("Ordernummer", order.get("orderNumber"))}

        {this.renderRow("Orderregels", <OrderItems items={this.props.orderItems} />)}

        <div className="form-group row">
          <div className="col-xs-12">
            {// <img src="<?php echo ent($imageUrl); ?>" title="boeket" />
            order.get("imageUrl") ? <img src={order.get("imageUrl")} title="boeket" /> : null}
          </div>
        </div>

        {this.renderRow(
          "Tekst kaartje",
          order.getIn(["card", "text"], "") ? (
            <div>
              {wordwrap(
                order.getIn(["card", "text"], "").replace(/(.*)(Graag een ".+?" er aan\.)([ ]?)(.*)/, "$1$2\n$4"),
                50,
                "\n",
                false
              )
                .split("\n")
                .map((line, idx) => (
                  <div key={idx}>{line}</div>
                ))}
            </div>
          ) : (
            <i>Er zit geen kaartje bij de bestelling.</i>
          )
        )}

        {this.renderRow("Omschrijving", order.getIn(["description"]))}

        {this.renderRow("Opmerking", order.get("comment") ? order.get("comment") : <i>Geen opmerking</i>)}

        {this.renderRow(
          "Bezorgadres",
          <div>
            <div>{order.getIn(["recipient", "name"])}</div>
            <div>{order.getIn(["recipient", "company"])}</div>
            <div>
              {`${order.getIn(["recipient", "address", "street"])} ${order.getIn([
                "recipient",
                "address",
                "streetNumber"
              ])}`}
            </div>
            <div>
              {`${order.getIn(["recipient", "address", "zipcode"])} ${order.getIn(["recipient", "address", "city"])}`}
            </div>
            <div>
              {this.props.countryCodes
                ? this.props.countryCodes.get(order.getIn(["recipient", "address", "countryCode"]), "")
                : ""}
            </div>
            {order.getIn(["recipient", "phoneNumber"]) && <div>Tel: {order.getIn(["recipient", "phoneNumber"])}</div>}
          </div>
        )}

        {this.renderRow(
          "Opdrachtgever",
          <div>
            <div>BoeketCadeau.nl</div>
            <div>Bloemendaalseweg 232</div>
            <div>2051GM Overveen</div>
            <div>023-5265909</div>
          </div>
        )}
      </div>
    );
  }
}

ViewFlorist.propTypes = {
  countryCodes: PropTypes.object, // immutable.map
  floristId: PropTypes.number.isRequired,
  florist: PropTypes.object, // immutable.map
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object, // immutable.map
  orderItems: PropTypes.object, // immutable.map
  findFloristById: PropTypes.func,
  isFetching: PropTypes.bool
};

export const floristIdSelector = createSelector(
  state => state,
  order => order.getIn(["order", "data", "transport", "florist", "id"], 0)
);

export const floristOrderItemsSelector = createSelector(
  state => state,
  order => order.getIn(["order", "data", "transport", "florist", "data", "orderItems"], {})
);

export default connect(
  state => {
    const florist = state.florist.getIn(["active", "data"]);
    const order = state.order.get("order");
    return {
      countryCodes: state.app.getIn(["locale", "countryCodes"]),
      floristId: floristIdSelector(state.order),
      orderItems: floristOrderItemsSelector(state.order),
      florist: florist,
      order: order.get("data"),
      isFetching: florist.get("isFetching") || order.get("isFetching")
    };
  },
  {
    findFloristById
  }
)(ViewFlorist);

import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import Button from "./Button/Button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "1px solid silver",
    borderRadius: 0,
    minWidth: 320
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#0288d1",
    boxShadow: "0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12), 0 1px 8px 0 rgba(0,0,0,.2)",
    zIndex: 20,
    height: 48,
    color: "white",
    fontSize: 20,
    textShadow: "1px 1px 1px grey",
    paddingLeft: 10,
    paddingRight: 10
  },
  closeButton: {
    marginLeft: 10,
    cursor: "pointer"
  },
  contentContainer: {
    padding: 20
  }
};

class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.node,
    closable: PropTypes.bool,
    children: PropTypes.node,
    buttons: PropTypes.bool,
    cancelText: PropTypes.string,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    title: null,
    closable: true,
    children: null,
    buttons: true,
    cancelText: "Annuleren",
    okText: "OK",
    onCancel: null,
    onAccept: null
  };

  componentDidMount() {
    window.addEventListener("keyup", this.onKeyInput);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.onKeyInput);
  }

  onKeyInput = e => {
    if (!this.props.isOpen) {
      return;
    }
    if (e.keyCode === 27 && this.props.onCancel) {
      // Escape
      this.props.onCancel(e);
    }
  };

  render() {
    const { title, closable, children, buttons, cancelText, okText, onCancel, onAccept, ...rest } = this.props;
    return (
      <ReactModal contentLabel="Modal" style={customStyles} role="dialog" ariaHideApp={false} {...rest}>
        {title && (
          <div style={customStyles.titleContainer}>
            {title}
            {closable && (
              <div style={customStyles.closeButton}>
                <div className="material-icons" onClick={onCancel}>
                  close
                </div>
              </div>
            )}
          </div>
        )}
        <div style={customStyles.contentContainer}>
          {children}
          {buttons !== false && (
            <div className="layout-row layout-align-end-end">
              <Button onClick={onCancel}>{cancelText}</Button>
              <Button onClick={onAccept}>{okText}</Button>
            </div>
          )}
        </div>
      </ReactModal>
    );
  }
}

export default Modal;

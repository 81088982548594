import PropTypes from "prop-types";
import React from "react";

export default class PrimaryButton extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  render() {
    return (
      <button className="btn btn-default btn-primary" {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

import PropTypes from "prop-types";
import React from "react";
import classes from "./Loader.module.scss";

export const SmallLoader = () => (
  <span>
    <i className="fa fa-spin fa-circle-notch" />
    &nbsp;
  </span>
);

export const Loader = ({ text, className }) => (
  <div className={`${classes.container} ${className}`}>
    <i className="fa fa-3x fa-spin fa-circle-notch" />
    &nbsp;
    <span>{text}</span>
  </div>
);

Loader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default Loader;

import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import Immutable from "immutable";

import {
  find as findOrders,
  fetchOne,
  print,
  sendToEuroFlorist,
  markAsEuroFlorist,
  fetchAttributes,
  sendToRegio
} from "../modules/Order";
import { scrollToTop } from "store/modules/App";

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the counter:   */

import OrderDetails from "../components/OrderDetail";

const EMPTY_LIST = Immutable.List();

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })

 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

const initialValuesSelector = createSelector(
  state => state.get("order"),
  order =>
    // console.log('initialValues', order.get('data').toJS());
    order.get("data").toJS()
);

const selectFeature = createSelector(
  [state => state.app.get("features"), (state, featureId) => featureId],
  (features, featureId) => features.find(feature => feature.get("title") === featureId)
);

const selectFeatureActive = createSelector(
  [selectFeature],
  feature => {
    if (!feature) {
      return true;
    }
    return feature.get("extraAttribute_28088961", true);
  }
);

const mapStateToProps = (state /* , props */) => {
  const isNew = state.order.getIn(["order", "data", "treatment"]) === "Nieuw";
  return {
    location: state.location,
    kmtUrl: state.auth.getIn(["url"], ""),
    isNew: isNew,
    configuredPrinters: state.app.getIn(["config", "printers"]),
    availablePrinters: state.app.getIn(["printers", "data"], []),
    isEveningDelivery: state.order.getIn(["order", "data", "isEveningDelivery"], false),
    transporters: state.app.get("features").filter(f => f.get("extraAttribute_28088960") === "transporter"),
    printers: state.app.getIn(["printers", "data"], EMPTY_LIST).reduce(
      (printers, printer) => printers.set(printer.get("id"), printer.get("displayName")),
      new Immutable.OrderedMap({
        _popup: "Popup (legacy)"
      })
    ),
    isTransportRjp: state.order.getIn(["order", "data", "isRedjepakketje"], false),
    isTransportZorgeloosBezorgd: state.order.getIn(["order", "data", "isZorgeloosBezorgd"], false),
    isTransportPostNl: state.order.getIn(["order", "data", "isPostNl"], false),
    isTransportDynalogic: state.order.getIn(["order", "data", "isDynalogic"], false),
    isTransportReviva: state.order.getIn(["order", "data", "isReviva"], false),
    isTransportRegio: state.order.getIn(["order", "data", "isRegio"], false),

    isFetching: state.order.getIn(["order", "isFetching"], false),
    hasFetched: state.order.getIn(["order", "hasFetched"], false),
    isEditingDeliveryDate: state.order.getIn(["order", "editing", "deliveryDate"], false),
    isEditingDeliveryDateTime: state.order.getIn(["order", "editing", "deliveryDateTime"], false),
    isEditingCardText: state.order.getIn(["order", "editing", "card"], false),
    isEditingComment: state.order.getIn(["order", "editing", "comment"], false),
    isEditingRecipient: state.order.getIn(["order", "editing", "recipient"], false),
    isEditingCustomer: state.order.getIn(["order", "editing", "customer"], false),
    isEditingCustomerEmail: state.order.getIn(["order", "editing", "customerEmail"], false),
    isEditingCustomerInvoiceEmailAddress: state.order.getIn(["order", "editing", "customerInvoiceEmailAddress"], false),
    isEditingDeliveryStatus: state.order.getIn(["order", "editing", "deliveryStatus"], false),

    printing: state.order.getIn(["order", "print"]),
    isPrinting: state.order.getIn(["order", "isPrinting"], false),
    isPrintingCard1:
      state.order.getIn(["order", "isPrintingCard"], false) && state.order.getIn(["order", "cardType"], false) === 1,
    isPrintingCard2:
      state.order.getIn(["order", "isPrintingCard"], false) && state.order.getIn(["order", "cardType"], false) === 2,

    isMourning: state.order.getIn(["order", "data", "isMourning"], false),
    isRegioImporting: state.order.getIn(["order", "regio", "isSubmitting1"], false),

    isEuroFloristImporting: state.order.getIn(["order", "euroflorist", "isSubmitting1"], false),

    isEuroFlorist2Importing: state.order.getIn(["order", "euroflorist", "isSubmitting2"], false),

    isSubmittingFlorist: state.order.getIn(["order", "florist", "isSubmitting"], false),

    order: state.order.getIn(["order", "data"]),
    countryCodes: state.app.getIn(["locale", "countryCodes"], new Immutable.Map()),
    apiError: state.order.get("order").get("error"),
    initialValues: initialValuesSelector(state.order),
    schemas: state.app.get("schemas"),

    attributes: state.order.getIn(["attributes", "data"], new Immutable.List()),
    hasInternalLogEntries:
      selectFeatureActive(state, "internalLog") &&
      state.order.getIn(["order", "data", "internalLog"], new Immutable.List()).size > 0,
    hasBcCard: state.order.getIn(["order", "data", "ownDelivery"], false)
  };
};

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapActionCreators = {
  findOrders,
  fetchOne,
  fetchAttributes,
  print,
  sendToRegio,
  sendToEuroFlorist,
  markAsEuroFlorist,
  scrollToTop
};

export class OrderDetailsContainer extends React.Component {
  componentDidMount() {
    if (!this.props.hasFetched && this.props.orderId) {
      this.props.fetchOne(this.props.orderId);
      this.props.scrollToTop();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.orderId && this.props.orderId !== newProps.orderId) {
      this.props.fetchOne(newProps.orderId);
      this.props.scrollToTop();
    }
  }

  render() {
    return <OrderDetails {...this.props} />;
  }
}

OrderDetailsContainer.propTypes = {
  kmtUrl: PropTypes.string,
  isFetching: PropTypes.bool,
  hasFetched: PropTypes.bool,
  isEditingDeliveryDate: PropTypes.bool,
  isEditingDeliveryDateTime: PropTypes.bool,
  isEditingCardText: PropTypes.bool,
  isEditingComment: PropTypes.bool,
  isEditingRecipient: PropTypes.bool,
  isEditingCustomer: PropTypes.bool,
  isEditingCustomerEmail: PropTypes.bool,
  isEditingCustomerInvoiceEmailAddress: PropTypes.bool,
  isEditingDeliveryStatus: PropTypes.bool,
  isPrinting: PropTypes.bool,
  isPrintingCard1: PropTypes.bool,
  isPrintingCard2: PropTypes.bool,
  onEditSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  findOrders: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchAttributes: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func,
  orderId: PropTypes.any.isRequired,
  order: PropTypes.any,
  countryCodes: PropTypes.any,
  apiError: PropTypes.any,
  print: PropTypes.func.isRequired
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapActionCreators
  )(OrderDetailsContainer)
);

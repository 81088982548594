import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { browserHistory } from "react-router";
import makeRootReducer from "./reducers";
import promiseMiddleware from "./middleware/promise";
import { updateLocation } from "./modules/Location";

export let store;

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [promiseMiddleware, thunk];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  if (__DEV__) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "function") {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
      // console.log redux action logging
      // middleware.push(require('redux-logger').createLogger());
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  store = createStore(
    makeRootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
  store.asyncReducers = {};

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store));

  // init initial location...
  updateLocation(store)(browserHistory.getCurrentLocation());

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};

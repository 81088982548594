import isObject from "lodash/isObject";
// import $ from "jquery";
import { SubmissionError } from "redux-form";
import setIn from "lodash/set";
import Ajv from "ajv";

/**
 * Flatten a deep object into a one level object with it’s path as key
 *
 * @see https://github.com/hughsk/flat/issues/52
 *
 * @param  {object} object - The object to be flattened
 * @param  {string} d - The delimiter (defaults to dot)
 * @param  {RegExp} ignoreIfObjectContains - The delimiter (defaults to dot)
 *
 * @return {object}        - The resulting flat object
 */
export const flattenObject = (object, d = ".", ignoreIfObjectContains) => {
  // dataPath|schemaPath
  const r = {};
  if (!object) {
    return r;
  }
  (function f(o, p) {
    if (ignoreIfObjectContains && Object.keys(o).filter(key => ignoreIfObjectContains.test(key)).length > 0) {
      r[p] = o;
      return;
    }
    // eslint-disable-next-line no-return-assign
    Object.keys(o).forEach(k =>
      o[k] && typeof o[k] === "object" ? f(o[k], p ? `${p}${d}${k}` : k) : (r[p ? `${p}${d}${k}` : k] = o[k])
    );
  })(object);
  return r;
};

/**
 *
 * @param arr
 * @param byKey
 */
export const arrayToObjectByKey = (arr, byKey) =>
  arr.reduce((result, item) => {
    result[item[!byKey ? Object.keys(item)[0] : Object.keys(item).filter(k => k === byKey)[0]]] = item;
    return result;
  }, {});

const rootKeywords = ["required", "dependencies", "additionalProperties", "oneOf", "validator"];
export const ajvErrorMapperCreator = (errors, schema, t) => err => {
  if (!isObject(err)) {
    setIn(errors, err, err);
    return;
  }

  const path = err.dataPath
    ? err.dataPath
        .replace(/^\./, "")
        .replace(/\[([0-9]+)]/gm, ".$1")
        .split(".")
    : [];

  // get property
  let property = null;
  let { message } = err;

  if (rootKeywords.indexOf(err.keyword) === -1) {
    property = path.pop();
  }

  if (err.keyword === "required" || err.keyword === "dependencies" || err.keyword === "enum") {
    message = "required";
    if (err.keyword !== "enum") {
      property = err.params.missingProperty;
    }
  }

  if (err.keyword === "enum" && err.schemaPath && err.schemaPath.endsWith("items/enum")) {
    if (/\[\d+]$/.test(err.dataPath)) {
      property = path.pop();
    }
    message = "type";
  }

  if (err.keyword === "oneOf") {
    property = "renderOneOf";
    message = "required";
  }

  if (err.keyword === "minLength" && err.params.limit === 1) {
    message = "required";
  }

  if (err.keyword === "minItems" && err.params.limit === 1) {
    message = "required";
  }

  if (err.keyword === "maximum") {
    message = "maximum";
  }

  if (err.keyword === "minimum") {
    message = "minimum";
  }

  if (err.keyword === "validator") {
    // eslint-disable-next-line prefer-destructuring
    property = err.params.property;
    // eslint-disable-next-line prefer-destructuring
    message = err.message;
  }

  // eslint-disable-next-line no-multi-assign
  message = message || err.keyword;
  if (property === "emailAddress" || property === "invoiceEmailAddress") {
    message = "formatemail";

    // ignore format errors on non required fields
    // if (!err.data && !nibble[property]) {
    //   nibble = null;
    // }
  }
  if (err.params && err.params.format === "email") {
    message = "formatemail";
  }

  path.push(property);

  let value = t ? t(`fielderror_${message}`, err.params) : message;

  if (message === "checkQuantityIsApplied") {
    value = err;
  }

  if (err.forceMessage && err.message) {
    value = err.message;
  }
  setIn(errors, `${path.join(".")}`, value);
};

export const handleSubmitError = (t, fieldErrors) => {
  if (__DEV__ || window.logSchemaErrors) {
    // eslint-disable-next-line no-console
    console.log("submit.error", fieldErrors);
  }
  if (fieldErrors && !fieldErrors.errors && fieldErrors.message) {
    throw new SubmissionError({
      _error: `${t("Submit failed")}: ${t(fieldErrors.message)}`
    });
  }
  if (fieldErrors && fieldErrors.message && fieldErrors.message === "PersistException") {
    throw new SubmissionError({
      err: fieldErrors.errors.join(", "),
      _error: `${t("Submit failed")}: ${t("Check your details")}`
    });
  }
  if (fieldErrors && fieldErrors.errors && fieldErrors.errors.length) {
    if (__DEV__ || window.logSchemaErrors) {
      // eslint-disable-next-line no-console
      console.log("server.error", fieldErrors);
    }

    const serverErrors = {};
    fieldErrors.errors.map(ajvErrorMapperCreator(serverErrors, null, t));

    if (__DEV__ || window.logSchemaErrors) {
      // eslint-disable-next-line no-console
      console.log("form.errors", serverErrors);
    }

    throw new SubmissionError({
      ...serverErrors,
      _error: `${t("Submit failed")}: ${t("Check your details")}`
    });
  }
  // if (fieldErrors && fieldErrors.errors) {
  //   throw new SubmissionError({ _error: `${t('Submit failed')}: ${t('Unexpected error, try again')}` });
  // }
  throw new SubmissionError({
    _error: `${t("Submit failed")}: ${t("Unexpected error, try again")}`
  });
};

/**
 *
 */
export const reduxFormOnSubmitFailScrollToFirstError = errors => {
  if (!errors) {
    return;
  }
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.error("onSubmitFail.errors", errors);
  }
  // # Focus on first error

  // When Immutable:
  // The errors are _not_ in `REGISTER_FIELD` order so we cant just "use" the first one..
  // (possibly requires an ordered list reviver when getting errors?)

  // if (process.env.NODE_ENV === 'development') {
  //   // eslint-disable-next-line no-console
  //   console.error('onSubmitFail.errors', errors);
  // }

  // so we try to get the first error element in another way:
  // We do this using a DOM selector of all the fields that have error'ed and use `querySelector` which
  // Returns the first Element within the document that matches the specified selector, or group of selectors.
  const selector = Object.keys(flattenObject(errors))
    .map(
      fieldName => `[name="${fieldName.replace(/\.(\d+)\./gm, "[$1].")}"],#form-control-${fieldName.replace(".", "-")}`
    )
    .join(",");
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.error("onSubmitFail.selector", selector);
  }
  if (!selector) {
    return;
  }
  const errorEl = document.querySelector(selector);

  // const $errorEl = $(document.querySelector(selector))
  //   .filter(":visible")
  //   .first();
  // if (!$errorEl.length) {
  //   return;
  // }
  // const errorEl = $errorEl[0];

  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.error("onSubmitFail.errorEl", errorEl);
  }
  if (!errorEl) {
    return;
  }

  if (errorEl.focus) {
    // npe
    if (errorEl.getAttribute("type") === "hidden") {
      if (errorEl.nextSibling) {
        errorEl.nextSibling.scrollIntoView();
      } else {
        errorEl.parentNode.scrollIntoView();
      }
    } else {
      if (errorEl.scrollIntoView) {
        errorEl.scrollIntoView(); // fails as the site has a fixed header
      }
      errorEl.focus();
    }
  }
};

export const ajvInstance = new Ajv({ removeAdditional: true, allErrors: true, verbose: true });

export default {
  arrayToObjectByKey,
  ajvErrorMapperCreator,
  ajvInstance
};

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { connect } from "react-redux";
import Immutable from "immutable";
import isNumber from "lodash/isNumber";
import ucFirst from "locutus/php/strings/ucfirst";
import { DragDropContext, DragSource } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ReactTooltip from "react-tooltip";
import "react-virtualized/styles.css";

import ModuleView from "../../../components/ModuleView";
import moment from "../../../lib/moment";
import Paper from "../../../components/Paper";
import TabContainer from "./TabContainer";
import { price } from "../../../lib/Formatters";
import OrderDetail from "../containers/OrderDetailsContainer";
import OrderAction from "./OrderAction";
import PaginatedDataList from "./DataList/PaginatedDataList";
import { buildUrl } from "../../../lib/RouteUtil";
import { removeItem, removeOrderFromTag } from "../modules/Order";

import classes from "./OrderView.module.scss";
import OrderCreateContainer from "~/routes/Order/components/OrderCreate";

const buildIdUrl = id => buildUrl({ id: id });

export const Error = props => <div className="alert alert-danger">{props.message}</div>;
Error.propTypes = {
  message: PropTypes.any
};

export class DataListItemRenderer extends React.Component {
  onRemoveOrderFromTag = e => {
    this.props.removeOrderFromTag(this.props.item.get("id"), this.props.item.getIn(["tag", "id"]));
    this.props.removeItem(this.props.item.get("id"));
    e.preventDefault();
    return false;
  };

  onOpenInvoices = e => {
    e.preventDefault();

    if (!this.props.item.get("unpaidInvoiceIds")) {
      return;
    }

    const ids = this.props.item.get("unpaidInvoiceIds").toJS();

    const el = document.createElement("a");
    document.getElementsByTagName("body")[0].appendChild(el);
    el.setAttribute("href", `${this.props.kmtUrl}/kmt/invoice/?ids=${ids.join(",")}`);
    el.setAttribute("target", "_blank");
    el.click();
  };

  getTransporterIcon = transporter => {
    if (transporter === "florist") {
      return <i className="fa fa-link" />;
    }
    if (transporter === "regio" || transporter === "blanco") {
      return <i className="fa fa-car-side" />;
    }
    return <img src={`/favicon_${transporter}.png`} alt="" data-tip={`${ucFirst(transporter)} aanmelding`} />;
  };

  renderTransporterIcon() {
    const { item, options } = this.props;
    const transporter = item.get("activeTransporter");
    if (!options.displayTransporterIcon || !transporter) {
      return null;
    }
    return <div className="transport-icon">{this.getTransporterIcon(transporter)}</div>;
  }

  render() {
    const { isDragging, connectDragSource, orderId, item, isNew, isProblem, isSearchView, options } = this.props;

    const isBold = item.get("treatment", "") === "Nieuw" && isSearchView;

    const orderMoment = moment(item.get("orderDate"));

    // const isZeroPrice = item.get('total') === 0;

    return connectDragSource(
      <div style={{ opacity: isDragging ? 0.8 : 1, display: "block" }}>
        <Link to={buildIdUrl(item.get("id"))} key={item}>
          <div
            className={`datalist-row layout-row layout-align-start-center ${
              orderId === item.get("id") ? " datalist-row-selected" : ""
            }${isBold ? "  bold" : ""}`}
          >
            {options.displayPaymentIcon && (
              <div className="datalist-col datalist-col-icon flex-1">
                <img
                  alt=""
                  src={item.get("paymentMethod").get("icon")}
                  data-tip={item.get("paymentMethod").get("title")}
                />
              </div>
            )}
            <div className="datalist-col datalist-col-id flex-1">
              {item.get("orderNumber")}
              {options.displayOrderCount && item.get("orderCount") > 1 ? (
                <span>
                  &nbsp;<strong>({item.get("orderCount")})</strong>
                </span>
              ) : null}
            </div>
            <div className="datalist-col datalist-col-date flex-1">
              {moment(item.get("deliveryDate")).format("DD-MM-YYYY")}
            </div>
            <div className="datalist-col flex-4 layout-row layout-align-start-stretch">
              {options.displayOpenInvoices && item.get("hasReminders") ? (
                <span onClick={this.onOpenInvoices}>
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ color: "red" }}
                    data-tip="Heeft openstaand herinneringen!"
                  />
                  &nbsp;
                </span>
              ) : null}
              {options.displayBlacklisted && item.get("blackListed") ? (
                <span>
                  <i className="fa fa-exclamation-circle" style={{ color: "black" }} data-tip="Is op de Zwartelijst!" />
                  &nbsp;
                </span>
              ) : null}
              <div className="flex-4 layout-column" style={{ wordWrap: "breakWord", wordBreak: "breakAll" }}>
                {item.get("customer")}
              </div>

              <div className="icons flex-1 layout-row layout-align-end-center">
                {options.displayBcCardIcon && item.get("ownDelivery") && (
                  <div className="transport-icon">
                    <img
                      alt=""
                      style={{ display: "block", height: "14px" }}
                      src="/img/boeketcadeau2014/sprite/favicon.png"
                      data-tip="BoeketCadeau Kaart"
                    />
                  </div>
                )}

                {item.get("isMorningDelivery") && options.displayMorningDeliveryIcon ? (
                  <div className="transport-icon">
                    <img src="/morningDelivery.png" alt="" data-tip="Ochtend levering" />
                  </div>
                ) : null}

                {item.get("isAfternoonDelivery") && options.displayAfternoonDeliveryIcon ? (
                  <div className="transport-icon">
                    <img src="/afternoonDelivery.png" alt="" data-tip="Middag levering" />
                  </div>
                ) : null}

                {item.get("isEveningDelivery") && options.displayEveningDeliveryIcon ? (
                  <div className="transport-icon">
                    <img src="/moon.png" alt="" data-tip="Avond levering" />
                  </div>
                ) : null}

                {item.get("isMourning") && options.displayMourningIcon ? (
                  <div className="transport-icon">
                    <div className={classes.MourningOrder} data-tip="Rouworder">
                      ✝
                    </div>
                  </div>
                ) : null}

                {item.get("hasPhotoService", false) !== false && options.displayPhotoServiceIcon && (
                  <div className="transport-icon">
                    <i className="fa fa-fw fa-camera" data-tip="Extra Service: Foto" />
                  </div>
                )}
              </div>
            </div>
            <div className="datalist-col datalist-col-price flex-1">{price(item.get("total"))}</div>
            {!isNew || isProblem ? (
              <div className="datalist-col datalist-col-status flex-1 layout-row layout-align-start-stretch">
                <div className="flex-4 layout-column">{item.get("treatment")}</div>
                <div className="icons flex-1 layout-row layout-align-end-center">{this.renderTransporterIcon()}</div>
              </div>
            ) : null}
            <div className="datalist-col datalist-col-datetime flex-1">
              <span data-tip={orderMoment.format("DD-MM-YYYY HH:mm:ss")}>{orderMoment.format("DD-MM-YYYY HH:mm")}</span>
            </div>

            {!isSearchView && item.has("tag") ? (
              <div className="datalist-col datalist-col-faicon">
                <i
                  className="fa fa-fw fa-times"
                  data-tip={`Verwijder uit ${item.getIn(["tag", "title"])}`}
                  onClick={this.onRemoveOrderFromTag}
                />
              </div>
            ) : null}
          </div>
        </Link>
      </div>,
      {
        dropEffect: "move"
      }
    );
  }
}

DataListItemRenderer.propTypes = {
  kmtUrl: PropTypes.string,
  removeItem: PropTypes.func,
  removeOrderFromTag: PropTypes.func,
  isDragging: PropTypes.bool,
  connectDragSource: PropTypes.func,
  text: PropTypes.any,
  orderId: PropTypes.number,
  item: PropTypes.object, // immutable.map
  isNew: PropTypes.bool,
  isSearchView: PropTypes.bool,
  isProblem: PropTypes.bool,
  options: PropTypes.shape({
    displayMorningDeliveryIcon: PropTypes.bool,
    displayAfternoonDeliveryIcon: PropTypes.bool,
    displayEveningDeliveryIcon: PropTypes.bool,
    displayBcCardIcon: PropTypes.bool,
    displayTransporterIcon: PropTypes.bool,
    displayMourningIcon: PropTypes.bool,
    displayPaymentIcon: PropTypes.bool,
    displayOrderCount: PropTypes.bool,
    displayOpenInvoices: PropTypes.bool,
    displayBlacklisted: PropTypes.bool,
    displayPhotoServiceIcon: PropTypes.bool
  })
};

const DraggableDataListItemRenderer = connect(
  () => ({}),
  {
    removeItem,
    removeOrderFromTag
  }
)(
  DragSource(
    "OrderListItem",
    {
      beginDrag(props) {
        return {
          orderId: props.orderId,
          order: props.item,
          isNew: props.isNew,
          isProblem: props.isProblem
        };
      },
      endDrag(props, monitor) {
        const result = monitor.getDropResult();
        if (!result || !result.dropped) {
          return;
        }
        props.removeItem(monitor.getItem().order.get("id"));
      }
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(DataListItemRenderer)
);

export class OrderDataList extends React.Component {
  componentDidMount() {
    const isLoading = !this.props.isSearchView || this.props.query;

    if (isLoading) {
      this.props.findOrders(this.props.tags, this.props.page, this.props.query);
      this.props.fetchTags(this.props.query);
      this.props.fetchDeliveryExpiredTags("");
    }
  }

  componentWillReceiveProps(props) {
    const isLoading = !this.props.isSearchView || this.props.query;

    if (
      (isLoading && !Immutable.is(this.props.tags, props.tags)) ||
      this.props.page !== props.page ||
      this.props.query !== props.query
    ) {
      this.props.fetchTags(props.query, true);
      this.props.findOrders(props.tags, props.page, props.query);
      this.props.fetchDeliveryExpiredTags("");
    }
  }

  renderWrapper = children => (
    <Paper className={`${classes.OrderListPaper} Paper`}>
      <div className="">{children}</div>
    </Paper>
  );

  dataListRenderer = () => {
    const isNew = this.props.tags.includes("unprocessed");
    const isTagged = this.props.tags.filter(tag => isNumber(tag)).size === 1;
    const isProblem = this.props.tags.filter(tag => tag === 23279793).size === 1;

    const {
      isSearchView,
      displayMorningDeliveryIcon,
      displayAfternoonDeliveryIcon,
      displayEveningDeliveryIcon,
      displayBcCardIcon,
      displayTransporterIcon,
      displayMourningIcon,
      displayPhotoServiceIcon,
      displayPaymentIcon,
      displayOrderCount,
      displayOpenInvoices,
      displayBlacklisted
    } = this.props;

    const options = {
      displayMorningDeliveryIcon,
      displayAfternoonDeliveryIcon,
      displayEveningDeliveryIcon,
      displayBcCardIcon,
      displayTransporterIcon,
      displayMourningIcon,
      displayPhotoServiceIcon,
      displayPaymentIcon,
      displayOrderCount,
      displayOpenInvoices,
      displayBlacklisted
    };

    return (
      <div className={`datalist ${this.props.isFetching ? "datalist-fetching" : ""}`}>
        <div className="datalist-row datalist-header-row layout-row layout-align-start-center">
          <div className="datalist-col datalist-col-icon flex-1" />
          <div className="datalist-col datalist-col-id flex-1">OrderNr</div>
          <div className="datalist-col datalist-col-date flex-1">Leverdatum</div>
          <div className="datalist-col flex-4">Opdrachtgever</div>
          <div className="datalist-col datalist-col-price flex-1">Bedrag</div>
          {!isNew || isProblem ? <div className="datalist-col datalist-col-status flex-1">Status</div> : null}
          <div className="datalist-col datalist-col-datetime flex-1">Besteldatum</div>
          {isTagged ? <div className="datalist-col datalist-col-faicon" /> : null}
        </div>
        <ReactTooltip effect="solid" />
        {this.props.data
          .getIn(["items", "data"])
          .valueSeq()
          .map(item => (
            <DraggableDataListItemRenderer
              options={options}
              removeItem={this.props.removeItem}
              isSearchView={isSearchView}
              removeOrderFromTag={this.props.removeItem}
              key={item}
              orderId={this.props.orderId}
              item={item}
              isNew={isNew}
              isProblem={isProblem}
              kmtUrl={this.props.kmtUrl}
            />
          ))}
      </div>
    );
  };

  render() {
    if (this.props.orderAction) {
      return null;
    }
    // if (this.props.data.get('isFetching')) {
    //   return this.renderWrapper(<Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />);
    // }
    //
    // if (this.props.error) {
    //   return this.renderWrapper(<Error {...this.props.error} />);
    // }

    return (
      <div className={`${classes.OrderListWrapper}`}>
        {this.renderWrapper(<TabContainer isSearchView={this.props.isSearchView} tags={this.props.tags} />)}
        {this.renderWrapper(
          <PaginatedDataList
            isSearchView={this.props.isSearchView}
            tags={this.props.tags}
            data={this.props.data}
            onRefresh={this.props.findOrders}
            hasTotal={__DEV__}
            renderer={this.dataListRenderer}
          />
        )}
      </div>
    );
  }
}

OrderDataList.propTypes = {
  displayMorningDeliveryIcon: PropTypes.bool,
  displayAfternoonDeliveryIcon: PropTypes.bool,
  displayEveningDeliveryIcon: PropTypes.bool,
  displayBcCardIcon: PropTypes.bool,
  displayTransporterIcon: PropTypes.bool,
  displayMourningIcon: PropTypes.bool,
  displayPhotoServiceIcon: PropTypes.bool,
  displayPaymentIcon: PropTypes.bool,
  displayOrderCount: PropTypes.bool,
  displayOpenInvoices: PropTypes.bool,
  displayBlacklisted: PropTypes.bool,
  kmtUrl: PropTypes.string,
  isSearchView: PropTypes.bool,
  removeOrderFromTag: PropTypes.func,
  tags: PropTypes.object, // immutable.list
  query: PropTypes.string,
  searchString: PropTypes.string,
  pagination: PropTypes.object, // immutable.map
  findOrders: PropTypes.func,
  fetchTags: PropTypes.func,
  fetchDeliveryExpiredTags: PropTypes.func,
  removeItem: PropTypes.func,
  location: PropTypes.object,
  orderAction: PropTypes.string,
  isFetching: PropTypes.bool,
  error: PropTypes.any,
  data: PropTypes.object,
  orderId: PropTypes.number,
  page: PropTypes.any
};

export class OrderView extends React.Component {
  render() {
    const { isZoomed, orderId, orderAction } = this.props;
    return (
      <ModuleView>
        <div className="layout-row fix-width">
          <div className={`layout-fill layout-column flex big-column ${isZoomed ? "" : "big-column--sticky"}`}>
            <OrderDataList {...this.props} />
            {orderId && orderAction ? <OrderAction action={orderAction} orderId={orderId} /> : null}
          </div>
          {this.props.params.id === "create" ? (
            <OrderCreateContainer className="order-detail small-column" />
          ) : (
            <OrderDetail className="order-detail small-column" orderId={orderId} />
          )}
        </div>
      </ModuleView>
    );
  }
}

OrderView.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  isZoomed: PropTypes.bool,
  kmtUrl: PropTypes.string,
  removeOrderFromTag: PropTypes.func,
  findOrders: PropTypes.func,
  fetchTags: PropTypes.func,
  location: PropTypes.object,
  tags: PropTypes.object, // immutable.list
  query: PropTypes.string,
  searchString: PropTypes.string,
  data: PropTypes.any,
  orderId: PropTypes.any,
  orderAction: PropTypes.any,
  page: PropTypes.any,
  error: PropTypes.any
};

export default DragDropContext(HTML5Backend)(OrderView);

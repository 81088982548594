import Immutable from "immutable";
import api from "lib/Api";

export const DATA_FETCH = "@app/florist/FETCH";
export const DATA_RECEIVE = "@app/florist/RECEIVE";
export const DATA_FETCH_ERROR = "@app/florist/FETCH_ERROR";

export const DATA_FETCH_ONE = "@app/florist/FETCH_ONE";
export const DATA_RECEIVE_ONE = "@app/florist/RECEIVE_ONE";
export const DATA_FETCH_ONE_ERROR = "@app/florist/FETCH_ONE_ERROR";

export const SCHEMA_FETCH = "@app/florist/SCHEMA_FETCH";
export const SCHEMA_RECEIVE = "@app/florist/SCHEMA_RECEIVE";
export const SCHEMA_FETCH_ERROR = "@app/florist/SCHEMA_FETCH_ERROR";

export const DATA_UPDATE = "@app/florist/UPDATE";
export const DATA_UPDATE_RECEIVE = "@app/florist/UPDATE_RECEIVE";
export const DATA_UPDATE_ERROR = "@app/florist/UPDATE_ERROR";

// ------------------------------------
// Action Generators
// ------------------------------------

export const find = () => (dispatch, getState) => {
  dispatch({ type: DATA_FETCH });
  return api("/workbench/florist")
    .find()
    .then(data => {
      dispatch({ type: DATA_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FETCH_ERROR, payload: err });
    });
};

export const findById = id => (dispatch, getState) => {
  dispatch({ type: DATA_FETCH_ONE, payload: id });
  return api("/workbench/florist")
    .findById(id)
    .then(data => {
      dispatch({ type: DATA_RECEIVE_ONE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FETCH_ONE_ERROR, payload: err });
    });
};

export const update = data => (dispatch, getState) => {
  dispatch({ type: DATA_UPDATE, payload: { id: data.id, data } });
  return api("/workbench/order")
    .update(data.id, data)
    .then(data => {
      dispatch({ type: DATA_UPDATE_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_UPDATE_ERROR, payload: err });
      throw err;
    });
};

export const fetchSchema = () => (dispatch, getState) => {
  dispatch({ type: SCHEMA_FETCH });
  return api("/schema")
    .find("Florist")
    .then(data => dispatch({ type: SCHEMA_RECEIVE, payload: data }))
    .catch(err => dispatch({ type: SCHEMA_FETCH_ERROR, payload: err }));
};

// ------------------------------------
// Initial State
// ------------------------------------
const State = new Immutable.Record({
  schema: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.Map()
  }),
  list: Immutable.Map({
    isFetching: false,
    error: "",
    items: Immutable.Map({
      meta: Immutable.Map(),
      data: Immutable.Map()
    })
  }),
  active: Immutable.Map({
    isFetching: false,
    isUpdating: false,
    error: "",
    data: Immutable.Map()
  }),
  errors: Immutable.Map()
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [DATA_FETCH]: (state, { payload }) => state.setIn(["list", "isFetching"], true),
  [DATA_RECEIVE]: (state, { payload }) =>
    state.setIn(["list", "isFetching"], false).setIn(["list", "items"], Immutable.fromJS(payload)),
  [DATA_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["list", "isFetching"], false).setIn(["list", "error"], payload),

  [DATA_FETCH_ONE]: (state, { payload }) =>
    state.set("active", new State().get("active")).setIn(["active", "isFetching"], true),
  [DATA_RECEIVE_ONE]: (state, { payload }) =>
    state.setIn(["active", "isFetching"], false).setIn(["active", "data"], Immutable.fromJS(payload)),
  [DATA_FETCH_ONE_ERROR]: (state, { payload }) =>
    state.setIn(["active", "isFetching"], false).setIn(["active", "error"], Immutable.fromJS(payload)),

  [DATA_UPDATE]: (state, { payload }) => state.setIn(["active", "isUpdating"], true),
  [DATA_UPDATE_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["active", "isFetching"], false)
      .setIn(["active", "isUpdating"], false)
      .setIn(["active", "data"], Immutable.fromJS(payload)),
  [DATA_UPDATE_ERROR]: (state, { payload }) =>
    state.setIn(["order", "isUpdating"], false).setIn(["order", "error"], payload),

  [SCHEMA_FETCH]: (state, { payload }) => state.setIn(["schema", "isFetching"], true),
  [SCHEMA_RECEIVE]: (state, { payload }) =>
    state.setIn(["schema", "isFetching"], false).setIn(["schema", "data"], Immutable.fromJS(payload)),
  [SCHEMA_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["schema", "isFetching"], false).setIn(["schema", "error"], Immutable.fromJS(payload))
};

// ------------------------------------
// Reducer
// ------------------------------------
export function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import React from "react";
import PropTypes from "prop-types";

const Alert = ({ type, message, children, className }) => (
  <div className={`alert alert-${type} ${className}`}>
    <div style={{ display: "inline-block", paddingRight: "8px" }}>
      <i className="fa fa-fw fa-exclamation-circle" />
    </div>
    <div style={{ display: "inline-block" }}>{message || children}</div>
  </div>
);

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node
};

Alert.defaultProps = {
  className: "",
  type: "info",
  message: null,
  children: null
};

export default Alert;

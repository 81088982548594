import PropTypes from "prop-types";
import React from "react";
import Button from "components/Button";
import moment from "lib/moment";
import { SmallLoader } from "components/Loader";

export default class CardButton extends React.Component {
  render() {
    const { title, onClick, printing, printed, disabled } = this.props;

    return (
      <div className="flex layout-column">
        <div className="layout-column margin-bottom-5">
          <Button onClick={onClick} disabled={printing || disabled}>
            {printing ? <SmallLoader /> : null} {title}
          </Button>
        </div>
        <div className="layout-column">
          {printed ? (
            <div className="layout-column margin-bottom-5 layout-align-center-center layout-align-center center">
              Geprint op {moment(printed * 1000).format("D MMMM YYYY HH:mm")}
            </div>
          ) : (
            <div className="layout-column" />
          )}
        </div>
      </div>
    );
  }
}

CardButton.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  printing: PropTypes.bool,
  printed: PropTypes.number
};

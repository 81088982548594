import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";
import Immutable from "immutable";
import ucFirst from "locutus/php/strings/ucfirst";

import { SmallLoader } from "../../../components/Loader";
import { Input, Select, Button } from "../../../components/Form";
import { persistConfig } from "../../../store/modules/App";
import ModuleView from "../../../components/ModuleView";
import Paper from "../../../components/Paper";
import "./LocationView.scss";

export const PrinterSelect = props => <Select {...props} />;

export class LocationView extends React.Component {
  onSubmit = data => this.props.persistConfig(data);

  render() {
    console.log(this.props.printers);
    return (
      <ModuleView title="Locatie configuratie">
        <Paper>
          <h2>Locatie</h2>
          <Input
            placeholder="Locatie naam"
            name="name"
            description="De naam van deze locatie; dit word gebruikt om andere gebruikers te kunnen attenderen"
          />
        </Paper>
        <Paper>
          <h2>Printers</h2>

          <PrinterSelect
            placeholder="Order printer"
            name="printers.order"
            description={
              <div>
                De printer tbv het printen van <strong>Bestellingen</strong>
              </div>
            }
            options={this.props.printers}
          />

          <PrinterSelect
            placeholder="Kaartje 1 printer"
            name="printers.card1"
            description={
              <div>
                De printer tbv het printen van <strong>Kaartje 1</strong>
              </div>
            }
            options={this.props.printers}
          />

          <PrinterSelect
            placeholder="Kaartje 2 printer"
            name="printers.card2"
            description={
              <div>
                De printer tbv het printen van <strong>Kaartje 2</strong>
              </div>
            }
            options={this.props.printers}
          />

          {this.props.transporters.map(transporter => {
            const title = ucFirst(transporter.get("title", transporter.get("id")));
            return (
              <PrinterSelect
                key={`printers.${transporter.get("id")}`}
                placeholder={`${title} label printer`}
                name={`printers.${transporter.get("title")}`}
                description={
                  <div>
                    De printer tbv het printen van <strong>{title} labels</strong>
                  </div>
                }
                options={this.props.printers}
              />
            );
          })}

          <div className="d-flex align-items-center justify-content-end">
            <Button
              large
              onClick={this.props.handleSubmit(this.onSubmit)}
              disabled={this.props.pristine || this.props.submitting}
            >
              {this.props.submitting ? <SmallLoader /> : ""}
              {this.props.error ? (
                <i className="fa fa-exclamation-circle" title={this.props.error}>
                  &nbsp;
                </i>
              ) : (
                ""
              )}
              Opslaan
            </Button>
            <Button large onClick={this.props.reset} disabled={this.props.pristine || this.props.submitting}>
              Herstellen
            </Button>
          </div>
        </Paper>
      </ModuleView>
    );
  }
}

LocationView.propTypes = {
  ...reduxFormPropTypes,
  persistConfig: PropTypes.func
};

const EMPTY_LIST = new Immutable.List();

const getPrinterStatusColor = status => {
  switch (status) {
    case "idle":
    case "online":
      return "green";
    case "out_of_paper":
    case "disconnected":
      return "orange";
    case "error":
      return "red";
    case "offline":
    default:
      return "gray";
  }
};

export default connect(
  state => ({
    initialValues: state.app.get("config").toJS(),
    transporters: state.app
      .get("features")
      .filter(
        f =>
          f.get("extraAttribute_28088960") === "transporter" &&
          f.get("extraAttribute_28088961") &&
          f.get("extraAttribute_28088962")
      ),
    printers: state.app.getIn(["printers", "data"], EMPTY_LIST).reduce(
      (printers, printer) =>
        printers.set(
          printer.get("id"),
          <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
            {printer.get("state") && (
              <div style={{ marginRight: "0.5rem" }}>
                <i
                  className="fa fa-circle"
                  style={{ fontSize: 8, color: `${getPrinterStatusColor(printer.get("state"))}` }}
                />
              </div>
            )}
            <div style={{ marginRight: "0.5rem" }}>
              {printer.get("name")
                ? `${printer.get("name")} (${printer.get("displayName")})`
                : printer.get("displayName")}
            </div>
            <div className="PrinterOption_MetaData">
              {printer.get("computerName") && (
                <div style={{ marginRight: "0.5rem" }}>op {printer.get("computerName")}</div>
              )}
              {printer.get("provider") && <div>[{printer.get("provider")}]</div>}
            </div>
          </div>
        ),
      new Immutable.OrderedMap({})
    )
  }),
  {
    persistConfig
  }
)(
  reduxForm({
    form: "location",
    enableReinitialize: true,
    validate: data => {
      const errors = {};
      if (!data) {
        return errors;
      }

      if (!data.name) {
        errors.name = "Dit veld is vereist";
      }

      errors.printers = {};

      if (!data.printers || !data.printers.order) {
        errors.printers.order = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.card1) {
        errors.printers.card1 = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.card2) {
        errors.printers.card2 = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.dynalogic) {
        errors.printers.dynalogic = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.postnl) {
        errors.printers.postnl = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.redjepakketje) {
        errors.printers.redjepakketje = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.reviva) {
        errors.printers.reviva = "Dit veld is vereist";
      }

      if (!data.printers || !data.printers.trunkrs) {
        errors.printers.trunkrs = "Dit veld is vereist";
      }

      return errors;
    }
  })(LocationView)
);

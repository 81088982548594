import React from "react";
import PropTypes from "prop-types";

import Spinner from "./Spinner";

const LoaderContainer = ({ message, children, messagePosition, color, backgroundColor, spinnerSize }) => {
  const containerClasses = ["d-flex"];
  const messageClasses = ["p-4"];
  switch (messagePosition) {
    case "top":
      containerClasses.push("flex-column");
      containerClasses.push("flex-column-reverse");
      containerClasses.push("align-items-center");
      messageClasses.push("mb-4");
      break;
    case "right":
      containerClasses.push("flex-row");
      containerClasses.push("align-items-center");
      messageClasses.push("ml-3");
      break;
    case "bottom":
      containerClasses.push("flex-column");
      containerClasses.push("align-items-center");
      messageClasses.push("mt-3");
      break;
    case "left":
      containerClasses.push("flex-row");
      containerClasses.push("flex-row-reverse");
      containerClasses.push("align-items-center");
      messageClasses.push("mr-3");
      break;
    default:
      break;
  }
  return (
    <div className="Loader d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor }}>
      <div className={containerClasses.join(" ")} style={{ color }}>
        <Spinner color={color} style={{ maxWidth: spinnerSize }} />
        <div className={messageClasses.join(" ")}>{children || message}</div>
      </div>
    </div>
  );
};

LoaderContainer.propTypes = {
  message: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  messagePosition: PropTypes.oneOf(["top", "right", "bottom", "right"]),
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  spinnerSize: PropTypes.number
};

LoaderContainer.defaultProps = {
  message: null,
  children: null,
  messagePosition: "right",
  color: "gray",
  backgroundColor: "transparent",
  spinnerSize: 30
};

export const SmallLoader = () => <LoaderContainer spinnerSize={12} color={"white"} />;

export default LoaderContainer;

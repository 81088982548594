import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

class GalleryThumbnail extends Component {
  static propTypes = {
    orderId: PropTypes.number,
    onDelete: PropTypes.func,
    canDelete: PropTypes.bool,
    attachment: PropTypes.instanceOf(Immutable.Map)
  };

  static defaultProps = {};

  state = {
    isDeleting: false,
    canDelete: true
  };

  onOpen = () => {
    window.open(`/download/?id=${this.props.attachment.get("id")}&fileType=auto`, "_blank");
  };

  onDelete = () => {
    this.setState({ isDeleting: true }, () => {
      this.props.onDelete(this.props.attachment.get("id"));
    });
  };

  render() {
    const { attachment, canDelete } = this.props;
    return (
      <div className="OrderPhotoGallery__Images__Image" key={attachment.get("id")}>
        <img src={`/download/?id=${attachment.get("id")}&width=170&fileType=auto`} alt="" />
        <div className="OrderPhotoGallery__Images__Image__Controls">
          <button onClick={this.onOpen} disabled={this.state.isDeleting}>
            <i className="fa fa-fw fa-search-plus" />
          </button>
          {canDelete && (
            <React.Fragment>
              <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <button onClick={this.onDelete} disabled={this.state.isDeleting}>
                {this.state.isDeleting ? (
                  <i className="fa fa-fw fa-spin fa-cog" />
                ) : (
                  <i className="fa fa-fw fa-trash" />
                )}
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default GalleryThumbnail;

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";
import { toastr } from "react-redux-toastr";

import { TIMEFRAME_ID_STANDARD } from "../../../../lib/constants";
import Button from "../../../../components/Button";
import Loader, { SmallLoader } from "../../../../components/Loader";
import { Input, Select, SubmissionError } from "../../../../components/Form";
import { fetchDynalogicOptions, sendToDynalogic } from "../../modules/Order";
import moment from "../../../../lib/moment";

const getSelectCompatArray = imap => {
  if (!imap || !imap.size) {
    return [];
  }
  return imap
    .map(item => ({
      value: item.get("id"),
      label: item.get("title")
    }))
    .toArray();
};

export class SendToDynalogic extends React.Component {
  componentDidMount() {
    this.props.fetchDynalogicOptions(this.props.orderId);
  }

  onSubmit = data =>
    // validated data
    this.props
      .sendToDynalogic(this.props.orderId, data)
      .then(() => {
        this.props.doClose(`Order ${this.props.orderId} is bij Dynalogic aangemeld`);
        this.props.doAddToPrintQueue("dynalogic");
      })
      .catch(err => {
        console.log(err);
        if (err.body.errors) {
          const msg = err.message.indexOf("SchemaValidationException")
            ? "Er zijn validatie fouten opgetreden, controleer uw invoer."
            : err.message;
          toastr.error("Kan niet bij Dynalogic aanmelden!", msg);
          throw new SubmissionError({ ...err.body.errors, _error: msg });
        }
        toastr.error("Kan niet bij Dynalogic aanmelden!", err.message);
        throw new SubmissionError({ _error: err.message });
      });

  getOrderKinds = () => getSelectCompatArray(this.props.config.get("orderKind"));

  getDeliveryTimes = () => getSelectCompatArray(this.props.config.getIn(["timeframes", "options"]));

  render() {
    if (this.props.isFetching) {
      return (
        <div>
          <h2>Meld aan bij Dynalogic</h2>
          <Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />
        </div>
      );
    }

    return (
      <div>
        <h2>Meld aan bij Dynalogic</h2>
        <form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Input type="hidden" name="orderId" />

          <Select label="Opdrachttype" required name="orderKind" options={this.getOrderKinds()} />

          <Input label="Bezorgdatum" required type="date" name="preferredDeliveryDate" />

          <Select label="Bezorgtijd" required name="preferredDeliveryTime" options={this.getDeliveryTimes()} />

          <Input label="Coli" name="coli" type="number" />

          <Input label="Gewicht in KG" name="weight" type="number" />

          <Input label="Opmerking voor bestuurder" name="remark" />

          <Input label="Telefoon" name="phone" />

          <Input label="Bedrijfsnaam" name="companyName" />

          <Input label="Naam" name="name" />

          <Input label="Fax" name="fax" />

          <Input label="E-mail" name="email" type="email" />

          <div className="offset-sm-3" style={{ textAlign: "center" }}>
            <Button type="submit" className="col-sm-8" disabled={this.props.submitting}>
              {this.props.submitting ? <SmallLoader /> : ""}
              {!this.props.submitting && this.props.error ? (
                <i className="fa fa-exclamation-circle" title={this.props.error}>
                  &nbsp;
                </i>
              ) : (
                ""
              )}
              Meld aan bij Dynalogic
            </Button>
          </div>

          {this.props.error ? (
            <div className="offset-sm-3">
              <br />
              <div className="alert alert-danger layout-row">
                <div>
                  <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                </div>
                <div>
                  <strong>Er is een fout opgetreden</strong>
                  <br />
                  {this.props.error}
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
SendToDynalogic.propTypes = {
  ...reduxFormPropTypes,
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object, // immutable.map
  config: PropTypes.object, // immutable.map
  fetchDynalogicOptions: PropTypes.func,
  doAddToPrintQueue: PropTypes.func,
  isFetching: PropTypes.bool
};

const getRemark = order => {
  if (order.get("deliveryDate") && moment(order.get("deliveryDate")).format("MMDD") === "1224") {
    // return 'In verband met kerst dit versproduct altijd afgeven, meerdere omwonenden proberen.';
    return (
      "Graag altijd leveren, is kerstcadeau!. " +
      "Indien niet thuis meerdere buren/omwonenden proberen met kaart in bus. " +
      "Bij problemen bellen: 023-7370619"
    );
  }

  if (order.get("deliveryDateTime") || order.get("isMourning")) {
    // church or funeral service
    return "Indien problemen bij afleveren, bel naar BoeketCadeau 023 526 5909";
  }

  return "Bij niet aanwezig afleveren bij buren met kaart in brievenbus bij de ontvanger waar geleverd";
};

const initialValuesSelector = createSelector(
  [state => state.get("data"), state => state.get("dynalogic")],
  (order, dynalogic) => {
    let orderKind = "geen";
    if (order.get("deliveryDateTime") || order.get("isMourning")) {
      orderKind = "BOEKROUW";
    }
    if (order.get("isEveningDelivery")) {
      orderKind = "SEVERSAFL";
    }
    return {
      orderId: order.get("id"),
      orderKind,
      preferredDeliveryTime: dynalogic.getIn(["config", "timeframes", "selected"], TIMEFRAME_ID_STANDARD),
      preferredDeliveryDate: order.get("deliveryDate"),
      remark: getRemark(order),
      coli: 1,
      weight: 3,
      phone: order.getIn(["recipient", "phoneNumber"]),
      companyName: order.getIn(["recipient", "company"]),
      name: order.getIn(["recipient", "name"]),
      fax: "",
      email: order.getIn(["customer", "emailAddress"])
    };
  }
);

export default connect(
  (state, props) => {
    const order = state.order.get("order");
    return {
      isFetching: order.get("isFetching") || order.getIn(["dynalogic", "isFetching"]),
      initialValues: initialValuesSelector(order),
      config: order.get("dynalogic").get("config")
    };
  },
  {
    fetchDynalogicOptions,
    sendToDynalogic
  }
)(
  reduxForm({
    form: "SendToDynalogic",
    enableReinitialize: true,
    validate: data => {
      const errors = {};
      if (!data) {
        return errors;
      }

      if (!data.preferredDeliveryDate) {
        errors.preferredDeliveryDate = "Dit veld is vereist";
      }

      if (!data.orderKind || data.orderKind === "geen") {
        errors.orderKind = "Dit veld is vereist";
      }

      if (!data.preferredDeliveryTime) {
        errors.preferredDeliveryTime = "Dit veld is vereist";
      }
      return errors;
    },
    warn: values => {
      const warnings = {};

      // if (values.card && values.card.text) {
      //   warnings.card = {};
      //   warnings.card.text = 'Hmm, you seem a bit young...';
      // }

      return warnings;
    }
  })(SendToDynalogic)
);

/* eslint-disable react/jsx-no-bind */
import PropTypes from "prop-types";

import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import ModuleView from "../../../components/ModuleView";
import { hydrateStatistics, toggleDashboardWidget } from "../modules/Admin";
import Widget from "./Dashboard/Widget";

// window.Chart.defaults.global.responsive = true;

/* global Chart */
Chart.Tooltip.positioners.fixed = function fixedTooltipPosition(elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  // var tooltip = this;
  return { ...eventPosition, y: 60 };
};

import "./DashboardView.scss";

const colors = [
  "rgba(219,197,142,0.5)",
  "rgba(215,174,234,0.5)",
  "rgba(156,196,141,0.5)",
  "rgba(161,187,236,0.5)",
  "rgba(222,239,181,0.5)",
  "rgba(209,182,223,0.5)",
  "rgba(108,201,177,0.5)",
  "rgba(233,166,183,0.5)",
  "rgba(94,198,207,0.5)",
  "rgba(234,164,140,0.5)",
  "rgba(120,197,231,0.5)",
  "rgba(238,189,147,0.5)",
  "rgba(140,228,238,0.5)",
  "rgba(198,166,117,0.5)",
  "rgba(167,232,218,0.5)",
  "rgba(203,178,146,0.5)",
  "rgba(182,234,193,0.5)",
  "rgba(241,216,177,0.5)",
  "rgba(145,192,170,0.5)",
  "rgba(191,196,145,0.5)"
];

export class DashboardView extends React.Component {
  static propTypes = {
    hydrateStatistics: PropTypes.func.isRequired,
    toggleDashboardWidget: PropTypes.func.isRequired,
    widgets: PropTypes.array.isRequired,
    hiddenWidgets: PropTypes.array.isRequired
  };

  componentDidMount() {
    this.props.hydrateStatistics();
  }

  onUnhideWidget = widgetId => {
    this.props.toggleDashboardWidget(widgetId);
  };

  setRef = e => {
    this.containerRef = ReactDOM.findDOMNode(e);
    if (this.containerRef) {
      // this forces a re-render
      this.setState({ width: this.containerRef.offsetWidth });
    }
  };

  render() {
    const { widgets, hiddenWidgets } = this.props;
    const isEven = widgets.length % 2 === 0;
    const lastIdx = widgets.length - 1;

    return (
      <ModuleView className="DashboardView">
        <div className="DashboardView__Widgets" ref={this.setRef}>
          {widgets.map((widget, idx) => {
            if (!this.containerRef) {
              return null;
            }
            const containerWidth = this.containerRef.offsetWidth;

            const halfWidth = containerWidth / 2 - 12 - 30;
            const fullWidth = containerWidth - 12 - 30 * 2;

            let width = widgets.length === 1 ? fullWidth : halfWidth;
            if (idx === lastIdx && !isEven) {
              width = fullWidth;
            }
            const style = {
              // flex: 1
              width
            };
            return (
              <Widget
                key={`dashboard_widget_${widget.title}`}
                id={widget.title}
                title={widget.extraAttribute_28090100}
                style={style}
                type="graph"
                config={{
                  type: "line",
                  color: colors[idx],
                  width: width - 30,
                  height: 250,
                  responsive: false,
                  options: {
                    responsive: false,
                    // plugins: {
                    //   datalabels: {
                    //     display: id === 'secondChance'
                    //   }
                    // },
                    // legend: {
                    //   display: id === 'secondChance'
                    // },
                    tooltips: {
                      mode: "index",
                      intersect: false,
                      position: "fixed"
                      // itemSort: (a, b) => {
                      //   if (a.yLabel > b.yLabel) {
                      //     return -1;
                      //   }
                      //   if (a.yLabel < b.yLabel) {
                      //     return 1;
                      //   }
                      //   return 0;
                      // }
                    }
                  }
                }}
              />
            );
          })}
        </div>
        {hiddenWidgets.length > 0 && (
          <div className="DashboardView__DisabledWidgets">
            <div>Verborgen</div>
            {hiddenWidgets.map(widget => (
              <div
                key={`dashboard_disabledwidget_${widget.title}`}
                className="DashboardView__DisabledWidgets__DisabledWidget"
              >
                {widget.title}
                <i className="fa fa-times ml-3 p-3" onClick={this.onUnhideWidget.bind(this, widget.title)} />
              </div>
            ))}
          </div>
        )}
      </ModuleView>
    );
  }
}

const selectWidgets = createSelector(
  [state => state.app.get("features")],
  features =>
    features
      .filter(f => f.get("extraAttribute_28088960") === "graph" && f.get("extraAttribute_28088961"))
      .sort((a, b) => {
        if (a.get("extraAttribute_28090089") > b.get("extraAttribute_28090089")) {
          return 1;
        }
        if (a.get("extraAttribute_28090089") < b.get("extraAttribute_28090089")) {
          return -1;
        }
        return 0;
      })
      .toJS()
);

const selectDashboardSettings = createSelector(
  [state => state.admin.get("dashboard")],
  settings => settings
);

const selectDisplayedWidgets = createSelector(
  [state => selectWidgets(state), state => selectDashboardSettings(state)],
  (widgets, settings) => widgets.filter(widget => !settings.getIn(["widgets", widget.title, "hidden"], false))
);

const selectHiddenWidgets = createSelector(
  [state => selectWidgets(state), state => selectDashboardSettings(state)],
  (widgets, settings) => widgets.filter(widget => settings.getIn(["widgets", widget.title, "hidden"], false))
);

export default connect(
  state => ({
    widgets: selectDisplayedWidgets(state),
    hiddenWidgets: selectHiddenWidgets(state)
  }),
  {
    hydrateStatistics,
    toggleDashboardWidget
  }
)(DashboardView);

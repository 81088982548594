import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Immutable from "immutable";
import isNumber from "lodash/isNumber";
import ModuleView from "components/ModuleView";
import Paper from "~/components/Paper";
import Button from "~/components/Button";

import { fetchTags, addTag, deleteTag } from "../../Order/modules/Order";

export class TagsView extends React.Component {
  componentDidMount() {
    this.props.fetchTags();
  }

  onAddTag = () =>
    this.props.addTag(this.refs.taginput.value).then(() => {
      this.refs.taginput.value = "";
    });

  render() {
    return (
      <ModuleView title="Mappen beheer">
        <Paper>
          <h2>Nieuwe map aanmaken</h2>

          <div className="layout-row row">
            <div className="col-sm-6">
              <input
                className="form-control tag-control"
                ref="taginput"
                placeholder="Creeër map"
                onKeyDown={e => {
                  e.persist();
                  if (e.keyCode === 13) {
                    // VK_ENTER
                    this.onAddTag();
                  }
                }}
              />

              <Button disabled={false} onClick={this.onAddTag}>
                Toevoegen
              </Button>
            </div>
          </div>
        </Paper>
        <Paper>
          <h2>Mappen overzicht</h2>
          <div className="layout-row layout-wrap layout-align-start">
            {this.props.tags.valueSeq().map(tag => {
              if (!isNumber(tag.get("id"))) {
                return null;
              }
              const canDelete = tag.get("id") !== 23279793 && tag.get("count", 0) === 0;
              return <Tag key={tag} data={tag} canDelete={canDelete} deleteTag={this.props.deleteTag} />;
            })}
          </div>
        </Paper>
      </ModuleView>
    );
  }
}

TagsView.propTypes = {
  isFetching: PropTypes.bool,
  tags: PropTypes.object, // immutable.list
  addTag: PropTypes.func,
  deleteTag: PropTypes.func,
  fetchTags: PropTypes.func
};

// <input className="form-control" defaultValue={data.get('title')} />

export class Tag extends React.Component {
  onRemove = () => this.props.deleteTag(this.props.data.get("id"));

  render() {
    return (
      <div className="layout-row Tag">
        <div className="Tag__title">{this.props.data.get("title")}</div>

        <div className="Tag__count">({this.props.data.get("count")})</div>

        {!this.props.canDelete ? null : (
          <div className="Tag__delete">
            {this.props.data.get("isDeleting", false) ? (
              <i className="fa fa-spin fa-circle-o-notch" />
            ) : (
              <i data-tip="Verwijderen" className="fa fa-times" onClick={this.onRemove} />
            )}
          </div>
        )}
      </div>
    );
  }
}

Tag.propTypes = {
  canDelete: PropTypes.bool,
  deleteTag: PropTypes.func,
  data: PropTypes.object // immutable.map
};

export default connect(
  state => ({
    isFetching: state.order.getIn(["tags", "isFetching"], true),
    tags: state.order.getIn(["tags", "data"], new Immutable.List())
  }),
  {
    fetchTags,
    deleteTag,
    addTag
  }
)(TagsView);

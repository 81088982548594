import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import ucFirst from "locutus/php/strings/ucfirst";

import Paper from "../../../components/Paper";
import { scrollToTop } from "../../../store/modules/App";
import { confirm } from "./OrderDetail";
import { buildUrl } from "../../../lib/RouteUtil";
import { print } from "../modules/Order";

import ViewFlorist from "./OrderActions/ViewFlorist";
import PhotoGallery from "./OrderActions/PhotoGallery";

import SendToColleague from "./OrderActions/SendToColleague";
import SendToEurofloristEmail from "./OrderActions/SendToEurofloristEmail";
import SendToDynalogic from "./OrderActions/SendToDynalogic";
import SendToPostNL from "./OrderActions/SendToPostNL";
import SendToZorgeloosBezorgd from "./OrderActions/SendToZorgeloosBezorgd";
import SendToRedjepakketje from "./OrderActions/SendToRedjepakketje";
import AttachmentGallery from "./OrderActions/AttachmentGallery";

// this is the new one to rule them all
import SendToTransporter from "./OrderActions/SendToTransporter";

import classes from "./OrderAction.module.scss";

const actionWidgets = {
  addPhoto: PhotoGallery,
  attachments: AttachmentGallery,
  viewFlorist: ViewFlorist,
  sendToColleague: SendToColleague,
  sendToEuroFloristEmail: SendToEurofloristEmail,
  sendToDynalogic: SendToDynalogic,
  sendToPostnl: SendToPostNL,
  sendToZorgeloosBezorgd: SendToZorgeloosBezorgd,
  sendToRedjepakketje: SendToRedjepakketje
};

const toastError = (title, err) => {
  if (!err) {
    return;
  }
  toastr.error(title, err.body ? err.body.message : err.message);
};

export class OrderAction extends React.Component {
  componentDidMount() {
    this.props.scrollToTop();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.action !== this.props.action) {
      this.props.scrollToTop();
    }
  }

  doClose = () => {
    this.doActionClose(null);
  };

  doActionClose = message => {
    this.doCloseOrFinalise(message, this.props.orderId ? buildUrl() : buildUrl({ id: false, tags: ["unprocessed"] }));
  };

  doFinalised = message => {
    this.doCloseOrFinalise(message, buildUrl({ id: false }), true);
  };

  doCloseOrFinalise = (message, url, replace) => {
    if (message) {
      toastr.success(message);
    }
    browserHistory[replace ? "replace" : "push"](url);
    this.props.scrollToTop();
  };

  print = (orderId, jobType, shipmentId) => {
    const printerId = this.props.configuredPrinters.get(jobType);

    if (printerId === "_popup") {
      window.open(
        `/print/${orderId || shipmentId}/${jobType}/${printerId}`,
        `${jobType}print`,
        "width=500,height=500,scrollbars=yes,toolbar=no"
      );
      return Promise.resolve(false);
    }

    if (!printerId || !this.props.availablePrinters.find(item => item.get("id") === printerId)) {
      confirm({
        title: "Configuratie fout",
        message: (
          <div>
            Er is geen of een onjuist printer geconfigureerd.
            <br />
            Wil je naar de locatie configuratie?
          </div>
        ),
        onOk: () => browserHistory.push("/admin/location")
      });
      return Promise.reject(new Error("Configuration error"));
    }
    return this.props
      .print(orderId || shipmentId, jobType, printerId)
      .then(success => ({
        success,
        orderId
      }))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        throw err;
      });
  };

  doAddToPrintQueue = (jobType, shipmentId) =>
    this.print(this.props.orderId, jobType, shipmentId)
      .then(result => {
        if (result.success === false) {
          return;
        }
        toastr.success(`${ucFirst(jobType)} label is in de printer wachtrij`);
      })
      .catch(err => {
        toastError(`Kan de ${ucFirst(jobType)} label niet printen!`, err);
      });

  renderAction() {
    const actionProps = {
      orderId: this.props.orderId,
      doAddToPrintQueue: this.doAddToPrintQueue,
      doClose: this.doActionClose,
      doFinalised: this.doFinalised,
      transporter: this.props.action.replace("sendTo", "").toLowerCase()
    };

    if (
      this.props.action !== "sendToTrunkrs" &&
      this.props.action !== "sendToReviva" &&
      this.props.action !== "sendToRegio"
    ) {
      return React.createElement(actionWidgets[this.props.action], { ...actionProps });
    }

    if (/^sendTo/.test(this.props.action)) {
      return <SendToTransporter {...actionProps} />;
    }

    return null;
  }

  render() {
    const classNames = `layout-fill layout-column flex action-container ${classes.OrderAction}`;

    return (
      <Paper className={classNames} style={{ padding: 0, margin: 0 }} name="scrollToContainer">
        <div className="" style={{ padding: 15 }}>
          <div className="close-button">
            <span className="material-icons" onClick={this.doClose}>
              close
            </span>
          </div>
          {this.renderAction()}
        </div>
      </Paper>
    );
  }
}

OrderAction.propTypes = {
  location: PropTypes.string,
  orderId: PropTypes.number,
  action: PropTypes.string.isRequired,
  scrollToTop: PropTypes.func,
  print: PropTypes.func,
  configuredPrinters: PropTypes.object, // immutable.map
  availablePrinters: PropTypes.object // immutable.map
};

export default connect(
  state => ({
    location: state.location.pathname,
    configuredPrinters: state.app.getIn(["config", "printers"]),
    availablePrinters: state.app.getIn(["printers", "data"], [])
  }),
  {
    print,
    scrollToTop
  }
)(OrderAction);

// import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import Immutable from "immutable";
import isString from "lodash/isString";
import isEqual from "lodash/isEqual";
import classNames from "classnames";

import Select from "./Select";
import Datetime from "./Datetime";
// import FileUpload from "./FileUpload";

import "./index.scss";

export const getSelectCompatArray = (imap, valueKey) => {
  if (!imap) {
    return [];
  }
  valueKey = valueKey || "id";
  return imap.map(item => item.get(valueKey));
};

export const addOptionsToSchema = (schema, options) => {
  if (!options) {
    return schema;
  }
  return schema.set("enum", getSelectCompatArray(options)).set("enum_titles", getSelectCompatArray(options, "title"));
};

class SchemaField extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    path: PropTypes.string, // deprecated for name
    style: PropTypes.object,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    schema: PropTypes.instanceOf(Immutable.Map),
    parse: PropTypes.func,
    normalize: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    inputGroupRight: PropTypes.node,
    note: PropTypes.string,
    disabled: PropTypes.bool,
    unsafeLabel: PropTypes.bool,
    rows: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    inputType: PropTypes.string,
    type: PropTypes.string,
    labelRenderer: PropTypes.func,
    optionLabelRenderer: PropTypes.func,
    tooltip: PropTypes.string,
    fieldError: PropTypes.string,
    // editable: PropTypes.bool,
    showError: PropTypes.bool,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    wrappedLabel: PropTypes.bool
  };

  static contextTypes = {
    t: PropTypes.func,
    ajv: PropTypes.object,
    schema: PropTypes.instanceOf(Immutable.Map),
    _reduxForm: PropTypes.object
  };

  static defaultProps = {
    className: "",
    autoComplete: null,
    schema: null,
    disabled: false,
    unsafeLabel: false,
    label: true,
    placeholder: null,
    parse: null,
    normalize: null,
    note: null,
    inputType: null,
    labelRenderer: null,
    tooltip: null,
    path: null, // deprecated for name
    // editable: true,
    onClick: null,
    showError: true,
    onFocus: null,
    onBlur: null,
    wrappedLabel: false,
    fieldError: null
  };

  shouldComponentUpdate(nextProps) {
    // for some reason this does not work in the connect() "areMergedPropsEqual" option
    return !isEqual(this.props, nextProps);
  }

  getClassNames(extraClasses, meta) {
    const classes = ["schema-field"];

    if (this.props.disabled) {
      classes.push("schema-field-disabled");
    }
    if (this.props.className) {
      classes.push(this.props.className);
    }
    if (((meta.touched || meta.dirty) && meta.invalid && this.props.showError) || this.props.fieldError) {
      classes.push("has-error");
    } else if ((meta.touched || meta.dirty) && meta.valid) {
      classes.push("has-success");
    }

    if ((meta.touched && meta.error && this.props.showError) || this.props.fieldError) {
      classes.push("has-error");
      classes.push("has-danger");
      classes.push("is-invalid");
    }

    return classes.concat(extraClasses.split(" ")).join(" ");
  }

  getInputType(schema) {
    if (schema.get("type") === "number") {
      return this.props.type || "number";
    }
    if (schema.get("type") === "boolean") {
      return this.props.type || "checkbox";
    }
    return this.props.inputType || schema.get("inputType") || this.props.type || "text";
  }

  getElementId() {
    return `form-control-${(this.props.name || this.props.path).replace(/[[.]/gm, "-").replace(/]/gm, "")}`;
  }

  getContainerClassName(classes) {
    const classNames = [`SchemaField schema-${(this.props.name || this.props.path).replace(".", "-")}`];
    if (!classes) {
      return classNames.join(" ");
    }
    return classNames
      .concat(classes)
      .filter(v => !!v)
      .join(" ");
  }

  getLabelTitle = props => {
    const schema = props.schema || this.context.schema;

    const label = props.label === true ? null : props.label;
    const title = props.children || label || schema.get("title") || props.placeholder || null;

    if (!title) {
      return schema.get("description") || null;
    }
    return props.unsafeLabel ? (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{ __html: title }} />
    ) : (
      title
    );
  };

  getErrorMessage = props => {
    const { meta, schema } = props;
    const isRequired =
      meta.error === "required" ||
      (meta.error === "minLength" && schema.get("minLength") === 1 && schema.get("required")) ||
      (meta.error === "enum" && schema.get("required"));

    if (isRequired) {
      return "required";
    }

    if (
      (meta.error === "format" || meta.error === "pattern" || meta.error === "regexp") &&
      schema.get("format") === "email"
    ) {
      return "formatemail";
    }

    return meta.error;
  };

  trimInput = v => {
    if (isString(v)) {
      return v.replace(/^\s*/, "").replace(/\s+/m, " ");
    }
    return v;
  };

  normalizeBooleanValue = v => {
    if (v === "true") {
      return true;
    }
    if (v === "false") {
      return false;
    }
    return !!v;
  };

  normalizeIntegerValue = v => {
    if (v === undefined) {
      return v;
    }
    const _v = parseInt(v, 10);
    if (Number.isNaN(_v)) {
      return v;
    }
    return _v;
  };

  normalizeNumberValue = v => {
    if (v === undefined) {
      return v;
    }
    const _v = parseInt(v, 10);
    if (Number.isNaN(_v)) {
      return v;
    }
    return _v;
  };

  normalizeUriValue = v => {
    if (v === undefined) {
      return v;
    }
    if (!v) {
      return v;
    }
    if (/^\s*(h$|ht$|htt$|https?$|https?:|https?:\/|https?:\/\/)/.test(v)) {
      return v.trim();
    }
    return v.replace(/^/, "http://").trim();
  };

  renderRadio = props => {
    let parse = null;
    let normalize = null;
    if (props.defaultValue === null && props.title === null) {
      return (
        <Field
          key={props.defaultValue}
          name={this.props.name}
          component="input"
          type="hidden"
          disabled={this.props.disabled}
          parse={this.props.parse || parse}
          normalize={this.props.normalize || normalize}
        />
      );
    }
    if (Number.isInteger(props.defaultValue)) {
      parse = parseInt;
      normalize = this.normalizeIntegerValue;
    }
    return (
      <label key={props.defaultValue}>
        <Field
          name={this.props.name}
          component="input"
          type="radio"
          disabled={this.props.disabled}
          value={props.defaultValue}
          parse={this.props.parse || parse}
          normalize={this.props.normalize || normalize}
        />
        {this.props.labelRenderer ? this.props.labelRenderer(props, this.props) : props.title}
      </label>
    );
  };

  // eslint-disable-next-line no-unused-vars
  renderRadioGroup(props) {
    const { input, meta, ...rest } = props;
    const schema = rest.schema || this.context.schema;
    if (!schema.get("enum")) {
      return this.renderRadio(this.props.value, this.props.children || schema.get("title"));
    }
    return (
      <div className={this.getClassNames("schema-field-ui-radiogroup", meta)}>
        {schema.get("enum").map((v, idx) =>
          this.renderRadio({
            input,
            meta,
            schema,
            item: v,
            idx,
            defaultValue: v,
            title: schema.getIn(["enum_titles", idx], v)
          })
        )}
      </div>
    );
  }

  renderCheckBox(props) {
    if (props.wrappedLabel) {
      return this.renderWrappedCheckBox(props);
    }

    return (
      <div className={props.className}>
        <div className="SchemaField__CheckBox col-12 offset-sm-4 col-sm-8">
          <div className="form-check">
            {this.renderInputType(props)}
            {this.renderLabel(props)}
            {props.hasError && <div className="invalid-feedback">{props.meta.error}</div>}
          </div>
          {this.renderNote(props.note, props.noteId)}
        </div>
      </div>
    );
  }

  renderWrappedCheckBox(props) {
    return (
      <div className={props.className}>
        <div className="col-12 offset-sm-4 col-sm-8">
          <div className="SchemaField__CheckBox">
            <label className="form-check-label">
              {this.renderInputType(props)}
              <span className="ml-2">{this.getLabelTitle(props)}</span>
            </label>
          </div>
          {this.renderNote(props.note, props.noteId)}
          {props.hasError && (
            <div
              className="invalid-feedback"
              dangerouslySetInnerHTML={{
                __html: props.meta.error
              }}
            />
          )}
        </div>
      </div>
    );
  }

  renderSelect({
    input,
    meta,
    options,
    /* eslint-disable no-unused-vars */
    className,
    unsafeLabel,
    label,
    inputType,
    labelRenderer,
    optionLabelRenderer,
    showError,
    fieldError,
    wrappedLabel,
    itemContext,
    t, // TODO some other way
    /* eslint-enable no-unused-vars */
    ...rest
  }) {
    const schema = rest.schema || this.context.schema;

    const id = this.getElementId();
    const note = schema.get("note", null) || this.props.note;

    // default
    const hasError = meta.touched && meta.error && this.props.showError;

    const autoComplete = this.props.autoComplete || schema.get("autocomplete");

    const classNames = ["form-control"];
    if (hasError) {
      classNames.push("is-invalid");
    }
    const s = addOptionsToSchema(schema, options);
    return (
      <Select
        id={id}
        field={{ input, meta, schema: s }}
        className={classNames.join(" ")}
        aria-describedby={note ? `${id}-note` : null}
        autoComplete={autoComplete}
      />
    );
  }

  renderLabel(props) {
    if (props.label === null || props.label === false) {
      return null;
    }
    const note = null; // schema.get('note', null) || this.props.note;

    const title = this.getLabelTitle(props);

    if (!title && !note) {
      return null;
    }

    const schema = props.schema || this.context.schema;
    const isCheckBox = (props.type || this.getInputType(schema)) === "checkbox";

    const classNames = [];
    if (!isCheckBox) {
      classNames.push("col-12 col-sm-4");
    } else {
      classNames.push("form-check-label");
    }
    const id = this.getElementId();
    if (!note) {
      return (
        <label htmlFor={id} className={classNames.join(" ")}>
          {title}
          {`${schema.get("required") ? " *" : ""}`}
          {props.tooltip && <i data-tip={props.tooltip} className="ml-2 fa fa-info-circle" />}
        </label>
      );
    }
    return (
      <label htmlFor={id} className={classNames.join(" ")}>
        {title}
        {`${schema.get("required") ? " *" : ""}`}
        <br />
        <small className="form-text text-muted">{`${note}`}</small>
      </label>
    );
  }

  renderInput({ input, meta, ...rest }) {
    const schema = rest.schema || this.context.schema;

    const id = this.getElementId();
    const inputType = this.getInputType(schema);

    // default
    const hasError = (meta.touched && meta.error && this.props.showError) || this.props.fieldError;

    const classNames = [];
    if (inputType !== "checkbox" && rest.type !== "checkbox") {
      classNames.push("form-control");
    }
    if (inputType === "checkbox" || rest.type === "checkbox") {
      classNames.push("form-check-input");
    }
    if (hasError) {
      classNames.push("is-invalid");
    }

    if (inputType === "display") {
      classNames.push("form-control-display");
      return <div className={classNames.join(" ")}>{input.value}</div>;
    }

    // if ((schema.get("inputType") || this.props.inputType || rest.type || schema.get("renderHint")) === "File") {
    //   return <FileUpload {...rest} id={id} meta={meta} input={input} />;
    // }

    const note = schema.get("note", null) || rest.note;
    const noteId = `${id}-note`;

    const placeholder =
      this.props.placeholder === false
        ? null
        : this.props.placeholder ||
          schema.get("placeholder", schema.get("description", schema.get("title", "").toLowerCase()));

    const autoComplete =
      this.props.autoComplete === "off" ? "nope" : this.props.autoComplete || schema.get("autocomplete");

    return (schema.get("inputType") || this.props.inputType || rest.type || schema.get("renderHint")) === "textarea" ? (
      <textarea
        {...input}
        required={schema.get("required")}
        placeholder={placeholder}
        minLength={schema.get("minLength")}
        maxLength={schema.get("maxLength")}
        className={classNames.join(" ")}
        aria-describedby={note ? noteId : null}
        id={id}
        style={this.props.style}
        disabled={this.props.disabled}
        autoComplete={autoComplete}
        rows={this.props.rows || 5}
        onClick={this.props.onClick}
      />
    ) : (
      <input
        {...input}
        type={schema.get("format") === "email" ? "email" : inputType}
        required={schema.get("required")}
        aria-describedby={note ? noteId : null}
        minLength={schema.get("minLength")}
        maxLength={schema.get("maxLength")}
        min={schema.get("minimum", this.props.min)}
        max={schema.get("maximum", this.props.max)}
        pattern={schema.get("pattern")}
        className={classNames.join(" ")}
        id={id}
        disabled={this.props.disabled}
        autoComplete={autoComplete}
        placeholder={placeholder}
        style={this.props.style}
        onClick={this.props.onClick}
      />
    );
  }

  renderNote = (note, noteId) =>
    note && <small id={noteId} className="form-text text-muted" dangerouslySetInnerHTML={{ __html: note }} />;

  renderInputType = props => {
    const schema = props.schema || this.context.schema;
    const inputType = schema.get("inputType") || this.props.inputType || this.props.type;

    // if (inputType === "recaptcha") {
    //   return <ReCaptcha {...props} />;
    // }
    if (schema.get("format") === "datetime-local") {
      return <Datetime {...props} />;
    }
    if (schema.get("format") === "date") {
      return <Datetime {...props} isDateOnly />;
    }
    if (inputType === "radio") {
      return this.renderRadioGroup(props);
    }
    if (schema.get("type") === "boolean") {
      return this.renderInput(props);
    }

    if (schema.get("enum")) {
      return this.renderSelect(props);
    }
    return this.renderInput(props);
  };

  renderSchemaInput = props => {
    const schema = props.schema || this.context.schema;
    const hasError =
      (props.showError && props.meta.touched && props.meta.error) || (props.showError && props.fieldError);
    if (this.getInputType(schema) === "hidden") {
      return <input {...props.input} type="hidden" />;
    }

    const id = this.getElementId();
    const note = schema.get("note", null) || props.note;
    const noteId = `${id}-note`;

    const hasLabel = props.label !== false;

    const className = this.getContainerClassName([
      props.className,
      "form-group",
      "row",
      hasError ? "has-danger" : null
    ]);

    const isCheckBox = (props.type || this.getInputType(schema)) === "checkbox";

    if (isCheckBox) {
      return this.renderCheckBox({
        ...props,
        className,
        hasError,
        id,
        note,
        noteId,
        type: "checkbox"
      });
    }

    return (
      <div className={className}>
        {this.renderLabel(props)}
        <div className={`col-12 ${hasLabel ? "col-sm-8" : ""}`}>
          {props.inputGroupRight ? (
            <div className={classNames("input-group", hasError && "is-invalid")}>
              {this.renderInputType(props)}
              <div className="input-group-append">
                <div className="input-group-text">{props.inputGroupRight}</div>
              </div>
            </div>
          ) : (
            this.renderInputType(props)
          )}
          {hasError && (
            <div
              className="invalid-feedback"
              dangerouslySetInnerHTML={{
                __html: props.meta.error || props.fieldError
              }}
            />
          )}
          {this.renderNote(note, noteId)}
        </div>
      </div>
    );
  };

  render() {
    const schema = this.props.schema || this.context.schema;

    if (!schema) {
      if (__DEV__) {
        // invariant.
        // eslint-disable-next-line no-console
        console.warn("No schema set, defaulting to standard Field component");
      }
      const {
        /* eslint-disable no-unused-vars */
        labelRenderer,
        optionLabelRenderer,
        inputType,
        label,
        unsafeLabel,
        wrappedLabel,
        showError,
        fieldError,
        /* eslint-enable no-unused-vars */
        ...props
      } = this.props;
      return <Field component="input" {...props} />;
    }

    const fieldName = this.props.name || this.props.path || "";
    const path = `properties.${fieldName.split(".").join(".properties.")}`.split(".").reduce((c, p) => {
      if (/\[\d+]/.test(p)) {
        c.push(p.replace(/\[\d+]/, ""));
        c.push("items");
      } else {
        c.push(p);
      }
      return c;
    }, []);
    let propertySchema = schema.getIn(path);
    if (!propertySchema) {
      propertySchema = new Immutable.Map();
      // return null;
    }
    const property = path.slice().pop();

    if (propertySchema.get("type") === "array") {
      propertySchema = propertySchema
        .get("items")
        .set("title", propertySchema.get("title"))
        .set("note", propertySchema.get("note"))
        .set("multiple", true);
    }

    const parentPath = path.slice(0, path.length - 2); // back to parent
    const parentSchema = schema.getIn(parentPath);
    if (parentSchema) {
      const parentSchemaRequired = parentSchema.get("required");
      if (parentSchemaRequired && parentSchemaRequired.contains(property)) {
        propertySchema = propertySchema.set("required", true);
      }
    }

    const type = propertySchema.get("type", "string");
    const format = propertySchema.get("format");

    let normalize = null;
    let parse = null;
    if (type === "boolean") {
      normalize = this.normalizeBooleanValue;
    }
    if (type === "integer") {
      normalize = this.normalizeIntegerValue;
    }
    if (type === "number") {
      normalize = this.normalizeNumberValue;
      parse = parseInt;
    }
    if (format === "uri") {
      normalize = this.normalizeUriValue;
    }

    return (
      <Field
        {...this.props}
        type={this.props.type || this.getInputType(propertySchema) === "checkbox" ? "checkbox" : null}
        name={fieldName}
        schema={propertySchema}
        normalize={this.props.normalize || normalize}
        parse={this.props.parse || parse}
        component={this.renderSchemaInput}
      />
    );
  }
}

export default SchemaField;

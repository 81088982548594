import request from "./Request";
import { store } from "store/createStore";
import qs from "qs";

/**
 * Return an api url for the given resource
 *
 * i.e. resource "test" gives API_URI/test
 *
 * This requires global variable API_URI being set!
 *
 * @param resource
 *
 * @returns {string}
 */
export const getApiUri = resource => API_URI + resource;

export const handleError = e => {
  // eslint-disable-next-line no-console
  console.log(e);
  if (e.status === 401 || e.status === 403 || (e.body && e.body.code === 401)) {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    window.document.location.reload();
  }
  if (e.status && e.message) {
    const err = new Error(e.message);
    err.name = "APIError";
    err.message = e.body && e.body.message ? e.body.message : e.message;
    err.status = e.status;
    err.body = e.body;
    err.type = e.body && e.body.message;
    err.errors = e.body && e.body.errors;
    throw err;
  }
  throw e;
};

export const handleAuthError = e => {
  // TODO do something with error
  // TODO is to cactch auth errors and dispatch an action then rethrow the error;
  throw e;
};

export const handleRequest = req =>
  req
    .then(res => res.body)
    .catch(handleError)
    .catch(handleAuthError);

const running = {};

export const Api = (resource, single) => ({
  findById: (id, params) => handleRequest(request.get(getApiUri(`${resource}/${id}`)).query(params)),
  find: params => {
    if (single && running[resource] && running[resource].cancel) {
      running[resource].cancel();
    }
    running[resource] = request
      .get(getApiUri(resource))
      .query(qs.stringify(params))
      .promise();
    return handleRequest(running[resource]);
  },
  create: data => {
    const req = request.post(getApiUri(resource));
    if (data && data.file instanceof File) {
      req.field("name", data.name);
      req.attach("file", data.file);
      return handleRequest(req);
    }
    return handleRequest(req.send(data));
  },
  update: (id, data) => handleRequest(request.put(getApiUri(resource + (id ? `/${id}` : ""))).send(data)),
  destroy: id => handleRequest(request.del(getApiUri(`${resource}/${id}`)))
});

export const actionGenerator = (api, pending, success, failure) => {
  store.dispatch(typeof pending === "function" ? pending() : { type: pending });
  api
    .then(data => {
      store.dispatch(typeof success === "function" ? success(data) : { type: success, payload: data });
    })
    .catch(e => {
      store.dispatch(typeof failure === "function" ? failure(e) : { type: failure, payload: e });
    });
};

export default Api;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ucFirst from "locutus/php/strings/ucfirst";

import { fetchDeliveryExpiredTags } from "../Order/modules/Order";
import ModuleView from "../../components/ModuleView";
import OrderAction from "../Order/components/OrderAction";

const DeliveryAddress = ({ className }) => (
  <div className={className}>
    <h3>Bezorgadres</h3>
    <div />
  </div>
);
DeliveryAddress.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (/*state, props*/) => ({
  isZoomed: Math.round(window.devicePixelRatio * 100) !== 100
});

const mapActionCreators = {
  fetchDeliveryExpiredTags
};

export class RegisterArbitraryShipmentContainer extends Component {
  static propTypes = {
    fetchDeliveryExpiredTags: PropTypes.func.isRequired,
    isZoomed: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      transporter: PropTypes.string
    })
  };

  componentDidMount() {
    this.props.fetchDeliveryExpiredTags("");
  }

  render() {
    const { isZoomed } = this.props;
    return (
      <ModuleView>
        <div className="layout-row fix-width">
          <div className={`layout-fill layout-column flex big-column ${isZoomed ? "" : "big-column--sticky"}`}>
            <OrderAction action={`sendTo${ucFirst(this.props.params.transporter)}`} />
          </div>
          <div className="order-detail small-column" style={{ minWidth: 500 }} />
        </div>
      </ModuleView>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapActionCreators
  )(RegisterArbitraryShipmentContainer)
);

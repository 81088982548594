import Promise from "bluebird";

import Immutable, { ListAndOrderedMapReviver } from "../../lib/Immutable";
import api from "../../lib/Api";
import { getParams } from "lib/RouteUtil";
import { navigateByKeyCode, fetchTags, find as fetchOrders, DATA_RECEIVE_ONE } from "../../routes/Order/modules/Order";
import WebSocketClient from "../../lib/WebSocketClient";

// Actions

const INITIALIZE = "@app/INITIALIZE";
export const MODAL_OVERLAY = "@app/MODAL_OVERLAY";
export const TOGGLE_MODAL = "@app/TOGGLE_MODAL";
const FETCH_L10N = "@app/FETCH_L10N";
const RECEIVE_L10N = "@app/RECEIVE_L10N";
const FETCH_ERROR_L10N = "@app/FETCH_ERROR_L10N";
const SET_TITLE = "@app/SET_TITLE";
// const RESET_TITLE = '@app/RESET_TITLE';
const TOGGLE_SIDEBAR = "@app/TOGGLE_SIDEBAR";
const SCROLL_TO_TOP = "@app/SCROLL_TO_TOP";
const RESET_SCROLL_TO_TOP = "@app/RESET_SCROLL_TO_TOP";

export const LOAD_CONFIG = "@app/config/LOAD";
export const PERSIST_CONFIG = "@app/config/PERSIST";
// export const PERSIST_CONFIG_ERROR = "@app/config/PERSIST_ERROR";

export const FETCH_PRINTERS = "@app/printers/FETCH";
export const FETCH_PRINTERS_RECEIVE = "@app/printers/FETCH_RECEIVE";
export const FETCH_PRINTERS_ERROR = "@app/printers/FETCH_ERROR";

export const INIT_DATA_UPDATE_SOCKET = "@app/socket/INIT_DATA_UPDATE";

export const LOAD_ENV = "@app/LOAD_ENV";

export const loadEnv = () => dispatch => {
  api("/env")
    .find()
    .then(payload => {
      dispatch({ type: LOAD_ENV, payload });
    });
};

export const initialize = () => (dispatch, getState) => {
  // load stuff
  const promises = [
    dispatch(loadEnv()),
    dispatch(loadConfig()),
    dispatch(fetchLocale()),
    dispatch(fetchPrinters()),
    dispatch(fetchSchemas()),
    dispatch(hydrateFeatures())
  ];

  if (!window.hasEventListeners) {
    document.addEventListener("keydown", event => {
      // Only continue if this is not a form element
      if (
        event.target.nodeName === "TEXTAREA" ||
        event.target.nodeName === "INPUT" ||
        event.target.nodeName === "SELECT"
      ) {
        return;
      }
      // Only continue if the key is either arrow down (40) or arrow up (38)
      if (event.keyCode !== 38 && event.keyCode !== 40) {
        return;
      }
      event.preventDefault();
      navigateByKeyCode(event.keyCode)(dispatch, getState);
    });

    window.hasEventListeners = true;
  }

  return dispatch({
    type: INITIALIZE,
    payload: Promise.all(promises).then(() => {
      // initialize socket(s)
      if (!window.DataUpdateSocket) {
        const WSS_UPDATE_URI = getState().app.getIn(["env", "data", "WSS_UPDATE_URI"]);

        dispatch({ type: INIT_DATA_UPDATE_SOCKET, payload: WSS_UPDATE_URI });

        window.DataUpdateSocket = new WebSocketClient(WSS_UPDATE_URI);
        window.DataUpdateSocket.onmessage = msg => {
          const state = getState();
          const activeOrderId = state.order.getIn(["order", "data", "id"], 0);
          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.trace("DataUpdateSocket", msg);
          }
          const data = JSON.parse(msg.data);
          const isFingerPrinted = data.fingerprint;
          const payload = isFingerPrinted ? data.payload : data;
          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.trace("payload", payload);
          }

          if (payload.type === "SERVER_RESTART") {
            dispatch({
              type: MODAL_OVERLAY,
              payload: "Applicatie is bijgewerkt, de applicatie word opnieuw ingeladen!"
            });
            setTimeout(() => {
              window.document.location.reload();
            }, 600);
            return;
          }

          if (payload.type === "feature") {
            dispatch(hydrateFeatures());
            return;
          }

          if (payload.type === "listitem") {
            const params = getParams();

            fetchTags(params.query, true)(dispatch, getState);

            fetchOrders(Immutable.fromJS(params.tags), params.page, params.query, true)(dispatch, getState);
            // dispatch({ type: DATA_REPLACE_OR_INSERT, payload: payload.data });
          }

          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log(payload.type, activeOrderId, payload.data.id);
          }
          if (payload.type === "activeitem" && activeOrderId === payload.data.id) {
            dispatch({ type: DATA_RECEIVE_ONE, payload: payload.data });
          }
        };
      }
    })
  });
};

export const loadConfig = () => dispatch => {
  const payload = JSON.parse(localStorage.getItem("config"));
  if (!payload) {
    return;
  }
  if (payload.printer) {
    payload.printers = payload.printer;
    delete payload.printer;
  }
  // persist location configuration from localstorage to store
  dispatch({ type: LOAD_CONFIG, payload: payload });
};

export const persistConfig = data => {
  localStorage.setItem("config", JSON.stringify(data));
  return { type: PERSIST_CONFIG, payload: Promise.resolve(data) };
};

export const fetchLocale = () => dispatch => {
  dispatch({ type: FETCH_L10N });
  api("/workbench/locale")
    .find()
    .then(data => {
      dispatch({ type: RECEIVE_L10N, payload: data });
    })
    .catch(err => {
      dispatch({ type: FETCH_ERROR_L10N, payload: err });
    });
};

export const HYDRATE_FEATURES = "@app/HYDRATE_FEATURES";
export const hydrateFeatures = () => ({
  type: HYDRATE_FEATURES,
  payload: api("/workbench/feature").find()
});

export const fetchPrinters = () => dispatch => {
  dispatch({ type: FETCH_PRINTERS });
  return api("/workbench/printer")
    .find()
    .then(data => {
      dispatch({ type: FETCH_PRINTERS_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: FETCH_PRINTERS_ERROR, payload: err });
      throw err;
    });
};

export const scrollToTop = () => ({ type: SCROLL_TO_TOP });
export const resetScrollTop = () => ({ type: RESET_SCROLL_TO_TOP });

export const toggleModal = modalName => (dispatch, getState) =>
  dispatch({
    type: TOGGLE_MODAL,
    payload: {
      name: modalName,
      value: !getState().app.getIn(["modals", modalName])
    }
  });

export const toggleSidebarExpanded = expanded => dispatch => {
  localStorage.setItem("sidebarExpanded", expanded);
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: expanded
  });
};

export const setTitle = title => dispatch => {
  dispatch({
    type: SET_TITLE,
    payload: title
  });
};

const FETCH_SCHEMA = "@app/schema/FETCH";
const FETCH_SCHEMA_RECEIVE = "@app/schema/RECEIVE";
const FETCH_SCHEMA_ERROR = "@app/schema/ERROR";
export const fetchSchema = id => dispatch => {
  dispatch({ type: FETCH_SCHEMA, payload: id });
  return api("/workbench/schema")
    .findById(id)
    .then(data => {
      dispatch({ type: FETCH_SCHEMA_RECEIVE, payload: { id, data } });
    })
    .catch(err => {
      dispatch({ type: FETCH_SCHEMA_ERROR, payload: err });
      throw err;
    });
};

const FETCH_SCHEMAS = "@app/schemas/FETCH";
export const fetchSchemas = () => (dispatch, getState) => {
  dispatch({ type: FETCH_SCHEMAS });
  fetchSchema("DeliveryAddress")(dispatch, getState);
};

export const WINDOW_SCROLL = "@app/WINDOW_SCROLL";
export const onWindowScroll = ({ scrollY, scrollX }) => ({
  type: WINDOW_SCROLL,
  payload: {
    scrollY,
    scrollX
  }
});

export const WINDOW_RESIZE = "@app/WINDOW_RESIZE";
export const onWindowResize = () => ({
  type: WINDOW_RESIZE,
  payload: {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  }
});

export const TOGGLE_FEATURE = "@app/TOGGLE_FEATURE";

export const toggleFeature = featureId => (dispatch, getState) => {
  const feature = getState()
    .app.get("features")
    .find(f => f.get("id") === featureId);
  if (!feature) {
    return;
  }
  return dispatch({
    type: TOGGLE_FEATURE,
    payload: api(`/workbench/feature`).update(featureId, {
      extraAttribute_28088961: !feature.get("extraAttribute_28088961")
    })
  });
};

// Initial State
export const State = Immutable.Record({
  isInitializing: false,
  hasInitialized: false,
  initializeError: null,
  MODAL_OVERLAY: "",
  env: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.Map({})
  }),
  titleLong: "BoeketCadeau Werkplaats",
  titleShort: "Werkplaats",
  titleTransient: null,
  sidebar: Immutable.Map({
    expanded:
      localStorage.getItem("sidebarExpanded") !== null ? localStorage.getItem("sidebarExpanded") === "true" : true
  }),
  locale: Immutable.Map(),
  schemas: Immutable.Map(),
  features: Immutable.List(),
  isFetchingFeatures: false,
  featuresError: null,
  scrollY: 0,
  scrollX: 0,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  scrollToTop: false,
  scrollToBottom: false,
  config: Immutable.Map({
    printers: Immutable.Map({})
  }),
  printers: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.Map({})
  }),
  modals: Immutable.Map()
});

// Action handlers
export const actionHandlers = {
  [INITIALIZE](state, { status, payload }) {
    switch (status) {
      case "pending":
        return state.set("isInitializing", true);
      case "success":
        return state
          .set("isInitializing", false)
          .set("hasInitialized", true)
          .set("initializeError", null);
      case "error":
        return state
          .set("isInitializing", false)
          .set("hasInitialized", false)
          .set("initializeError", payload);
      default:
        return state;
    }
  },
  [TOGGLE_FEATURE]: (state, { status, payload }) => {
    if (status !== "success") {
      return state;
    }
    const index = state.get("features").findIndex(f => f.get("id") === payload.id);
    return state.setIn(["features", index], Immutable.fromJS(payload));
  },
  [HYDRATE_FEATURES]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isFetchingFeatures", true);
      case "success":
        return state
          .set("isFetchingFeatures", false)
          .set("featuresError", null)
          .set("features", Immutable.fromJS(payload));
      case "error":
        return state.set("isFetchingFeatures", false).set("featuresError", payload);
      default:
        return state;
    }
  },
  [TOGGLE_MODAL]: (state, { payload }) => state.setIn(["modals", payload.name], payload.value),
  [WINDOW_SCROLL]: (state, { payload }) => state.set("scrollY", payload.scrollY).set("scrollX", payload.scrollX),

  [WINDOW_RESIZE]: (state, { payload }) =>
    state.set("windowWidth", payload.windowWidth).set("windowHeight", payload.windowHeight),

  [MODAL_OVERLAY](state, { payload }) {
    return state.set("MODAL_OVERLAY", payload);
  },
  [SET_TITLE](state, { payload }) {
    return state.set("titleTransient", payload);
  },
  [TOGGLE_SIDEBAR](state, { payload }) {
    return state.setIn(["sidebar", "expanded"], payload);
  },
  [LOAD_ENV](state, { payload }) {
    return state.setIn(["env", "data"], Immutable.fromJS(payload));
  },
  [RECEIVE_L10N](state, { payload }) {
    return state.set("locale", Immutable.fromJS(payload));
  },
  [SCROLL_TO_TOP](state) {
    return state.set("scrollToTop", true).set("scrollY", 0);
  },
  [RESET_SCROLL_TO_TOP](state) {
    return state.set("scrollToTop", false);
  },

  [FETCH_SCHEMA_RECEIVE]: (state, { payload }) =>
    state.setIn(["schemas", payload.id], Immutable.fromJS(payload.data, ListAndOrderedMapReviver)),

  [LOAD_CONFIG]: (state, { payload }) => state.setIn(["config"], Immutable.fromJS(payload)),

  [PERSIST_CONFIG]: (state, { status, payload }) => {
    if (status === "success") {
      return state.setIn(["config"], Immutable.fromJS(payload));
    }
    return state;
  },

  [FETCH_PRINTERS]: state => state.setIn(["printers", "isFetching"], true),

  [FETCH_PRINTERS_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["printers", "isFetching"], false)
      .setIn(["printers", "error"], "")
      .setIn(["printers", "data"], Immutable.fromJS(payload, ListAndOrderedMapReviver)),

  [FETCH_PRINTERS_ERROR]: (state, { payload }) =>
    state.setIn(["printers", "isFetching"], false).setIn(["printers", "error"], payload)
};

// Reducer
export function reducer(state = new State(), action) {
  const { type } = action;
  if (type in actionHandlers) {
    return actionHandlers[type](state, action);
  } else {
    return state;
  }
}

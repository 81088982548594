import i18next from "i18next";
import sprintf from "i18next-sprintf-postprocessor";
// https://github.com/i18next/react-i18next/blob/master/example/react-native-expo/js/i18n.js

import { initReactI18next } from "react-i18next";

const resources = require("./i18n.json");

// fix google translated from lang attribute
// https://meta.stackexchange.com/questions/62939/how-to-make-users-aware-that-theyre-reading-translated-content
// https://sentry.io/kingsquare/debloemist/issues/401807824/
const language = document
  .getElementsByTagName("html")[0]
  .getAttribute("lang")
  .replace(/.*x-mtfrom-(.*)/, "$1");

// creating a language detection plugin using expo
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
  type: "languageDetector",
  detect: () => language,
  init: () => {},
  cacheUserLanguage: () => {}
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .use(sprintf)
  .init({
    overloadTranslationOptionHandler: sprintf.overloadTranslationOptionHandler,
    fallbackLng: "en",
    lang: "nl",
    resources,
    // have a common namespace used around the full app
    ns: ["common"],
    defaultNS: "common",
    // disable nested keys via keySeperator
    keySeparator: false,
    debug: __DEV__, // ,
    interpolation: {
      escapeValue: false // not needed for react as it does escape per default to prevent xss!
    },

    // react i18next special options (optional)
    react: {
      wait: false, // set to true if you like to wait for loaded in every translated hoc
      nsMode: "fallback" // set it to fallback to let passed namespaces to translated hoc act as fallbacks
    }
  });

if (module.hot) {
  module.hot.accept();
  // eslint-disable-next-line global-require
  // module.hot.accept("./i18n.json", args => {
  //   console.log("./i18n.json");
  //   console.log("args", args);
  //   resources = require("./i18n.json");
  //   console.log("resources.en.common.as", resources.en.common.as);
  //   i18next.addResourceBundle("", "", resources, true, true);
  //   i18next.reloadResources();
  //   i18next.emit("loaded");
  // });
}

export default i18next;

import React from "react";
import PropTypes from "prop-types";
import ReactDateTime from "react-datetime";
import { fieldPropTypes } from "redux-form";

import moment from "../../lib/moment";

class DatePicker extends React.Component {
  static propTypes = {
    ...fieldPropTypes,
    isDateOnly: PropTypes.bool
  };

  static defaultProps = {
    isDateOnly: false,
    displayFormat: "YYYY-MM-DD HH:mm",
    inputFormat: "YYYY-MM-DD HH:mm"
  };

  onChange = value => {
    const { input, inputFormat, isDateOnly } = this.props;
    input.onBlur(
      moment.isMoment(value) ? value.format(inputFormat.replace(isDateOnly ? "HH:mm" : "", "").trim()) : value
    );
  };

  onClear = () => {
    this.onChange("");
  };

  isValidDate = current => {
    const { deliveryDate } = this.props;
    const startOfDay = moment(deliveryDate).startOf("day");
    return current.isSame(startOfDay) || current.isAfter(startOfDay);
  };

  renderInput = (props, openCalendar) => {
    const { input, meta, placeholder } = this.props;
    let { value } = input;

    if (value !== moment(value).format(this.getInputFormat())) {
      value = null;
    }

    const classNames = ["SchemaField__DatePicker__Input form-control-display"];
    if (meta.error) {
      classNames.push("has-danger");
    }

    return (
      <div className={classNames.join(" ")} onClick={openCalendar} role="button" tabIndex="-1" onKeyDown={openCalendar}>
        <i className="fa fa-calendar mr-1" />
        <div className="SchemaField__DatePicker__Value">
          {(value && (moment.isMoment(value) ? value : moment(value)).format(this.getDisplayFormat())) || (
            <span>{placeholder || "selecteer"}</span>
          )}
        </div>
        <div className="SchemaField__DatePicker__Actions">
          {value && (
            <i className="fa fa-times" onClick={this.onClear} role="button" tabIndex="-1" onKeyDown={this.onClear} />
          )}
        </div>
        <input {...input} type="hidden" />
      </div>
    );
  };

  getDisplayFormat() {
    return this.getFormat(this.props.displayFormat);
  }

  getInputFormat() {
    return this.getFormat(this.props.inputFormat);
  }

  getFormat(format) {
    return format.replace(this.props.isDateOnly ? "HH:mm" : "", "").trim();
  }

  render() {
    const { input, placeholder, deliveryDate, isDateOnly } = this.props;
    const now = ReactDateTime.moment();

    let { value } = input;
    if (value !== moment(value).format(this.getInputFormat())) {
      value = null;
    }

    return (
      <div className="SchemaField__DatePicker">
        <ReactDateTime
          timeFormat={!isDateOnly}
          closeOnSelect
          viewMode="days"
          locale={moment.locale()}
          isValidDate={this.isValidDate}
          inputProps={{ placeholder }}
          value={
            value && !moment.isMoment(value)
              ? moment(value)
              : moment(deliveryDate || null)
                  .set("hours", now.hours())
                  .set("minutes", now.minutes())
          }
          onChange={this.onChange}
          onFocus={this.onFocus}
          renderInput={this.renderInput}
        />
      </div>
    );
  }
}

export default DatePicker;

import PropTypes from "prop-types";
import React from "react";

export default class DefaultButton extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  render() {
    return (
      <button className="btn btn-default" {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

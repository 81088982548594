import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Immutable from "immutable";
import { toastr } from "react-redux-toastr";

import moment from "~/lib/moment";

import PhotoCameraContainer from "./PhotoCameraContainer";
import { addAttachment, deleteAttachment } from "~/routes/Order/modules/Order";
import GalleryThumbnail from "~/routes/Order/components/OrderActions/PhotoGallery/GalleryThumbnail";

import "./index.scss";
import SendMailButton from "~/routes/Order/components/OrderDetail/SendMailButton";

class PhotoGalleryContainer extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    hasFetched: PropTypes.bool,
    isUploadingPhoto: PropTypes.bool,
    orderId: PropTypes.number,
    attachments: PropTypes.instanceOf(Immutable.List),
    addAttachment: PropTypes.func,
    deleteAttachment: PropTypes.func,
    fetchOne: PropTypes.func,
    hasAlreadySentMail: PropTypes.bool
  };

  static defaultProps = {
    hasAlreadySentMail: false
  };

  state = {};

  onAcceptPhoto = data =>
    this.props
      .addAttachment(this.props.orderId, {
        name: `WBP_${moment().format("YYYYMMDDHHIISS")}.jpg`,
        file: data
      })
      .catch(err => {
        toastr.error("Kan bijlage niet toevoegen!", err.message);
      });

  onDeletePhoto = attachmentId =>
    this.props.deleteAttachment(this.props.orderId, attachmentId).catch(err => {
      toastr.error("Fout bij verwijderen!", err.message);
    });

  render() {
    if (this.props.isFetching || !this.props.hasFetched) {
      return null;
    }

    const { hasAlreadySentMail } = this.props;

    const attachments = this.props.attachments.filter(a => a.get("title").startsWith("WBP_"));
    return (
      <div className="OrderPhotoGallery">
        {!hasAlreadySentMail ? <h2>Foto&apos;s toevoegen</h2> : <h2>Verstuurde foto&apos;s</h2>}
        {!hasAlreadySentMail && (
          <PhotoCameraContainer onAcceptPhoto={this.onAcceptPhoto} isAcceptingPhoto={this.props.isUploadingPhoto} />
        )}
        <div className="OrderPhotoGallery__Images">
          {attachments.map(attachment => (
            <GalleryThumbnail
              key={attachment.get("id")}
              orderId={this.props.orderId}
              canDelete={!hasAlreadySentMail}
              onDelete={this.onDeletePhoto}
              attachment={attachment}
            />
          ))}
        </div>
        <div className="mt-2">
          <br />
          {attachments.size === 0 ? (
            <div className="alert alert-info">Er zijn nog geen fotos gemaakt</div>
          ) : (
            <SendMailButton mailType="ExtraServicePhoto" showStatusOnly={hasAlreadySentMail}>
              Mail versturen
            </SendMailButton>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    hasAlreadySentMail: !!state.order.getIn(["order", "data", "workbenchMeta", "mailJobs", "ExtraServicePhoto"], false),
    isUploadingPhoto: state.order.getIn(["order", "isUploadingPhoto"], false),
    isFetching: state.order.getIn(["order", "isFetching"], false),
    hasFetched: state.order.getIn(["order", "hasFetched"], false),
    attachments: state.order.getIn(["order", "data", "attachments"], Immutable.List())
  }),
  {
    addAttachment,
    deleteAttachment
  }
)(PhotoGalleryContainer);

import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";

import ProgressButtonWithStatus from "../../../../components/Button/ProgressButtonWithStatus";
import moment from "../../../../lib/moment";
import { sendMail, setEditing } from "../../../../routes/Order/modules/Order";

const unixMoment = timestamp => moment(timestamp * 1000);

const formatUnixTimestamp = timestamp => {
  if (!timestamp) {
    return null;
  }
  return unixMoment(timestamp).format("D MMMM YYYY HH:mm");
};

class SendMailButton extends Component {
  static propTypes = {
    orderId: PropTypes.number.isRequired,
    sendMail: PropTypes.func.isRequired,
    setEditing: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    mailType: PropTypes.string.isRequired,
    canSubmit: PropTypes.bool,
    inProgress: PropTypes.bool,
    showStatusOnly: PropTypes.bool,
    sent: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string
  };

  static defaultProps = {
    canSubmit: false,
    inProgress: false,
    showStatusOnly: false,
    sent: null,
    children: null,
    className: null
  };

  onActivate = e => {
    this.props.setEditing(`canSubmit${this.props.mailType}`, e.target.checked);
  };

  onSubmit = e => {
    if (e.target.nodeName === "INPUT") {
      this.props.setEditing(`canSubmit${this.props.mailType}`, false);
      return;
    }
    this.props
      .sendMail(this.props.orderId, this.props.mailType)
      .then(() => {
        toastr.success(`${this.props.t(`mail_${this.props.mailType}`)} mail is verstuurd`);
        this.props.setEditing(`canSubmit${this.props.mailType}`, false);
      })
      .catch(err => {
        this.props.setEditing(`canSubmit${this.props.mailType}`, false);
        toastr.error("Kan mail niet versturen!", err.message);
      });
  };

  render() {
    return (
      <ProgressButtonWithStatus
        disabled={!this.props.canSubmit}
        className={this.props.className}
        fullWidth
        inProgress={this.props.inProgress}
        onClick={this.onSubmit}
        showStatusOnly={!this.props.showStatusOnly}
        status={formatUnixTimestamp(this.props.sent)}
      >
        {!this.props.inProgress && (
          <input type="checkbox" onChange={this.onActivate} checked={this.props.canSubmit} value={1} />
        )}
        {this.props.children}
      </ProgressButtonWithStatus>
    );
  }
}

const EMPTY_LIST = Immutable.List();
const EMPTY_MAP = Immutable.Map();

export default withTranslation()(
  connect(
    (state, props) => {
      const mailStatus = state.order.getIn(["order", "mail"], EMPTY_MAP);
      const mailJobs = state.order.getIn(["order", "data", "workbenchMeta", "mailJobs"], EMPTY_MAP);
      return {
        orderId: state.order.getIn(["order", "data", "id"], 0),
        canSubmit: state.order.getIn(["order", "editing", `canSubmit${props.mailType}`], false),
        inProgress: mailStatus.getIn([props.mailType, "inProgress"], false),
        error: mailStatus.getIn([props.mailType, "error"], false),
        sent: mailJobs.get(props.mailType, EMPTY_LIST).last() || 0
      };
    },
    {
      sendMail,
      setEditing
    }
  )(SendMailButton)
);

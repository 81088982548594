import React from "react";
import PropTypes from "prop-types";
// import { Link } from 'react-router';
import classes from "./FeatureBar.module.scss";

/* FEATURE BAR RIGHT ICONS
 <Link to="/admin">
 <div className="material-icons">settings</div>
 </Link>
 */
export const FeatureBar = ({ title }) => (
  <div className={classes["fb-featurebar"]}>
    <div className={classes["fb-featurebar-main"]}>
      <div className={classes["fb-featurebar-title-area"]}>
        <div className={classes["fb-featurebar-title"]}>{title}</div>
      </div>

      <div className="fb-featurebar-actions-area">
        <div className="fb-featurebar-action-buttons" />
      </div>
    </div>
  </div>
);

FeatureBar.propTypes = {
  // children: React.PropTypes.element.isRequired,
  title: PropTypes.string.isRequired
};

export default FeatureBar;

import PropTypes from "prop-types";
import React from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";

import LogoutView from "../components/LogoutView";
import { requestLogout } from "../modules/Auth";

export class LogoutContainer extends React.Component {
  componentDidMount() {
    this.props.requestLogout().then(() => {
      browserHistory.replace("/");
    });
  }

  render() {
    return <LogoutView {...this.props} />;
  }
}

LogoutContainer.propTypes = {
  requestLogout: PropTypes.func
};

export default connect(
  state => ({}),
  {
    requestLogout
  }
)(LogoutContainer);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ucFirst from "locutus/php/strings/ucfirst";
import { createSelector } from "reselect";
import { withTranslation } from "react-i18next";

import { toggleFeature } from "../../../../store/modules/App";
import ModuleView from "../../../../components/ModuleView/ModuleView";
import Paper from "../../../../components/Paper/Paper";

const alphaTitleSorter = (a, b) => {
  // if (a.extraAttribute_28090089 < b.extraAttribute_28090089) {
  //   return -1;
  // }
  // if (a.extraAttribute_28090089 > b.extraAttribute_28090089) {
  //   return 1;
  // }

  const aTitle = a.extraAttribute_28090100 || a.title;
  const bTitle = b.extraAttribute_28090100 || b.title;

  if (aTitle <= bTitle) {
    return -1;
  }
  if (aTitle > bTitle) {
    return 1;
  }
  return 0;
};

const FeatureType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  extraAttribute_28088960: PropTypes.string, // Type
  extraAttribute_28088961: PropTypes.bool, // Active
  extraAttribute_28090100: PropTypes.string, // Description
  extraAttribute_28090089: PropTypes.number, // Order
  // misc
  extraAttribute_28088962: PropTypes.bool // canRegister
});

const ArrayOfFeaturesType = PropTypes.arrayOf(FeatureType);

const GroupedFeature = PropTypes.shape({
  title: PropTypes.string.isRequired,
  features: ArrayOfFeaturesType
});

const GroupedFeaturesType = PropTypes.arrayOf(GroupedFeature);

class FeaturesContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    toggleFeature: PropTypes.func.isRequired,
    groupedFeatures: GroupedFeaturesType.isRequired
  };

  onToggleFeature = e => {
    this.props.toggleFeature(parseInt(e.target.dataset.id, 10));
  };

  renderFeature = feature => (
    <div key={`${feature.id}${feature.extraAttribute_28088961}`} className="form-group row">
      <div className="col-12">
        <label className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            data-id={feature.id}
            onChange={this.onToggleFeature.bind(feature.id)}
            defaultChecked={feature.extraAttribute_28088961}
            disabled={
              feature.extraAttribute_28088960 !== "transporter" ? false : feature.extraAttribute_28088962 === false
            }
          />
          <span className="custom-control-indicator" />
          <span className="custom-control-description">
            {ucFirst(feature.extraAttribute_28090100 || feature.title)}
          </span>
        </label>
      </div>
    </div>
  );

  render() {
    const { t, groupedFeatures } = this.props;
    return (
      <ModuleView title="Features">
        <Paper>
          {groupedFeatures.map(groupedFeature => (
            <div key={`groupedFeature${groupedFeature.title}`}>
              <h2>{t(`feature.group.${groupedFeature.title}`)}</h2>
              <div>{groupedFeature.features.map(this.renderFeature)}</div>
            </div>
          ))}
        </Paper>
      </ModuleView>
    );
  }
}

const selectFeatures = createSelector(
  [state => state.app.get("features")],
  features => features.toJS()
);

const selectGroupedFeatures = createSelector(
  [state => selectFeatures(state)],
  features => {
    const grouped = [];
    for (const feature of features) {
      let group = grouped.find(g => g.title === feature.extraAttribute_28088960);
      if (!group) {
        group = {
          title: feature.extraAttribute_28088960,
          features: []
        };
        grouped.push(group);
      }
      group.features.push(feature);
    }
    return grouped
      .map(g => {
        g.features.sort(alphaTitleSorter);
        return g;
      })
      .sort(alphaTitleSorter);
  }
);

export default withTranslation(["admin"])(
  connect(
    state => ({
      groupedFeatures: selectGroupedFeatures(state)
    }),
    {
      toggleFeature
    }
  )(FeaturesContainer)
);

import React from "react";
import LoginReset from "../components/LoginReset";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { requestReset } from "../modules/Auth";
// import { browserHistory } from 'react-router';

export class ResetContainer extends React.Component {
  render() {
    return <LoginReset {...this.props} />;
  }
}

export default connect(
  state => ({
    authError: state.auth.get("error"),
    isReset: state.auth.get("isReset"),
    title: state.app.get("titleLong")
  }),
  {
    requestReset
  }
)(
  reduxForm({
    form: "authReset",
    validate: data => {
      const errors = {};
      if (!data) {
        return errors;
      }
      if (!data.login) {
        errors.login = "Dit veld is vereist";
      }
      if (data.login && data.login.length < 4) {
        errors.login = "Uw gebruikersnaam is te kort";
      }
      return errors;
    }
  })(ResetContainer)
);

/**
 * Product ID for a timeframe product
 * Gehele dag - 8:00 - 18:00
 */
export const TIMEFRAME_ID_STANDARD = 14593019;

/**
 * Product ID for a timeframe product
 * Avond - 17:00 - 21:00
 */
export const TIMEFRAME_ID_EVENING = 14624708;

/**
 * Product IDS for timeframes
 */
export const TIMEFRAME_IDS = {
  STANDARD: TIMEFRAME_ID_STANDARD,
  EVENING: TIMEFRAME_ID_EVENING
};

/**
 * Product ID for a photo service product
 */
export const PRODUCT_ID_PHOTO_SERVICE = 29216444;

/**
 * Product ID for a invoice product
 */
export const PRODUCT_ID_INVOICE = 943234;

/**
 * Product ID for a discount product
 */
export const PRODUCT_ID_DISCOUNT = 17853163;

/**
 * Product ID for a shipping product
 */
export const SHIPPING_PRODUCT_ID = 943233;

/**
 * Product ID for a card product
 */
export const CARD_PRODUCT_ID = 938488;

/**
 * Card ID for a ribbon
 */
export const CARD_RIBBON_ID = 20012781;

/**
 */
export const VASE_PRODUCT_IDS = [
  19736722, // x-small
  936317, // small
  991436, // medium
  19736733, // semi-large
  991437, // large
  19736717 // x-large
];

export const PRODUCT_IDS = {
  INVOICE_ID: PRODUCT_ID_INVOICE,
  DISCOUNT_ID: PRODUCT_ID_DISCOUNT,
  SHIPPING_ID: SHIPPING_PRODUCT_ID,
  CARD_ID: CARD_PRODUCT_ID,
  VASE_IDS: VASE_PRODUCT_IDS,
  PHOTO_SERVICE_ID: PRODUCT_ID_PHOTO_SERVICE
};

export const PAYMENT_METHOD_ID_BUSINESS_ACCOUNT = 1098217;
export const PAYMENT_METHOD_ID_ON_ACCOUNT = 943243; // Op rekening
export const PAYMENT_METHOD_ID_BILLINK = 22563027; // Billink: Digitale acceptgiro
export const PAYMENT_METHOD_ID_AFTERPAY_GIRO = 20173031; // Digitale acceptgiro via AfterPay
export const PAYMENT_METHOD_ID_AFTERPAY = 20187184; // Billink: Digitale acceptgiro
export const PAYMENT_METHOD_ID_ON_ACCOUNT_INVOICED = 13002163; // Op rekening met factuur op papier
export const PAYMENT_METHOD_ID_ON_ACCOUNT_AFTERPAY = 13036060; // Op rekening, betaling achteraf
export const PAYMENT_METHOD_ID_ON_ACCOUNT_PIN_DELIVERY = 14678839; // Rembourslevering, betalen bij aflevering via pin

export const PAYMENT_METHOD_IDS_ON_ACCOUNT = [
  PAYMENT_METHOD_ID_ON_ACCOUNT,
  PAYMENT_METHOD_ID_BILLINK,
  PAYMENT_METHOD_ID_AFTERPAY_GIRO,
  PAYMENT_METHOD_ID_AFTERPAY,
  PAYMENT_METHOD_ID_ON_ACCOUNT_INVOICED,
  PAYMENT_METHOD_ID_ON_ACCOUNT_AFTERPAY,
  PAYMENT_METHOD_ID_ON_ACCOUNT_PIN_DELIVERY
];

export const PAYMENT_METHOD_IDS = {
  BUSINESS_ACCOUNT_ID: PAYMENT_METHOD_ID_BUSINESS_ACCOUNT,
  ON_ACCOUNT_ID: PAYMENT_METHOD_ID_ON_ACCOUNT,
  ON_ACCOUNT_IDS: PAYMENT_METHOD_IDS_ON_ACCOUNT
};

export default {
  PAYMENT_METHOD_IDS,
  PRODUCT_IDS,
  TIMEFRAME_IDS
};

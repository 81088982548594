import PropTypes from "prop-types";
import React from "react";
import isArray from "lodash/isArray";
import Select from "react-virtualized-select";
import Immutable from "immutable";

export const toSelectOptionsArray = list => {
  if (!list) {
    return [];
  }
  if (!Immutable.Map.isMap(list)) {
    return list;
  }
  return list
    .keySeq()
    .map(key => ({
      value: key,
      label: list.get(key)
    }))
    .toArray();
};

export const SelectWrapper = props => {
  const { options, meta, input, ...rest } = props;

  const allProps = {
    // don't call things "props" ;-)
    ...rest,
    ...input,
    ...props.l10n
  };

  // allProps.multi = !!field.multiple;
  allProps.options = toSelectOptionsArray(options);

  allProps.className = allProps.className || "";
  allProps.className += meta.touched ? ` ${meta.error ? "input-error" : "input-success"}` : "";

  allProps.value = input.value !== null && typeof input.value !== "undefined" ? input.value : null;

  allProps.onBlur = () => input.onBlur(input.value);

  allProps.clearable = false; // rest.required !== 'required';
  allProps.required = rest.required === "required";
  input.autocomplete = "nope"; // todo custom renderer with own "input" tag which does disable autocomplete...

  allProps.onChange = value => {
    if (isArray(value)) {
      value = value.map(item => item.value);
    }
    if (value && (value.value || value.value === "")) {
      value = value.value;
    }
    if (rest.onChange) {
      // react-select
      rest.onChange(value);
    }
    input.onChange(value); // redux-form Field
  };

  return <Select {...allProps} />;
};

SelectWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.any, // Immutable.Map or a Immutable.List
  l10n: PropTypes.object
};

export default SelectWrapper;

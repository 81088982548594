import PrintModalContainer from "./containers/PrintModalContainer";

export default (/*store*/) => ({
  path: "print",
  component: PrintModalContainer,
  onEnter: () => {},
  childRoutes: [
    {
      path: ":id/:jobType/:printerId/:shipmentId",
      onEnter: () => {}
    },
    {
      path: ":id/:jobType/:printerId",
      onEnter: () => {}
    }
  ]
});

import PropTypes from "prop-types";
import React from "react";
import classes from "./ModalLayout.module.scss";

export const ModalLayout = ({ children }) => (
  <div className={classes.modalWrapper}>
    <div data-modal className={classes["modal-container"]}>
      {children}
    </div>
  </div>
);

ModalLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default ModalLayout;

import { injectReducer } from "../../store/reducers";

import AdminLayout from "./layout/AdminLayout";
// import DashboardView from './components/DashboardView';
import LocationView from "./components/LocationView";
import TagsView from "./components/TagsView";
import DeletedView from "./components/DeletedView";
import BarCodeGeneratorView from "./components/BarCodeGeneratorView";
import FeaturesContainer from "./components/FeaturesView";

/**
 *
 * @param auth
 *
 * @returns {boolean}
 */
export function isAuthAdmin(auth) {
  return auth && auth.getIn(["user", "isAdministrator"], false);
}

/**
 *
 * @param store
 *
 * @returns {Function}
 */
function checkNeedsAdmin(store) {
  return function(nextState, replace) {
    const state = store.getState();
    if (isAuthAdmin(state.auth)) {
      return;
    }
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log("redirecting to /order/t[unprocessed]");
    }
    replace({
      pathname: "/order/t[unprocessed]"
    });
  };
}

/**
 * @param store
 */
export default store => {
  const reducer = require("./modules/Admin").default;
  injectReducer(store, { key: "admin", reducer });
  return {
    path: "admin",
    component: AdminLayout,
    onEnter: checkNeedsAdmin(store),
    onChange: checkNeedsAdmin(store),
    childRoutes: [
      {
        path: "location",
        component: LocationView,
        onEnter: checkNeedsAdmin(store)
      },
      {
        path: "tags",
        component: TagsView,
        onEnter: checkNeedsAdmin(store)
      },
      {
        path: "deleted",
        component: DeletedView,
        onEnter: checkNeedsAdmin(store)
      },
      {
        path: "BarCodeGenerator",
        component: BarCodeGeneratorView,
        onEnter: checkNeedsAdmin(store)
      },
      {
        path: "features",
        component: FeaturesContainer,
        onEnter: checkNeedsAdmin(store)
      }
      /*,
      {
        component: AdminView,
        childRoutes: [
          {
            component: AdminView,
            indexRoute: AdminView,
            childRoutes: [
            ]
          }
        ]
      }
      */
    ]
  };
};

import Immutable from "immutable";
import { browserHistory } from "react-router";

import api from "../../../lib/Api";
import moment from "../../../lib/moment";
import { buildUrl, getParams } from "../../../lib/RouteUtil";
import { ListAndOrderedMapReviver } from "../../../lib/Immutable";

export const DATA_FETCH = "@app/order/FETCH";
export const DATA_FETCH_QUIET = "@app/order/FETCH_QUIET";
export const DATA_RECEIVE = "@app/order/RECEIVE";
export const DATA_FETCH_ERROR = "@app/order/FETCH_ERROR";

export const DATA_FLORIST_FETCH = "@app/florist/FETCH";
export const DATA_FLORIST_RECEIVE = "@app/florist/RECEIVE";
export const DATA_FLORIST_FETCH_ERROR = "@app/florist/FETCH_ERROR";

export const DATA_FLORISTS_FETCH = "@app/order/florists/FETCH";
export const DATA_FLORISTS_RECEIVE = "@app/order/florists/RECEIVE";
export const DATA_FLORISTS_FETCH_ERROR = "@app/order/florists/FETCH_ERROR";

export const DATA_FETCH_ONE = "@app/order/FETCH_ONE";
export const DATA_RECEIVE_ONE = "@app/order/RECEIVE_ONE";
export const DATA_FETCH_ONE_ERROR = "@app/order/FETCH_ONE_ERROR";

export const SCHEMA_FETCH = "@app/order/SCHEMA_FETCH";
export const SCHEMA_RECEIVE = "@app/order/SCHEMA_RECEIVE";
export const SCHEMA_FETCH_ERROR = "@app/order/SCHEMA_FETCH_ERROR";

export const DATA_UPDATE = "@app/order/UPDATE";
export const DATA_UPDATE_RECEIVE = "@app/order/UPDATE_RECEIVE";
export const DATA_UPDATE_ERROR = "@app/order/UPDATE_ERROR";
export const DATA_REPLACE_OR_INSERT = "@app/order/REPLACE_OR_INSERT";

export const SET_EDITING = "@app/order/SET_EDITING";

export const DATA_DYNALOGIC_FETCH = "@app/order/dynalogic/FETCH";
export const DATA_DYNALOGIC_RECEIVE = "@app/order/dynalogic/RECEIVE";
export const DATA_DYNALOGIC_FETCH_ERROR = "@app/order/dynalogic/FETCH_ERROR";

export const DATA_FADELLO_FETCH = "@app/order/zorgeloosbezorgd/FETCH";
export const DATA_FADELLO_RECEIVE = "@app/order/zorgeloosbezorgd/RECEIVE";
export const DATA_FADELLO_FETCH_ERROR = "@app/order/zorgeloosbezorgd/FETCH_ERROR";

export const DATA_RJP_FETCH = "@app/order/redjepakketje/FETCH";
export const DATA_RJP_RECEIVE = "@app/order/redjepakketje/RECEIVE";
export const DATA_RJP_FETCH_ERROR = "@app/order/redjepakketje/FETCH_ERROR";

export const DATA_TRUNKRS_FETCH = "@app/order/trunkrs/FETCH";
export const DATA_TRUNKRS_RECEIVE = "@app/order/trunkrs/RECEIVE";
export const DATA_TRUNKRS_FETCH_ERROR = "@app/order/trunkrs/FETCH_ERROR";

export const PRINT = "@app/order/PRINT";
export const PRINT_RECEIVE = "@app/order/PRINT_RECEIVE";
export const PRINT_ERROR = "@app/order/PRINT_ERROR";

export const PRINT_CARD = "@app/order/PRINT_CARD";
export const PRINT_CARD_RECEIVE = "@app/order/PRINT_CARD_RECEIVE";
export const PRINT_CARD_ERROR = "@app/order/PRINT_CARD_ERROR";

export const SEND_TO_REGIO = "@app/order/regio/SEND";
export const SEND_TO_REGIO_RECEIVE = "@app/order/regio/SEND_RECEIVE";
export const SEND_TO_REGIO_ERROR = "@app/order/regio/MARK_ERROR";

export const SEND_TO_EUROFLORIST_EMAIL = "@app/order/euroflorist/SEND_EMAIL";
export const SEND_TO_EUROFLORIST_EMAIL_RECEIVE = "@app/order/euroflorist/SEND_EMAIL_RECEIVE";
export const SEND_TO_EUROFLORIST_EMAIL_ERROR = "@app/order/euroflorist/SEND_EMAIL_ERROR";

export const SEND_TO_EUROFLORIST = "@app/order/euroflorist/SEND";
export const SEND_TO_EUROFLORIST_RECEIVE = "@app/order/euroflorist/SEND_RECEIVE";
export const SEND_TO_EUROFLORIST_ERROR = "@app/order/euroflorist/SEND_ERROR";

export const MARK_AS_EUROFLORIST = "@app/order/euroflorist/MARK";
export const MARK_AS_EUROFLORIST_RECEIVE = "@app/order/euroflorist/MARK_RECEIVE";
export const MARK_AS_EUROFLORIST_ERROR = "@app/order/euroflorist/MARK_ERROR";

export const SEND_TO_POSTNL = "@app/order/postnl/SEND";
export const SEND_TO_POSTNL_RECEIVE = "@app/order/postnl/SEND_RECEIVE";
export const SEND_TO_POSTNL_ERROR = "@app/order/postnl/SEND_ERROR";

export const SEND_TO_DYNALOGIC = "@app/order/dynalogic/SEND";
export const SEND_TO_DYNALOGIC_RECEIVE = "@app/order/dynalogic/SEND_RECEIVE";
export const SEND_TO_DYNALOGIC_ERROR = "@app/order/dynalogic/SEND_ERROR";

export const SEND_TO_BLANCO = "@app/order/blanco/SEND";
export const SEND_TO_BLANCO_RECEIVE = "@app/order/blanco/SEND_RECEIVE";
export const SEND_TO_BLANCO_ERROR = "@app/order/blanco/SEND_ERROR";

export const SEND_TO_FADELLO = "@app/order/zorgeloosbezorgd/SEND";
export const SEND_TO_FADELLO_RECEIVE = "@app/order/zorgeloosbezorgd/SEND_RECEIVE";
export const SEND_TO_FADELLO_ERROR = "@app/order/zorgeloosbezorgd/SEND_ERROR";

export const SEND_TO_RJP = "@app/order/redjepakketje/SEND";
export const SEND_TO_RJP_RECEIVE = "@app/order/redjepakketje/SEND_RECEIVE";
export const SEND_TO_RJP_ERROR = "@app/order/redjepakketje/SEND_ERROR";

export const SEND_TO_TRUNKRS = "@app/order/trunkrs/SEND";
export const SEND_TO_TRUNKRS_RECEIVE = "@app/order/trunkrs/SEND_RECEIVE";
export const SEND_TO_TRUNKRS_ERROR = "@app/order/trunkrs/SEND_ERROR";

export const SEND_TO_FLORIST = "@app/order/florist/SEND";
export const SEND_TO_FLORIST_RECEIVE = "@app/order/florist/SEND_RECEIVE";
export const SEND_TO_FLORIST_ERROR = "@app/order/florist/SEND_ERROR";

// let orderWs = null;
// orderWs = orderWs || new WebSocket('wss://socket-services.kingsquare.nl/boeketcadeau/werkplaats/order');
// orderWs.onmessage = (data) => {
//   console.log('boeketcadeau/werkplaats/orders', data);
//   const parts = data.split('|');
//   if (parts[0] === 'open') {
//
//   }
// };

export const SET_ACTIVE_TAB = "@app/order/SET_ACTIVE_TAB";

export const STATS_FETCH = "@app/order/stats/FETCH";
export const STATS_RECEIVE = "@app/order/stats/FETCH_RECEIVE";
export const STATS_FETCH_ERROR = "@app/order/stats/FETCH_ERROR";

export const TAGS_FETCH = "@app/order/tags/FETCH";
export const TAGS_FETCH_QUIET = "@app/order/tags/TAGS_FETCH_QUIET";
export const TAGS_RECEIVE = "@app/order/tags/FETCH_RECEIVE";
export const TAGS_FETCH_ERROR = "@app/order/tags/FETCH_ERROR";

export const TAGS_PROCESSED_FETCH = "@app/order/tags/PROCESSED_FETCH";
export const TAGS_PROCESSED_RECEIVE = "@app/order/tags/PROCESSED_RECEIVE";
export const TAGS_PROCESSED_FETCH_ERROR = "@app/order/tags/PROCESSED_FETCH_ERROR";

export const TAGS_DELIVERY_EXPIRED_FETCH = "@app/order/tags/DELIVERY_EXPIRED_FETCH";
export const TAGS_DELIVERY_EXPIRED_RECEIVE = "@app/order/tags/DELIVERY_EXPIRED_RECEIVE";
export const TAGS_DELIVERY_EXPIRED_FETCH_ERROR = "@app/order/tags/DELIVERY_EXPIRED_FETCH_ERROR";

export const REMOVE_ITEM = "@app/order/REMOVE_ITEM";

export const TAG_ITEM = "@app/order/TAG_ITEM";
export const TAG_ITEM_RECEIVE = "@app/order/TAG_ITEM_RECEIVE";
export const TAG_ITEM_ERROR = "@app/order/TAG_ITEM_ERROR";

export const TAG_CREATE = "@app/order/TAG_ITEM";
export const TAG_RECEIVE = "@app/order/TAG_RECEIVE";
export const TAG_CREATE_ERROR = "@app/order/TAG_ITEM_ERROR";

export const TAGS_UPDATE = "@app/order/TAGS_UPDATE";
export const TAGS_UPDATE_ERROR = "@app/order/TAGS_UPDATE_ERROR";

export const TAG_DELETE = "@app/order/TAG_DELETE";
export const TAG_DELETE_RECEIVE = "@app/order/TAG_DELETE_RECEIVE";
export const TAG_DELETE_ERROR = "@app/order/TAG_DELETE_ERROR";

export const ATTRIBUTES_FETCH = "@app/order/ATTRIBUTES_FETCH";
export const ATTRIBUTES_RECEIVE = "@app/order/ATTRIBUTES_RECEIVE";
export const ATTRIBUTES_FETCH_ERROR = "@app/order/ATTRIBUTES_FETCH_ERROR";

export const CLEAR_SEARCH = "@app/order/CLEAR_SEARCH";

export const NAVIGATE = "@app/orderlist/NAVIGATE";

export const SEND_CONFIRMATION = "@app/order/confirmation/SEND";
export const SEND_CONFIRMATION_RECEIVE = "@app/order/confirmation/SEND_RECEIVE";
export const SEND_CONFIRMATION_ERROR = "@app/order/confirmation/SEND_ERROR";

export const SEND_MAIL = "@order/SEND_MAIL";

// ------------------------------------
// Action Generators
// ------------------------------------

export const DELETE_ATTACHMENT = "@order/DELETE_ATTACHMENT";
export const deleteAttachment = (orderId, attachmentId) => ({
  type: DELETE_ATTACHMENT,
  payload: api(`/workbench/order/${orderId}/attachment`).destroy(attachmentId)
});

export const ADD_PHOTO_TO_ORDER = "@order/ADD_PHOTO";
export const addAttachment = (orderId, file) => {
  if (file instanceof File) {
    return {
      type: ADD_PHOTO_TO_ORDER,
      payload: api(`/workbench/order/${orderId}/attachment`).create({
        name: file.name,
        file
      })
    };
  }
  return {
    type: ADD_PHOTO_TO_ORDER,
    payload: api(`/workbench/order/${orderId}/attachment`).create(file)
  };
};

export const CREATE_ORDER = "@order/CREATE";
export const create = data => ({
  type: CREATE_ORDER,
  payload: api(`/workbench/order`).create(data)
});

export const FIND_ORDER_ID_BY_ID = "@order/FIND_ORDER_ID_BY_ID";
export const findOrderIdById = orderId => ({
  type: FIND_ORDER_ID_BY_ID,
  payload: api(`/workbench/orderId`).findById(orderId)
});

export const DELETE_LOGENTRY = "@order/DELETE_LOGENTRY";
export const deleteLogEntry = (orderId, logEntryId) => ({
  type: DELETE_LOGENTRY,
  payload: api(`/workbench/order/${orderId}/internalLog`).destroy(logEntryId)
});

export const sendMail = (id, type) => ({
  type: SEND_MAIL,
  mailType: type,
  payload: api(`/workbench/order/${id}/mail`).create({ type })
});

export const navigateNext = () => navigate("next");
export const navigatePrev = () => navigate("prev");
export const navigateByKeyCode = keyCode => navigate(keyCode === 40 ? "next" : "prev");

export const navigate = direction => (dispatch, getState) => {
  const state = getState();

  if (
    state.location.get("pathname", "").indexOf("/sendTo") !== -1 ||
    state.location.get("pathname", "").indexOf("/viewFlorist") !== -1
  ) {
    return;
  }
  dispatch({ type: NAVIGATE, payload: direction });

  const currentOrderId = state.order.getIn(["order", "data", "id"], 0);
  const orders = state.order.getIn(["data", "items", "data"], new Immutable.List());
  const itemIdx = findItemIndex(orders, currentOrderId);

  let newOrderId = orders.getIn([itemIdx + (direction === "next" ? 1 : -1), "id"], 0);

  if (!newOrderId) {
    newOrderId = orders[direction === "next" ? "first" : "last"]().get("id");
  }

  if (newOrderId) {
    browserHistory.push(buildUrl({ id: newOrderId }));
  }
};

// Attributes
export const fetchAttributes = () => dispatch => {
  dispatch({ type: ATTRIBUTES_FETCH });
  return api("/workbench/order/attribute")
    .find()
    .then(data => {
      dispatch({ type: ATTRIBUTES_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: ATTRIBUTES_FETCH_ERROR, payload: err });
    });
};

export const clearSearch = () => ({ type: CLEAR_SEARCH });

export const removeItem = id => ({ type: REMOVE_ITEM, payload: id });

export const setActiveTab = idx => ({ type: SET_ACTIVE_TAB, payload: idx });

export const setEditing = (key, value) => ({ type: SET_EDITING, payload: { key, value } });

export const ORDER_TAG_DELETE = "@app/order/tag/DELETE";
export const ORDER_TAG_DELETE_RECEIVE = "@app/order/tag/DELETE_RECEIVE";
export const ORDER_TAG_DELETE_ERROR = "@app/order/tag/DELETE_ERROR";

export const removeOrderFromTag = (orderId, tagId) => dispatch => {
  dispatch({ type: ORDER_TAG_DELETE, payload: { orderId, tagId } });
  return api(`/workbench/order/${orderId}/tag`)
    .destroy(tagId)
    .then(() => {
      dispatch({ type: ORDER_TAG_DELETE_RECEIVE, payload: { orderId, tagId } });
    })
    .catch(error => {
      dispatch({ type: ORDER_TAG_DELETE_ERROR, payload: { error } });
      throw error;
    });
};

export const deleteTag = id => dispatch => {
  dispatch({ type: TAG_DELETE, payload: id });
  return api("/workbench/tag")
    .destroy(id)
    .then(() => {
      dispatch({ type: TAG_DELETE_RECEIVE, payload: id });
    })
    .catch(error => {
      dispatch({ type: TAG_DELETE_ERROR, payload: { error } });
      throw error;
    });
};

export const updateTags = tags => dispatch => {
  dispatch({ type: TAGS_UPDATE, payload: tags });
  return api("/workbench/tag")
    .update(0, tags)
    .then(data => {
      dispatch({ type: TAGS_RECEIVE, payload: data });
    })
    .catch(error => {
      dispatch({ type: TAGS_UPDATE_ERROR, payload: { error } });
      throw error;
    });
};

export const addTag = tag => dispatch => {
  dispatch({ type: TAG_CREATE, payload: { tag } });
  return api("/workbench/tag")
    .create({ tag })
    .then(data => {
      dispatch({ type: TAG_RECEIVE, payload: data });
    })
    .catch(error => {
      dispatch({ type: TAG_CREATE_ERROR, payload: { tag, error } });
      throw error;
    });
};

export const tagItem = (id, objectId, objectType) => dispatch => {
  dispatch({ type: TAG_ITEM, payload: { id, objectId, objectType } });
  return api(`/workbench/tag/${id}?q=${getParams().query}`)
    .create({ objectId, objectType })
    .then(data => {
      // dispatch({ type: TAG_RECEIVE, payload: { id, data } });
      dispatch({ type: TAGS_RECEIVE, payload: data });
    })
    .catch(error => {
      dispatch({ type: TAG_ITEM_ERROR, payload: { id, error } });
      throw error;
    });
};

// Stats
export const fetchStats = () => dispatch => {
  dispatch({ type: STATS_FETCH });
  return api("/workbench/stats")
    .find()
    .then(data => {
      dispatch({ type: STATS_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: STATS_FETCH_ERROR, payload: err });
    });
};

// Tags
export const fetchTags = (query, quiet) => (dispatch, getState) => {
  const state = getState();

  if (state.location.getIn(["params", "tags"], new Immutable.List()).contains("processed")) {
    fetchProcessedTags(query, quiet)(dispatch, getState);
  } else if (state.location.getIn(["params", "tags"], new Immutable.List()).contains("deliveryexpired")) {
    fetchDeliveryExpiredTags(query, quiet)(dispatch, getState);
  } else {
    dispatch({ type: quiet ? TAGS_FETCH_QUIET : TAGS_FETCH, query });
    return api("/workbench/tag")
      .find({ q: getParams().query })
      .then(data => {
        dispatch({ type: TAGS_RECEIVE, payload: data });
      })
      .catch(err => {
        dispatch({ type: TAGS_FETCH_ERROR, payload: err });
      });
  }
};

export const fetchProcessedTags = query => dispatch => {
  dispatch({ type: TAGS_PROCESSED_FETCH, query });
  return api("/workbench/tag/processed")
    .find({ q: query })
    .then(data => {
      dispatch({ type: TAGS_PROCESSED_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: TAGS_PROCESSED_FETCH_ERROR, payload: err });
    });
};

export const fetchDeliveryExpiredTags = query => dispatch => {
  dispatch({ type: TAGS_DELIVERY_EXPIRED_FETCH, query });
  return api("/workbench/tag/deliveryexpired")
    .find({ q: query })
    .then(data => {
      dispatch({ type: TAGS_DELIVERY_EXPIRED_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: TAGS_DELIVERY_EXPIRED_FETCH_ERROR, payload: err });
    });
};

export const find = (tags, page, query, quiet) => dispatch => {
  dispatch({ type: quiet ? DATA_FETCH_QUIET : DATA_FETCH, payload: { tags, page, query, quiet } });
  return api("/workbench/order", true)
    .find({
      tags: tags ? tags.toJS() : [],
      page,
      q: query
    })
    .then(data => {
      dispatch({ type: DATA_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FETCH_ERROR, payload: err });
    });
};

export const fetch = () => dispatch => {
  dispatch({ type: DATA_FETCH });
  return api("/workbench/order")
    .find()
    .then(data => {
      dispatch({ type: DATA_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FETCH_ERROR, payload: err });
    });
};

export const fetchOne = (orderId, quiet) => (dispatch, getState) => {
  dispatch({ type: DATA_FETCH_ONE, quiet, payload: orderId });
  return api("/workbench/order")
    .findById(orderId)
    .then(data => {
      if (window.CommsSocket) {
        const state = getState();
        window.CommsSocket.send(
          JSON.stringify({
            type: "OPEN_ORDER",
            id: orderId,
            client: state.app.getIn(["config", "name"], "Onbekend")
          })
        );
      }
      dispatch({ type: DATA_RECEIVE_ONE, quiet, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FETCH_ONE_ERROR, quiet, payload: err });
    });
};

export const updateOrder = data => dispatch => {
  dispatch({ type: DATA_UPDATE, payload: { id: data.id, data } });
  return api("/workbench/order")
    .update(data.id, data)
    .then(data => {
      dispatch({ type: DATA_UPDATE_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_UPDATE_ERROR, payload: err });
      throw err;
    });
};

export const PRINT_EF_CARD = "@app/PRINT_EF_CARD";

export const printEfCard = data => ({
  type: PRINT_EF_CARD,
  payload: api(`/workbench/order/${data.id}/print`).create(data)
});

export const print = (id, type, printer, shipmentId) => dispatch => {
  dispatch({ type: PRINT, payload: { id, type, printer } });
  return api(`/workbench/order/${id}/print`)
    .create({ type, printer, shipmentId })
    .then(data => {
      dispatch({ type: PRINT_RECEIVE, payload: { type, data } });
    })
    .catch(error => {
      dispatch({ type: PRINT_ERROR, payload: { type, error } });
      throw error;
    });
};

export const fetchFlorists = orderId => dispatch => {
  dispatch({ type: DATA_FLORISTS_FETCH });
  return api(`/workbench/order/${orderId}/florist`)
    .find()
    .then(data => {
      dispatch({ type: DATA_FLORISTS_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FLORISTS_FETCH_ERROR, payload: err });
    });
};

export const fetchDynalogicOptions = orderId => dispatch => {
  dispatch({ type: DATA_DYNALOGIC_FETCH });
  return api(`/workbench/order/${orderId}/transport/dynalogic/config`)
    .find()
    .then(data => {
      dispatch({ type: DATA_DYNALOGIC_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_DYNALOGIC_FETCH_ERROR, payload: err });
    });
};

export const fetchZorgeloosBezorgdOptions = orderId => dispatch => {
  dispatch({ type: DATA_FADELLO_FETCH });
  return api(`/workbench/order/${orderId}/transport/zorgeloosbezorgd/config`)
    .find()
    .then(data => {
      dispatch({ type: DATA_FADELLO_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_FADELLO_FETCH_ERROR, payload: err });
    });
};

export const fetchRedjepakketjeOptions = orderId => dispatch => {
  dispatch({ type: DATA_RJP_FETCH });
  return api(`/workbench/order/${orderId}/transport/redjepakketje/config`)
    .find()
    .then(data => {
      dispatch({ type: DATA_RJP_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_RJP_FETCH_ERROR, payload: err });
    });
};

export const HYDRATE_TRANSPORT_OPTIONS = "@app/transport/HYDRATE_OPTIONS";
export const HYDRATE_TRANSPORT_SCHEMA = "@app/transport/HYDRATE_SCHEMA";
export const SET_ATTRIBUTE_VALUE = "@app/order/SET_ATTRIBUTE_VALUE";
export const REGISTER_TRANSPORT = "@app/transport/REGISTER";

export const hydrateTransportOptions = (transporterId, orderId, params) => ({
  type: HYDRATE_TRANSPORT_OPTIONS,
  transporterId,
  payload: api(`/workbench/order/${orderId}/transport/${transporterId}/config`).find({
    ...params
  })
});

export const hydrateTransportSchema = (transporterId, orderId) => ({
  type: HYDRATE_TRANSPORT_SCHEMA,
  transporterId,
  payload: api(`/workbench/order/${orderId}/transport/${transporterId}/schema`).find()
});

export const setAttributeValue = (orderId, attributeId, value) => ({
  type: SET_ATTRIBUTE_VALUE,
  attributeValue: value,
  payload: api(`/workbench/order/${orderId}/attribute`).update(attributeId, { value })
});

export const registerTransport = (transporterId, orderId, data) => ({
  type: REGISTER_TRANSPORT,
  transporterId,
  payload: api(`/workbench/order/${orderId}/transport/${transporterId}/register`).create(data)
});

export const fetchSchema = () => dispatch => {
  dispatch({ type: SCHEMA_FETCH });
  return api("/schema")
    .find("Order")
    .then(data => dispatch({ type: SCHEMA_RECEIVE, payload: data }))
    .catch(err => dispatch({ type: SCHEMA_FETCH_ERROR, payload: err }));
};

export const update = data => dispatch => {
  dispatch({ type: DATA_UPDATE, payload: data });
  return api("/workbench/order")
    .update(0, data)
    .then(data => {
      dispatch({ type: DATA_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: DATA_UPDATE_ERROR, payload: err });
    });
};

export const sendToEuroFloristEmail = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_EUROFLORIST_EMAIL, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/euroflorist/email`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_EUROFLORIST_EMAIL_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_EUROFLORIST_EMAIL_ERROR, payload: err });
      throw err;
    });
};

export const sendToEuroFlorist = id => dispatch => {
  dispatch({ type: SEND_TO_EUROFLORIST, payload: { id } });
  return api(`/workbench/order/${id}/transport/euroflorist/register`)
    .create({ id })
    .then(data => {
      dispatch({ type: SEND_TO_EUROFLORIST_RECEIVE, payload: data });
      return data;
    })
    .catch(err => {
      dispatch({ type: SEND_TO_EUROFLORIST_ERROR, payload: err });
      throw err;
    });
};

export const markAsEuroFlorist = id => dispatch => {
  dispatch({ type: MARK_AS_EUROFLORIST, payload: { id } });
  return api(`/workbench/order/${id}/transport/euroflorist/mark`)
    .create({ id })
    .then(data => {
      dispatch({ type: MARK_AS_EUROFLORIST_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: MARK_AS_EUROFLORIST_ERROR, payload: err });
      throw err;
    });
};

export const sendToRegio = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_REGIO, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/regio/register`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_REGIO_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_REGIO_ERROR, payload: err });
      throw err;
    });
};

export const sendToFlorist = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_FLORIST, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/florist/register`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_FLORIST_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_FLORIST_ERROR, payload: err });
      throw err;
    });
};

export const sendToPostNL = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_POSTNL, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/postnl/register`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_POSTNL_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_POSTNL_ERROR, payload: err });
      throw err;
    });
};

export const sendToDynalogic = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_DYNALOGIC, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/dynalogic/register`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_DYNALOGIC_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_DYNALOGIC_ERROR, payload: err });
      throw err;
    });
};

export const sendToZorgeloosBezorgd = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_FADELLO, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/zorgeloosbezorgd/register`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_FADELLO_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_FADELLO_ERROR, payload: err });
      throw err;
    });
};

export const sendToRedjepakketje = (id, data) => dispatch => {
  dispatch({ type: SEND_TO_RJP, payload: { id, data } });
  return api(`/workbench/order/${id}/transport/redjepakketje/register`)
    .create(data)
    .then(data => {
      dispatch({ type: SEND_TO_RJP_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: SEND_TO_RJP_ERROR, payload: err });
      throw err;
    });
};

export const sendConfirmation = (id, type) => dispatch => {
  dispatch({ type: SEND_CONFIRMATION, payload: { id, type } });
  return api(`/workbench/order/${id}/sendConfirmation`)
    .create({ id, type })
    .then(data => {
      dispatch({ type: SEND_CONFIRMATION_RECEIVE, payload: data });
      return data;
    })
    .catch(err => {
      dispatch({ type: SEND_CONFIRMATION_ERROR, payload: err });
      throw err;
    });
};

// ------------------------------------
// Initial State
// ------------------------------------
const State = new Immutable.Record({
  schema: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.Map()
  }),
  data: Immutable.Map({
    isFetching: false,
    hasFetched: false,
    error: "",
    items: Immutable.Map({
      meta: Immutable.Map(),
      data: Immutable.Map()
    })
  }),
  stats: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.Map()
  }),
  tags: Immutable.Map({
    isFetching: false,
    hasFetched: false,
    error: "",
    data: Immutable.List()
  }),
  tagsProcessed: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.List()
  }),
  deliveryexpired: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.List()
  }),
  attributes: Immutable.Map({
    isFetching: false,
    error: "",
    data: Immutable.List()
  }),
  order: Immutable.Map({
    isFetching: false,
    hasFetched: false,
    isUpdating: false,
    print: Immutable.Map({
      order: false,
      card1: false,
      card2: false,
      postnl: false,
      dynalogic: false,
      zorgeloosbezorgd: false,
      redjepakketje: false
    }),
    sendingMail: Immutable.Map({}),
    editing: Immutable.Map(),
    error: "",
    activeTab: 0,
    florists: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    dynalogic: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    euroflorist: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    florist: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    zorgeloosbezorgd: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    redjepakketje: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    reviva: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    trunkrs: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map(),
      schema: Immutable.Map()
    }),
    sendConfirmation: Immutable.Map({
      isFetching: false,
      isSubmitting: false,
      error: "",
      data: Immutable.Map(),
      config: Immutable.Map()
    }),
    data: Immutable.Map(),
    // attachments: Immutable.Map({
    //   isFetching: false,
    //   hasFetched: false,
    //   data: Immutable.List()
    // }),
    isUploadingPhoto: false,
    photoUploadError: null
  }),
  errors: Immutable.Map(),
  barcode: Immutable.Map({
    isCheckingOrderExists: false,
    error: null
  })
});

// ------------------------------------
// Action Handlers
// ------------------------------------

/**
 *
 * @param items
 * @param id
 *
 * @returns {*}
 */
const findItemIndex = (items, id) => items.findIndex(item => item.get("id") === id);
// const findItem = (items, id) => {
//   return items.find((item) => {
//     return item.get('id') === id;
//   });
// };

const ACTION_HANDLERS = {
  [ADD_PHOTO_TO_ORDER]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["order", "isUploadingPhoto"], true);
      case "success":
        return state.setIn(["order", "isUploadingPhoto"], false);
      case "error":
        return state.setIn(["order", "isUploadingPhoto"], false).setIn(["order", "photoUploadError"], payload);
      default:
        return state;
    }
  },
  [FIND_ORDER_ID_BY_ID]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["barcode", "isCheckingOrderExists"], true);
      case "success":
        return state.setIn(["barcode", "isCheckingOrderExists"], false);
      case "error":
        return state.setIn(["barcode", "isCheckingOrderExists"], false).setIn(["barcode", "error"], null);
      default:
        return state;
    }
  },
  [SEND_MAIL]: (state, { status, mailType, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["order", "mail", mailType, "inProgress"], true);
      case "success":
        return state.setIn(["order", "mail", mailType, "inProgress"], false);
      case "error":
        return state
          .setIn(["order", "mail", mailType, "inProgress"], false)
          .setIn(["order", "mail", mailType, "error"], payload);
      default:
        return state;
    }
  },

  [CLEAR_SEARCH]: state =>
    state.setIn(
      ["data", "items"],
      Immutable.fromJS({
        data: [],
        meta: {
          total: 0,
          page: 0,
          pageSize: 0
        }
      })
    ),

  [REMOVE_ITEM]: (state, { payload }) => {
    const itemIndex = findItemIndex(state.getIn(["data", "items", "data"]), payload);
    return state.deleteIn(["data", "items", "data", itemIndex]);
  },

  [TAG_RECEIVE]: (state, { payload }) => {
    const tagsData = state.getIn(["tags", "data"]);
    const itemIndex = findItemIndex(tagsData, payload.id);

    // try to update a known tag
    if (itemIndex !== -1) {
      return state.setIn(["tags", "data", itemIndex], Immutable.fromJS(payload));
    }

    // add a new tag
    return state.setIn(["tags", "data"], tagsData.push(Immutable.fromJS(payload)));
  },

  [TAG_DELETE]: (state, { payload }) => {
    const tagsData = state.getIn(["tags", "data"]);
    const itemIndex = findItemIndex(tagsData, payload);

    // try to update a known tag
    if (itemIndex !== -1) {
      return state.setIn(["tags", "data", itemIndex, "isDeleting"], true);
    }
    return state;
  },

  [TAG_DELETE_RECEIVE]: (state, { payload }) => {
    const tagsData = state.getIn(["tags", "data"]);
    const itemIndex = findItemIndex(tagsData, payload);

    // try to update a known tag
    if (itemIndex !== -1) {
      return state.deleteIn(["tags", "data", itemIndex]);
    }
    return state;
  },

  // [TAG_ITEM]: (state, { payload }) => {
  //   const itemIndex = findItemIndex(state.getIn(['data', 'items', 'data']), payload.id);
  //   return state.deleteIn(['data', 'items', 'data', itemIndex]);
  // },

  [SET_ACTIVE_TAB]: (state, { payload }) => state.setIn(["order", "activeTab"], payload),

  [DATA_REPLACE_OR_INSERT]: (state, { payload }) => {
    const data = Immutable.fromJS(payload);

    const currentTags = state.getIn(["data", "query", "tags"], new Immutable.List());

    const itemTag = parseInt(data.getIn(["tag", "id"], 0));

    let itemTags = Immutable.fromJS([data.get("treatment") === "Nieuw" ? "unprocessed" : "processed"]);

    if (itemTag) {
      itemTags = itemTags.push(itemTag);
    } else if (currentTags.first() === "unprocessed") {
      const today = moment().startOf("day");
      const _moment = moment(data.get("deliveryDate")).startOf("day");
      if (_moment.isSame(today)) {
        itemTags = itemTags.push("today");
      } else if (_moment.isSame(today.add(1, "days"))) {
        itemTags = itemTags.push("tomorrow");
      } else {
        itemTags = itemTags.push("other");
      }
    }

    const itemIndex = findItemIndex(state.getIn(["data", "items", "data"]), data.get("id"));

    const isSameTab = currentTags.equals(itemTags);
    const isProblemTab = currentTags.last() === itemTag && itemTag === 23279793;

    if (isSameTab || isProblemTab) {
      // update in list current data
      if (itemIndex !== -1) {
        return state.setIn(["data", "items", "data", itemIndex], Immutable.fromJS(data));
      }
      state = state.setIn(["data", "items", "data"], state.getIn(["data", "items", "data"]).insert(0, data));
      return state;
    }
    return state.deleteIn(["data", "items", "data", itemIndex]);
  },

  [DATA_UPDATE]: state => state.setIn(["order", "isUpdating"], true),
  [DATA_UPDATE_RECEIVE]: (state, { payload }) =>
    state.setIn(["order", "isUpdating"], false).setIn(["order", "data"], Immutable.fromJS(payload)),
  [DATA_UPDATE_ERROR]: (state, { payload }) =>
    state.setIn(["order", "isUpdating"], false).setIn(["order", "error"], payload),

  [DATA_FETCH]: (state, { payload }) =>
    state.setIn(["data", "query"], Immutable.fromJS(payload)).setIn(["data", "isFetching"], true),

  [DATA_FETCH_QUIET]: (state, { payload }) =>
    state.setIn(["data", "query"], Immutable.fromJS(payload)).setIn(["data", "isFetching"], false),
  [DATA_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["data", "isFetching"], false)
      .setIn(["data", "hasFetched"], true)
      .setIn(["data", "items"], Immutable.fromJS(payload)),
  [DATA_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["data", "isFetching"], false).setIn(["data", "error"], payload),

  [ATTRIBUTES_FETCH]: state => state.setIn(["attributes", "isFetching"], true),
  [ATTRIBUTES_RECEIVE]: (state, { payload }) =>
    state.setIn(["attributes", "isFetching"], false).setIn(["attributes", "data"], Immutable.fromJS(payload)),
  [ATTRIBUTES_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["attributes", "isFetching"], false).setIn(["attributes", "error"], payload),

  [TAGS_FETCH]: state => state.setIn(["tags", "isFetching"], true).setIn(["tags", "data"], Immutable.fromJS([])),
  [TAGS_FETCH_QUIET]: state => state.setIn(["tags", "isFetching"], true),
  [TAGS_RECEIVE]: (state, { payload }) => {
    const todayTagCount = state.getIn(["tags", "data", 1, "count"], -1);
    const problemTagCount = state.getIn(
      ["tags", "data", findItemIndex(state.getIn(["tags", "data"]), 23279793), "count"],
      -1
    );

    const newData = Immutable.fromJS(payload);

    const hasNewTodayCount = todayTagCount === 0 && newData.getIn([findItemIndex(newData, "today"), "count"], 0) > 0;

    const problemItemIndex = findItemIndex(newData, 23279793);
    const hasNewProblemCount = problemTagCount === 0 && newData.getIn([problemItemIndex, "count"], 0) > 0;

    return state
      .setIn(["tags", "isFetching"], false)
      .setIn(
        ["tags", "data"],
        newData.setIn([1, "hasNewCount"], hasNewTodayCount).setIn([problemItemIndex, "hasNewCount"], hasNewProblemCount)
      );
  },
  [TAGS_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["tags", "isFetching"], false).setIn(["tags", "error"], payload),

  [TAGS_PROCESSED_FETCH]: state => state.setIn(["tagsProcessed", "isFetching"], true),
  [TAGS_PROCESSED_RECEIVE]: (state, { payload }) =>
    state.setIn(["tagsProcessed", "isFetching"], false).setIn(["tagsProcessed", "data"], Immutable.fromJS(payload)),
  [TAGS_PROCESSED_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["tagsProcessed", "isFetching"], false).setIn(["tagsProcessed", "error"], payload),

  [TAGS_DELIVERY_EXPIRED_FETCH]: state => state.setIn(["deliveryexpired", "isFetching"], true),
  [TAGS_DELIVERY_EXPIRED_RECEIVE]: (state, { payload }) =>
    state.setIn(["deliveryexpired", "isFetching"], false).setIn(["deliveryexpired", "data"], Immutable.fromJS(payload)),
  [TAGS_DELIVERY_EXPIRED_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["deliveryexpired", "isFetching"], false).setIn(["deliveryexpired", "error"], payload),

  [DATA_FETCH_ONE]: (state, { quiet, payload }) => {
    if (quiet) {
      return state;
    }
    return state.set("order", new State().get("order")).setIn(["order", "isFetching"], true);
  },
  [DATA_RECEIVE_ONE]: (state, { payload }) => {
    const oldData = state.getIn(["order", "data"]);

    let newData = Immutable.fromJS(payload);

    if (newData.get("id") === oldData.get("id")) {
      const prevStatus = state.getIn(["order", "data", "treatment"], "");
      newData = newData.set("hasTreatmentChanged", prevStatus !== newData.get("treatment", ""));
    }
    // fix old workbench data
    const floristOrderItems = newData.getIn(["transport", "florist", "data", "orderItems"]);
    if (floristOrderItems) {
      newData = newData.setIn(
        ["transport", "florist", "data", "orderItems"],
        floristOrderItems.map(item => item.set("pricePerProduct", parseFloat(item.get("pricePerProduct"))))
      );
    }

    return state
      .setIn(["order", "isFetching"], false)
      .setIn(["order", "hasFetched"], true)
      .setIn(["order", "data"], newData);
  },
  [DATA_FETCH_ONE_ERROR]: (state, { payload }) =>
    state.setIn(["order", "isFetching"], false).setIn(["order", "error"], Immutable.fromJS(payload)),

  [DATA_FLORISTS_FETCH]: state => state.setIn(["order", "florists", "isFetching"], true),
  [DATA_FLORISTS_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "florists", "isFetching"], false)
      .setIn(["order", "florists", "data"], Immutable.fromJS(payload)),
  [DATA_FLORISTS_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["order", "florists", "isFetching"], false).setIn(["order", "florists", "error"], payload),

  [DATA_DYNALOGIC_FETCH]: state =>
    state
      .setIn(["order", "dynalogic", "isFetching"], true)
      .setIn(["order", "dynalogic", "config"], Immutable.fromJS({})),
  [DATA_DYNALOGIC_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "dynalogic", "isFetching"], false)
      .setIn(["order", "dynalogic", "config"], Immutable.fromJS(payload)),
  [DATA_DYNALOGIC_FETCH_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "dynalogic", "isFetching"], false)
      .setIn(["order", "dynalogic", "config"], Immutable.fromJS({}))
      .setIn(["order", "dynalogic", "error"], payload),

  [DATA_FADELLO_FETCH]: state =>
    state.setIn(["order", "zorgeloosbezorgd", "isFetching"], true).setIn(["order", "zorgeloosbezorgd", "config"], Immutable.fromJS({})),
  [DATA_FADELLO_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "zorgeloosbezorgd", "isFetching"], false)
      .setIn(["order", "zorgeloosbezorgd", "config"], Immutable.fromJS(payload)),
  [DATA_FADELLO_FETCH_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "zorgeloosbezorgd", "isFetching"], false)
      .setIn(["order", "zorgeloosbezorgd", "config"], Immutable.fromJS({}))
      .setIn(["order", "zorgeloosbezorgd", "error"], payload),

  [DATA_RJP_FETCH]: state =>
    state
      .setIn(["order", "redjepakketje", "isFetching"], true)
      .setIn(["order", "redjepakketje", "config"], Immutable.fromJS({})),
  [DATA_RJP_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "redjepakketje", "isFetching"], false)
      .setIn(["order", "redjepakketje", "config"], Immutable.fromJS(payload)),
  [DATA_RJP_FETCH_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "redjepakketje", "isFetching"], false)
      .setIn(["order", "redjepakketje", "config"], Immutable.fromJS({}))
      .setIn(["order", "redjepakketje", "error"], payload),

  [DATA_TRUNKRS_FETCH]: state =>
    state.setIn(["order", "trunkrs", "isFetching"], true).setIn(["order", "trunkrs", "config"], Immutable.fromJS({})),
  [DATA_TRUNKRS_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "trunkrs", "isFetching"], false)
      .setIn(["order", "trunkrs", "config"], Immutable.fromJS(payload)),
  [DATA_TRUNKRS_FETCH_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "trunkrs", "isFetching"], false)
      .setIn(["order", "trunkrs", "config"], Immutable.fromJS({}))
      .setIn(["order", "trunkrs", "error"], payload),

  [SEND_TO_REGIO]: state =>
    state.setIn(["order", "regio", "isSubmitting1"], true).setIn(["order", "regio", "data"], Immutable.fromJS({})),
  [SEND_TO_REGIO_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "regio", "isSubmitting1"], false)
      .setIn(["order", "regio", "data"], Immutable.fromJS(payload))
      .setIn(["order", "data", "transport", "regio"], Immutable.fromJS(payload.data)),
  [SEND_TO_REGIO_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "regio", "isSubmitting1"], false)
      .setIn(["order", "regio", "data"], Immutable.fromJS({}))
      .setIn(["order", "regio", "error"], payload),

  [SEND_TO_POSTNL]: state =>
    state.setIn(["order", "postnl", "isSubmitting"], true).setIn(["order", "postnl", "data"], Immutable.fromJS({})),
  [SEND_TO_POSTNL_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "postnl", "isSubmitting"], false)
      .setIn(["order", "postnl", "data"], Immutable.fromJS(payload))
      .setIn(["order", "data", "transport", "postnl"], Immutable.fromJS(payload.data)),
  [SEND_TO_POSTNL_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "postnl", "isSubmitting"], false)
      .setIn(["order", "postnl", "data"], Immutable.fromJS({}))
      .setIn(["order", "postnl", "error"], payload),

  [SEND_TO_DYNALOGIC]: state =>
    state
      .setIn(["order", "dynalogic", "isSubmitting"], true)
      .setIn(["order", "dynalogic", "data"], Immutable.fromJS({})),
  [SEND_TO_DYNALOGIC_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "dynalogic", "isSubmitting"], false)
      .setIn(["order", "dynalogic", "data"], Immutable.fromJS(payload))
      .setIn(["order", "data", "transport", "dynalogic"], Immutable.fromJS(payload.data)),
  [SEND_TO_DYNALOGIC_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "dynalogic", "isSubmitting"], false)
      .setIn(["order", "dynalogic", "data"], Immutable.fromJS({}))
      .setIn(["order", "dynalogic", "error"], payload),

  [SEND_TO_FADELLO]: state =>
    state.setIn(["order", "zorgeloosbezorgd", "isSubmitting"], true).setIn(["order", "zorgeloosbezorgd", "data"], Immutable.fromJS({})),
  [SEND_TO_FADELLO_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "zorgeloosbezorgd", "isSubmitting"], false)
      .setIn(["order", "zorgeloosbezorgd", "data"], Immutable.fromJS(payload))
      .setIn(["order", "data", "transport", "zorgeloosbezorgd"], Immutable.fromJS(payload.data)),
  [SEND_TO_FADELLO_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "zorgeloosbezorgd", "isSubmitting"], false)
      .setIn(["order", "zorgeloosbezorgd", "data"], Immutable.fromJS({}))
      .setIn(["order", "zorgeloosbezorgd", "error"], payload),

  [SEND_TO_EUROFLORIST_EMAIL]: state =>
    state
      .setIn(["order", "euroflorist", "isSubmitting"], true)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS({})),
  [SEND_TO_EUROFLORIST_EMAIL_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["data", "items", "meta", "total"], state.getIn(["data", "items", "meta", "total"]) - 1)
      .setIn(
        ["data", "items", "data"],
        state.getIn(["data", "items", "data"]).remove(findItemIndex(state.getIn(["data", "items", "data"]), payload.id))
      )
      .setIn(["order", "euroflorist", "isSubmitting"], false)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS(payload)),
  [SEND_TO_EUROFLORIST_EMAIL_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "euroflorist", "isSubmitting"], false)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS({}))
      .setIn(["order", "euroflorist", "error"], payload),

  [SEND_TO_FLORIST]: state =>
    state.setIn(["order", "florist", "isSubmitting"], true).setIn(["order", "florist", "data"], Immutable.fromJS({})),
  [SEND_TO_FLORIST_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["data", "items", "meta", "total"], state.getIn(["data", "items", "meta", "total"]) - 1)
      .setIn(
        ["data", "items", "data"],
        state.getIn(["data", "items", "data"]).remove(findItemIndex(state.getIn(["data", "items", "data"]), payload.id))
      )
      .setIn(["order", "florist", "isSubmitting"], false)
      .setIn(["order", "florist", "data"], Immutable.fromJS(payload)),
  [SEND_TO_FLORIST_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "florist", "isSubmitting"], false)
      .setIn(["order", "florist", "data"], Immutable.fromJS({}))
      .setIn(["order", "florist", "error"], payload),

  [SEND_TO_EUROFLORIST]: state =>
    state
      .setIn(["order", "euroflorist", "isSubmitting1"], true)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS({})),
  [SEND_TO_EUROFLORIST_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "euroflorist", "isSubmitting1"], false)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS(payload)),
  [SEND_TO_EUROFLORIST_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "euroflorist", "isSubmitting1"], false)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS({}))
      .setIn(["order", "euroflorist", "error"], payload),

  [MARK_AS_EUROFLORIST]: state =>
    state
      .setIn(["order", "euroflorist", "isSubmitting2"], true)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS({})),
  [MARK_AS_EUROFLORIST_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["data", "items", "meta", "total"], state.getIn(["data", "items", "meta", "total"]) - 1)
      .setIn(
        ["data", "items", "data"],
        state.getIn(["data", "items", "data"]).remove(findItemIndex(state.getIn(["data", "items", "data"]), payload.id))
      )
      .setIn(["order", "euroflorist", "isSubmitting2"], false)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS(payload)),
  [MARK_AS_EUROFLORIST_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "euroflorist", "isSubmitting2"], false)
      .setIn(["order", "euroflorist", "data"], Immutable.fromJS({}))
      .setIn(["order", "euroflorist", "error"], payload),

  [SCHEMA_FETCH]: state => state.setIn(["schema", "isFetching"], true),
  [SCHEMA_RECEIVE]: (state, { payload }) =>
    state.setIn(["schema", "isFetching"], false).setIn(["schema", "data"], Immutable.fromJS(payload)),
  [SCHEMA_FETCH_ERROR]: (state, { payload }) =>
    state.setIn(["schema", "isFetching"], false).setIn(["schema", "error"], Immutable.fromJS(payload)),

  [SET_EDITING]: (state, { payload }) => state.setIn(["order", "editing", payload.key], payload.value),

  [PRINT]: (state, { payload }) => state.setIn(["order", "print", payload.type], true),
  [PRINT_RECEIVE]: (state, { payload }) => state.setIn(["order", "print", payload.type], false),
  [PRINT_ERROR]: (state, { payload }) => state.setIn(["order", "print", payload.type], false),

  [SEND_CONFIRMATION]: state =>
    state
      .setIn(["order", "sendConfirmation", "isSubmitting"], true)
      .setIn(["order", "sendConfirmation", "data"], Immutable.fromJS({})),
  [SEND_CONFIRMATION_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["order", "sendConfirmation", "isSubmitting"], false)
      .setIn(["order", "sendConfirmation", "data"], Immutable.fromJS(payload)),
  [SEND_CONFIRMATION_ERROR]: (state, { payload }) =>
    state
      .setIn(["order", "sendConfirmation", "isSubmitting"], false)
      .setIn(["order", "sendConfirmation", "data"], Immutable.fromJS({}))
      .setIn(["order", "sendConfirmation", "error"], payload),
  [HYDRATE_TRANSPORT_OPTIONS]: (state, { status, transporterId, payload }) => {
    const path = ["order", transporterId, "config"];
    const isFetchingPath = path.concat(["isFetching"]);
    const errorPath = path.concat(["error"]);
    switch (status) {
      case "pending":
        return state.setIn(isFetchingPath, true);
      case "success": {
        const configPath = path.concat(["data"]);
        return state
          .setIn(isFetchingPath, false)
          .setIn(configPath, Immutable.fromJS(payload))
          .setIn(errorPath, null);
      }
      case "error":
        return state.setIn(isFetchingPath, false).setIn(errorPath, payload);
    }
  },
  [HYDRATE_TRANSPORT_SCHEMA]: (state, { status, transporterId, payload }) => {
    const path = ["order", transporterId, "schema"];
    const isFetchingPath = path.concat(["isFetching"]);
    const errorPath = path.concat(["error"]);
    switch (status) {
      case "pending":
        return state.setIn(isFetchingPath, true);
      case "success": {
        const configPath = path.concat(["data"]);
        return state
          .setIn(isFetchingPath, false)
          .setIn(path.concat(["hasFetched"]), true)
          .setIn(configPath, Immutable.fromJS(payload, ListAndOrderedMapReviver))
          .setIn(errorPath, null);
      }
      case "error":
        return state.setIn(isFetchingPath, false).setIn(errorPath, payload);
    }
  },
  [SET_ATTRIBUTE_VALUE]: (state, { status, attributeValue }) => {
    if (status === "success") {
      return state.setIn(["order", "data", "excludeFromGuestbookInvitation"], attributeValue);
    }
    return state;
  },
  [REGISTER_TRANSPORT]: (state, { status, transporterId, payload }) => {
    const path = ["order", transporterId];
    const isSubmittingPath = path.concat(["isSubmitting"]);
    const dataPath = path.concat(["data"]);
    const errorPath = path.concat(["error"]);
    switch (status) {
      case "pending":
        return state.setIn(isSubmittingPath, true);
      case "success":
        return state
          .setIn(isSubmittingPath, false)
          .setIn(dataPath, Immutable.fromJS(payload))
          .setIn(["order", "data", "transport", transporterId], Immutable.fromJS(payload.data))
          .setIn(errorPath, null);
      case "error":
        return state.setIn(isSubmittingPath, false).setIn(errorPath, payload);
    }
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import request from "superagent-bluebird-promise";

import { store } from "~/store/createStore";

const { Request } = request;

/*
 * Add API Token header to each request
 */

const { promise } = Request.prototype;

Request.prototype.promise = function() {
  const req = this;

  const state = store.getState();

  req.set("Accept", "application/json");
  req.set("X-API-TOKEN", state.auth ? state.auth.get("token") : "");

  // if (process.env.NODE_ENV === "development") {
  //   req.set("XDEBUG_SESSION_START", "1").query("XDEBUG_SESSION_START=1");
  // }
  return promise.apply(this, arguments);
};

/**
 *
 * @returns {request.Request}
 */
Request.prototype.noCache = function() {
  const req = this;
  req.set("Cache-Control", "no-cache");
  return req;
};

export default request;

// import Notifications from 'react-notification-system-redux';

export default function promiseMiddleware(_ref) {
  const { dispatch } = _ref;

  return next => action => {
    if (!action.payload || !action.payload.then) {
      return next(action);
    }

    dispatch(Object.assign({}, action, { status: "pending", promise: action.payload, payload: null }));
    return action.payload.then(
      result => {
        dispatch(Object.assign({}, action, { status: "success", payload: result }));
        return result;
      },
      error => {
        dispatch(Object.assign({}, action, { status: "error", payload: error }));
        throw error;
      }
    );
  };
}

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import SideBar from "./components/SideBar";
import DashboardView from "../components/DashboardView";

export const AdminLayout = ({ expanded, children }) => (
  <div className="md-content layout-column flex">
    <SideBar className="sub-side-bar" expanded={expanded} />
    <div className="md-content layout-row flex">
      <div className={`side-bar-content layout-column flex${expanded ? "" : " collapsed"}`}>
        {children || <DashboardView />}
      </div>
    </div>
  </div>
);

AdminLayout.propTypes = {
  children: PropTypes.element,
  expanded: PropTypes.bool
};

export default connect(state => ({
  expanded: state.app.getIn(["sidebar", "expanded"], true)
}))(AdminLayout);

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./AttachmentThumbnail.scss";
import Spinner from "~/components/Loader/Spinner";
import LoaderContainer from "~/components/Loader";

class AttachmentThumbnail extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    srcParams: PropTypes.string,
    type: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    canDelete: PropTypes.bool,
    isUploading: PropTypes.bool,
    isDeleting: PropTypes.bool,
    onDelete: PropTypes.func,
    id: PropTypes.number
  };

  static defaultProps = {
    canDelete: true,
    isUploading: false
  };

  state = {};

  constructor(props) {
    super(props);

    this.linkRef = React.createRef();
  }

  onAttachmentOpen = () => {
    if (this.props.isUploading || this.props.isDeleting) {
      return;
    }
    this.linkRef.current.click();
  };

  onDestroyAttachment = e => {
    e.preventDefault();
    if (this.props.isDeleting) {
      return;
    }
    if (this.props.onDelete) {
      this.props.onDelete(this.props.id);
    }
  };

  render() {
    // console.log(this.props.type, this.props.src);
    const isImage = this.props.src && this.props.type.startsWith("image/");

    const { isDeleting } = this.props;

    return (
      <div
        onDoubleClick={this.onAttachmentOpen}
        className={classNames(
          "AttachmentThumbnail",
          this.props.isUploading && "AttachmentThumbnail--isUploading",
          isDeleting && "AttachmentThumbnail--isDeleting"
        )}
      >
        <div className="AttachmentThumbnail__Image">
          {this.props.isUploading && (
            <div>
              <Spinner color={"silver"} />
            </div>
          )}
          {!this.props.isUploading && isImage && <img src={`${this.props.src}${this.props.srcParams}`} alt="" />}
          {!this.props.isUploading && !isImage && (
            <div>
              <i className={"far fa-file"} />
            </div>
          )}
        </div>
        <div className="AttachmentThumbnail__Title">{this.props.title}</div>
        {!this.props.isUploading && (
          <div className="AttachmentThumbnail__Controls">
            {isDeleting ? (
              <LoaderContainer spinnerSize={16} color="silver" />
            ) : (
              <React.Fragment>
                <a ref={this.linkRef} href={this.props.src} target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-search" />
                </a>
                <i className="fa fa-trash" onClick={this.onDestroyAttachment} />
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AttachmentThumbnail;

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { buildUrl } from "lib/RouteUtil";

import { clearSearch } from "routes/Order/modules/Order";

class SearchControl extends React.Component {
  state = {
    value: ""
  };

  componentDidMount() {
    this.setState({ value: this.props.searchString });
  }

  componentWillReceiveProps(props) {
    if (this.props.searchString !== props.searchString) {
      this.setState({ value: props.searchString });
    }
  }

  onSearch = e => {
    if (!this.refs.input.value) {
      return;
    }

    // on click or on key down
    if (e.keyCode === undefined || e.keyCode === 13) {
      // VK_ENTER
      browserHistory.push(buildUrl({ query: this.refs.input.value, tags: [] }));
    }
  };

  onClear = () => {
    this.props.clearSearch();
    this.refs.input.value = "";
    browserHistory.push("/order/t[unprocessed]");
  };

  onChange = e => {
    this.setState({ value: e.target.value });
  };

  render() {
    const searchString = this.state.value;
    return (
      <div className="SearchControl">
        <i className="fa fa-search" style={{ cursor: "pointer" }} onClick={this.onSearch} />
        <input
          ref="input"
          className="search-control"
          value={searchString}
          onChange={this.onChange}
          placeholder="Zoeken..."
          onKeyDown={this.onSearch}
        />
        {searchString ? <i className="fa fa-times" style={{ cursor: "pointer" }} onClick={this.onClear} /> : null}
      </div>
    );
  }
}

SearchControl.propTypes = {
  searchString: PropTypes.string,
  clearSearch: PropTypes.func
};

export default connect(
  (state, props) => ({
    params: state.location.get("params"),
    searchString: state.location.getIn(["params", "query"], "")
  }),
  {
    clearSearch
  }
)(SearchControl);

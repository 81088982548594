import { injectReducer } from "../../store/reducers";

import OrderViewContainer from "./containers/OrderViewContainer";

export default store => {
  const reducer = require("./modules/Order").default;
  injectReducer(store, { key: "order", reducer });
  return {
    path: "order",
    component: OrderViewContainer,
    childRoutes: [
      {
        path: "q[:query]"
      },
      {
        path: "q[:query]/p[:page]"
      },
      {
        path: "q[:query]/p[:page]/:id(/:action)"
      },
      {
        path: "q[:query]/:id(/:action)"
      },
      {
        path: "t[:tags]"
      },
      {
        path: "t[:tags]/p[:page]"
      },
      {
        path: "t[:tags]/q[:query]"
      },
      {
        path: "t[:tags]/p[:page]/:id(/:action)"
      },
      {
        path: "t[:tags]/q[:query]/:id(/:action)"
      },
      {
        path: "t[:tags]/q[:query]/p[:page]/:id(/:action)"
      },
      {
        path: "t[:tags]/:id(/:action)"
      },
      {
        path: ":id(/:action)"
      }
    ]
  };
};

import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ucfirst from "locutus/php/strings/ucfirst";
import { Link } from "react-router";
import Immutable from "immutable";

import Button from "~/components/Button";
import { SmallLoader } from "~/components/Loader";
import { buildUrl } from "~/lib/RouteUtil";

//
class TransportButton extends React.Component {
  static propTypes = {
    transporter: PropTypes.object.isRequired,
    shipments: PropTypes.instanceOf(Immutable.Map).isRequired,
    transporterId: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    isRegioPrintedOnly: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    isPrinting: PropTypes.bool.isRequired,
    onPrint: PropTypes.func,
    barcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    trackUrl: PropTypes.string,
    linkTo: PropTypes.string,
    isScreenOpen: PropTypes.bool
  };

  static defaultTypes = {
    onSubmit: null,
    onPrint: null,
    status: null,
    barcode: "",
    trackUrl: "",
    linkTo: "",
    isScreenOpen: ""
  };

  state = {
    isChecked: false
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isScreenOpen && this.props.isScreenOpen) {
      this.setState({ isChecked: false });
    }
  }

  onCheckReSumit = () => {
    if (this.props.isScreenOpen) {
      return;
    }
    this.setState({ isChecked: !this.state.isChecked });
  };

  onSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.transporterId);
    }
  };

  onPrint = () => {
    this.props.onPrint(this.props.transporterId);
  };

  renderShipment(code, url) {
    if (!code || this.props.isRegioPrintedOnly) {
      return null;
    }
    return (
      <div key={`Shipment_${code}_${url}`} className="layout-row margin-bottom-5 layout-align-center-center center">
        Track:&nbsp;
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {code}
          </a>
        )}
      </div>
    );
  }

  renderShipments() {
    if (this.props.barcode || this.props.shipments.size === 0) {
      return this.renderShipment(this.props.barcode, this.props.trackUrl);
    }
    return this.props.shipments.keySeq().map(key => this.renderShipment(key, this.props.shipments.get(key)));
  }

  onChange = () => {};

  render() {
    const { shipments, transporter, transporterId, barcode, linkTo, isSubmitting, isPrinting, status } = this.props;

    const title = ucfirst(transporter.get("extraAttribute_28090100") || transporterId);

    let isRegistered = (shipments && shipments.size > 0) || barcode || this.props.isRegistered;
    if (transporterId === "regio" && this.props.isRegioPrintedOnly) {
      isRegistered = false;
    }

    return (
      <div className="flex layout-column">
        <div className="layout-column margin-bottom-5">
          {!isRegistered && status !== "PROCESSING" ? (
            <Button linkTo={linkTo} onClick={this.onSubmit} disabled={isSubmitting}>
              {isSubmitting ? <SmallLoader /> : null}
              <span>
                Stuur via <strong>{title}</strong>
              </span>
            </Button>
          ) : (
            <Button onClick={this.onPrint} disabled={isPrinting || status === "PROCESSING"}>
              {isPrinting ? <SmallLoader /> : null}
              <span>
                Print <strong>{title}</strong> label
              </span>
            </Button>
          )}
        </div>
        <div className="layout-column">
          {this.renderShipments()}
          {status && (
            <div className="layout-row margin-bottom-5 layout-align-center-center center">
              <i>{status === "PROCESSING" ? "Wacht op label..." : status}</i>
            </div>
          )}
          {!isRegistered && !status && <div className="flex-1" />}
          {isRegistered && (
            <div className="layout-row margin-bottom-5 layout-align-center-center center">
              <input
                onChange={this.onChange}
                type="checkbox"
                onClick={this.onCheckReSumit}
                defaultValue={1}
                className="mr-3"
                checked={this.state.isChecked && !this.props.isScreenOpen}
              />
              {this.state.isChecked && !this.props.isScreenOpen ? (
                <Link to={linkTo}>opnieuw aanmelden</Link>
              ) : (
                <div>opnieuw aanmelden</div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default hot(module)(
  connect((state, props) => {
    const transporterId = props.transporter && props.transporter.get("title");
    if (!transporterId) {
      return {};
    }
    return {
      transporterId,
      isScreenOpen: state.location.get("pathname").indexOf(`sendTo${ucfirst(transporterId)}`) !== -1,
      linkTo: buildUrl({ action: `sendTo${ucfirst(transporterId)}` }),
      isSubmitting: state.order.getIn(["order", transporterId, "isSubmitting"], false),
      isPrinting: state.order.getIn(["order", "print", transporterId], false),
      shipments: state.order.getIn(["order", "data", "transport", transporterId, "shipments"], Immutable.Map()),
      barcode: state.order.getIn(["order", "data", "transport", transporterId, "code"], ""),
      isRegioPrintedOnly: state.order.getIn(["order", "data", "isRegioPrintedOnly"], false),
      trackUrl: state.order.getIn(["order", "data", "transport", transporterId, "url"], ""),
      isRegistered: state.order.getIn(["order", "data", "transport", transporterId, "isRegistered"], false),
      status: state.order.getIn(["order", "data", "transport", transporterId, "status"], "")
    };
  })(TransportButton)
);

import PropTypes from "prop-types";
import React from "react";
import { Field as ReduxFormField } from "redux-form";

export const RenderInput = props => {
  const radioOrCheck = props.type === "radio" || props.type === "checkbox";
  // if (props.type === 'checkbox' || props.type === 'radio') {
  //   return RenderCheck(props);
  // }
  if (radioOrCheck) {
    return <RenderOptionInput {...props} />;
  }
  return (
    <div>
      {props.label ? (
        <label className="col-form-label control-label col-sm-4 col-sx-12" htmlFor={props.id}>
          {props.placeholder}
        </label>
      ) : null}
      {props.children}
    </div>
  );
};
RenderInput.propTypes = {
  children: PropTypes.any,
  label: PropTypes.any,
  id: PropTypes.any,
  type: PropTypes.any,
  placeholder: PropTypes.any
};

export const RenderOptionInput = () => (
  // if (props.type === 'checkbox' || props.type === 'radio') {
  //   return RenderCheck(props);
  // }
  <div>TEST</div>
);

// todo get form type from context
export const RenderWrapper = props => <div className="form-group row">{props.children}</div>;
RenderWrapper.propTypes = {
  children: PropTypes.any
};

export const Input = props => (
  <RenderWrapper>
    {props.label ? (
      <label className="col-form-label control-label col-sm-4 col-xs-12" htmlFor={props.id}>
        {props.placeholder}{" "}
      </label>
    ) : null}
    <div className={props.label ? "col-sm-8 col-xs-12" : "col-xs-12"}>
      <ReduxFormField className="form-control" component="input" {...props} />
    </div>
  </RenderWrapper>
);
Input.propTypes = {
  ...ReduxFormField.propTypes,
  component: PropTypes.any
};

export const Select = props => (
  <RenderWrapper>
    <ReduxFormField component="select" {...props} />
  </RenderWrapper>
);
Select.propTypes = {
  ...ReduxFormField.propTypes,
  component: PropTypes.any
};

export const CheckBox = props => (
  <RenderWrapper>
    <div className="col-xs-12">
      <label className="custom-control custom-checkbox">
        <ReduxFormField className="custom-control-input" component="input" type="checkbox" {...props} />
        <span className="custom-control-indicator" />
        <span className="custom-control-description">{props.label}</span>
      </label>
    </div>
  </RenderWrapper>
);
CheckBox.propTypes = {
  ...ReduxFormField.propTypes,
  component: PropTypes.any,
  label: PropTypes.string
};

export const CheckBoxGroup = () => <RenderWrapper />;
CheckBoxGroup.propTypes = {
  ...ReduxFormField.propTypes
};

export const RadioGroup = () => <RenderWrapper />;
RadioGroup.propTypes = {
  ...ReduxFormField.propTypes,
  component: PropTypes.any
};

export const RenderInput2 = props => (
  <div className="form-group row">
    {props.label ? (
      <label className="col-form-label control-label col-sm-4 col-sx-12" htmlFor={props.id}>
        {props.placeholder}
      </label>
    ) : null}
    <div className={props.label ? "col-sm-8 col-sx-12" : "col-sx-12"}>
      <ReduxFormField className="form-control" {...props} />
    </div>
  </div>
);
RenderInput2.propTypes = {
  children: PropTypes.any,
  label: PropTypes.any,
  id: PropTypes.any,
  type: PropTypes.any,
  placeholder: PropTypes.any
};

export class Field extends React.Component {
  render() {
    const component = this.props.component || "input";
    const props = { component, ...this.props };
    return (
      <RenderWrapper {...props}>
        <RenderInput id={`form-field-${this.props.name}`} {...props} />
      </RenderWrapper>
    );

    // const {
    //   label,
    //   ...rest
    // } = this.props;
    //
    // return <div className="form-group row">
    //   {
    //     label
    //       ? <label className="col-form-label control-label col-sm-4 col-sx-12" htmlFor={id}>
    //         {this.props.placeholder}
    //       </label>
    //       : null
    //   }
    //   <div className={label ? 'col-sm-8 col-sx-12' : 'col-sx-12'}>
    //     <ReduxFormField className="form-control" id={id} {...rest} />
    //   </div>
    // </div>;
  }
}

Field.propTypes = {
  ...ReduxFormField.propTypes,
  label: PropTypes.any
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import Quagga from "@ericblade/quagga2";
import { toastr } from "react-redux-toastr";

// import classes from "./WebCamContainer.module.scss";
import { browserHistory } from "react-router";
import { buildUrl } from "~/lib/RouteUtil";
import classes from "~/routes/Studio/components/WebCamContainer.module.scss";
import { connect } from "react-redux";
import Loader from "~/components/Loader/Loader";

import "./index.scss";

class BarCodeScannerContainer extends Component {
  static propTypes = {
    findOrderIdById: PropTypes.func.isRequired,
    isCheckingOrderExists: PropTypes.bool.isRequired
  };

  static defaultProps = {};

  state = {
    toldYouOnce: false,
    lastResult: null,
    showViewport: false
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            width: this.containerRef.current.offsetWidth,
            height: this.containerRef.current.offsetHeight || 768,
            aspectRatio: { min: 1, max: 100 },
            facingMode: "environment"
          }
        },
        locator: {
          patchSize: "medium",
          halfSample: true
        },
        numOfWorkers: 2,
        decoder: {
          readers: ["ean_reader"]
        },
        locate: true
      },
      err => {
        if (err) {
          this.setState({ error: err });
          return;
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(this.onDetected);

    Quagga.onProcessed(result => {
      if (!result) {
        return;
      }
      if (result.codeResult && result.codeResult.code) {
        return;
      }
      // console.log("onProcessed", result);

      const drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(box => box !== result.box)
            .forEach(box => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
            });
        }

        if (result.box) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, {
            color: "red",
            lineWidth: 3
          });
        }
      }
    });
  }

  componentWillUnmount() {
    Quagga.offDetected(this.onDetected);
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = e => {
    // Escape
    if (e.keyCode === 27) {
      e.preventDefault();
      this.onToggleViewPort();
    }
  };

  onDetected = result => {
    if (this.props.isCheckingOrderExists) {
      return;
    }

    const code = parseInt(result.codeResult.code.substring(0, 12), 10);
    // console.log("onDetected", this.state.lastResult, code, document.location.pathname);

    if (
      !this.state.toldYouOnce &&
      this.state.lastResult === code &&
      document.location.pathname.indexOf("/addPhoto") !== -1
    ) {
      toastr.info(`Order ${code} is al open!`);
      this.setState({ toldYouOnce: true });
      return;
    }

    // open on new code or same code but not yet open
    if (this.state.lastResult !== code || document.location.pathname.indexOf("/addPhoto") === -1) {
      console.log("onDetected", this.state.lastResult, code, document.location.pathname);
      this.setState({ lastResult: code }, () => {});

      Quagga.pause();

      this.props
        .findOrderIdById(code)
        .then(exists => {
          if (!exists) {
            // toastr.warning(`BarCode ${code} is niet aan een Order gerelateerd`);
            return;
          }
          toastr.success(`Order ${code} is gevonden`);
          this.onToggleViewPort();
          browserHistory.push(
            buildUrl({
              id: code,
              action: "addPhoto"
            })
          );
        })
        .then(() => {
          setTimeout(() => {
            Quagga.start();
          }, 200);
        })
        .catch(() => {
          Quagga.start();
        });
    }
  };

  onToggleViewPort = () => {
    if (this.state.showViewport) {
      window.removeEventListener("keydown", this.onKeyDown);
    } else {
      window.addEventListener("keydown", this.onKeyDown);
    }
    this.setState({
      showViewport: !this.state.showViewport
    });
  };

  render() {
    return (
      <div>
        <div className="Overlay__BarCodeViewport" style={{ display: this.state.showViewport ? "block" : "none" }}>
          <div
            id="interactive"
            ref={this.containerRef}
            className={`BarCodeViewport yo viewport ${classes.cameraInteractiveViewport}`}
          >
            {this.state.error && (
              <div>
                <div className="alert alert-danger">{this.state.error && "Geen camera gevonden!"}</div>
              </div>
            )}
            <i className="fa fa-fw fa-times" onClick={this.onToggleViewPort} />
          </div>
        </div>
        {this.props.isCheckingOrderExists && (
          <div className="Overlay__Loader">
            <Loader text="Barcode gedecteerd, controleren... " />
          </div>
        )}
        <i className="fa fa-fw fa-camera" onClick={this.onToggleViewPort} />
      </div>
    );
  }
}

export default connect(state => ({
  isCheckingOrderExists: state.order.getIn(["barcode", "isCheckingOrderExists"], false)
}))(BarCodeScannerContainer);

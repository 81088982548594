import { hot } from "react-hot-loader";
import React, { Component } from "react";
import PropTypes from "prop-types";

export class AuthedLayout extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default hot(module)(AuthedLayout);

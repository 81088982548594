import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import Immutable from "immutable";

import TransportButton from "../TransportButton";
import { registerTransport } from "../../../modules/Order";

class TransportButtons extends Component {
  static propTypes = {
    registerTransport: PropTypes.func.isRequired,
    onPrint: PropTypes.func.isRequired,
    order: PropTypes.instanceOf(Immutable.Map).isRequired,
    transporterRows: PropTypes.instanceOf(Immutable.List).isRequired
  };

  onSubmit = () => {};

  render() {
    const { transporterRows, order } = this.props;
    return (
      <div>
        {transporterRows.map((transporters, idx) => (
          <div className="layout-row gutter-5" key={`TransporterRow${idx}`}>
            {transporters.map(transporter => (
              <TransportButton
                onSubmit={this.onSubmit}
                transporter={transporter}
                key={`Transporter${transporter.get("id")}`}
                onPrint={this.props.onPrint}
              />
            ))}
          </div>
        ))}
        <div className="layout-row layout-align-center-center mt-5">
          <a target="_blank" rel="noopener noreferrer" href={order.get("url")}>
            Klant <strong>volguwbestelling</strong> link
            <i className="fa fa-external-link ml-3" />
          </a>
        </div>
      </div>
    );
  }
}

const selectTransporterRows = createSelector(
  [state => state.app],
  app => {
    const transporters = app
      .get("features")
      .filter(
        f =>
          f.get("extraAttribute_28088960") === "transporter" &&
          f.get("extraAttribute_28088961") &&
          f.get("extraAttribute_28088962", true)
      )
      .sort((a, b) => {
        if (a.get("extraAttribute_28090089") > b.get("extraAttribute_28090089")) {
          return 1;
        }
        if (a.get("extraAttribute_28090089") < b.get("extraAttribute_28090089")) {
          return -1;
        }
        return 0;
      });

    let chunkSize = 1;
    if (transporters.size >= 2) {
      chunkSize = 2;
    }
    if (transporters.size >= 3) {
      chunkSize = 3;
    }
    if (transporters.size % 2 === 0) {
      chunkSize = 2;
    }
    if (transporters.size % 3 === 0) {
      chunkSize = 3;
    }
    return Immutable.Range(0, transporters.count(), chunkSize)
      .map(chunkStart => transporters.slice(chunkStart, chunkStart + chunkSize))
      .toList();
  }
);

export default connect(
  state => ({
    transporterRows: selectTransporterRows(state)
  }),
  {
    registerTransport
  }
)(TransportButtons);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import Immutable from "immutable";

import EditableBlock from "../../EditableBlock";
import { TextArea } from "../../../../../components/Form";
import InternalLogEntry from "./InternalLogEntry";

import classes from "./InternalLog.module.scss";

class InternalLog extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    orderId: PropTypes.number.isRequired,
    entries: PropTypes.instanceOf(Immutable.List).isRequired
  };

  static defaultProps = {
    isActive: true
  };

  render() {
    if (!this.props.isActive) {
      return null;
    }
    const { orderId } = this.props;

    const hasEntries = this.props.entries && this.props.entries.size > 0;

    return (
      <div className={classes.InternalLog}>
        <EditableBlock
          title="Interne notities"
          form="internalLog"
          initialValues={{ id: orderId, internalLog: "" }}
          editIcon="plus"
        >
          <TextArea
            placeholder="Nieuwe interne notitie toevoegen"
            name="internalLog"
            emptyText=""
            contentClassName=""
          />
        </EditableBlock>

        <div className="d-flex flex-1 flex-column mb-2 pl-2 pr-2">
          {hasEntries ? (
            this.props.entries.map(entry => <InternalLogEntry orderId={orderId} key={entry.get("id")} entry={entry} />)
          ) : (
            <div className={classes.InternalLog__EmptyEntry}>Er zijn geen notities</div>
          )}
        </div>
      </div>
    );
  }
}

const selectFeature = createSelector(
  [state => state.app.get("features"), (state, featureId) => featureId],
  (features, featureId) => features.find(feature => feature.get("title") === featureId)
);

const selectFeatureActive = createSelector(
  [selectFeature],
  feature => {
    if (!feature) {
      return true;
    }
    return feature.get("extraAttribute_28088961", true);
  }
);

export default connect(state => ({
  isActive: selectFeatureActive(state, "internalLog"),
  entries: state.order.getIn(["order", "data", "internalLog"], new Immutable.List())
}))(InternalLog);

import React from "react";
import PropTypes from "prop-types";

// export Bootstrap from './Bootstrap';

export Button from "components/Button";

export { Field, FormSection, FieldArray, SubmissionError } from "redux-form";

import SelectWrapper from "./Select";
import DatePicker from "./DatePicker";

import { Field } from "redux-form";
import { ImmutableIterable } from "immutable";
import _find from "lodash/find";

import "./index.scss";

/**
 * Immutable Reviver: Converts JSON to List and OrderedMap; this keeps a object keys in the provided order
 *
 * @see https://facebook.github.io/immutable-js/docs/#/fromJS
 *
 * @param key
 * @param value
 *
 * @returns {*}
 */
export function ListAndOrderedMapReviver(key, value) {
  const isIndexed = ImmutableIterable.isIndexed(value);
  return isIndexed ? value.toList() : value.toOrderedMap();
}

export const RenderInput = ({ input, meta, renderer, label, description, ...rest }) => {
  let component = null;
  if (rest.type === "display") {
    component = (
      <div {...rest} className="form-control-static">
        {input.value}
      </div>
    );
  }
  if (rest.type === "date") {
    component = <DatePicker isValidDate={rest.isValidDate} field={{ input, meta, ...rest }} />;
  }
  if (renderer === "select") {
    component = <SelectWrapper input={input} meta={meta} {...rest} />;
  }
  if (renderer === "textarea") {
    component = <textarea {...rest} {...input} />;
  }
  if (component === null) {
    component = <input {...rest} {...input} />;
  }

  const wrap = rest.wrap !== false;
  return (
    <div className={wrap ? `${label ? "col-sm-9 " : ""}col-xs-12 form-control-container` : " empty"}>
      {component}
      {meta.touched &&
        ((meta.error && <div className="form-control-feedback">{meta.error}</div>) ||
          (meta.warning && <div className="form-control-feedback">{meta.warning}</div>))}
      {description ? <small className="form-text text-muted">{description}</small> : null}
    </div>
  );
};
RenderInput.propTypes = {
  renderer: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.any,
  input: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string
};

class FormField extends React.Component {
  static propTypes = {
    editing: PropTypes.bool,
    renderer: PropTypes.string,
    label: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    className: PropTypes.string
  };

  render() {
    const { input, meta, ...custom } = this.props;
    const {
      isEditing,
      renderer,
      label,
      editOnly,
      nonEditingPrefix,
      editing,
      emptyText,
      contentClassName,
      hint, // eslint-disable-line no-unused-vars
      ...rest
    } = custom;

    if (typeof isEditing !== "undefined" && !isEditing) {
      let { value } = input;
      if (renderer === "select" && rest.options && rest.options.length > 0) {
        value = _find(rest.options, { value: input.value });
        value = value && value.label ? value.label : input.value;
      }
      if (editOnly) {
        return null;
      }
      return <div className={value ? "" : "empty"}>{value ? (nonEditingPrefix || "") + value : value}</div>;
    }

    const id = input.name.split(".").join("-");

    rest.placeholder = rest.placeholder || label;

    const classNames = [
      rest.className,
      "form-control",
      meta.touched && meta.error ? " form-control-danger" : "",
      meta.touched && meta.warning ? " form-control-warning" : ""
    ];

    if (rest.type && rest.type === "checkbox") {
      classNames.push("form-control-checkbox");
    }

    rest.className = classNames.filter(v => !!v).join(" ");

    if (typeof rest.wrap !== "undefined" && !rest.wrap) {
      return <RenderInput id={id} input={input} meta={meta} renderer={renderer} label={label} {...rest} />;
    }

    // if (meta.valid && meta.touched) {
    //   rest.className += ' form-control-success';
    // }
    // (meta.valid && meta.touched ? ' has-success' :
    return (
      <div
        className={`form-group row${
          meta.touched && meta.error ? " has-danger" : meta.touched && meta.warning ? " has-warning" : ""
        }`}
      >
        {label && (
          <label className="control-label col-form-label col-sm-3 col-xs-12" htmlFor={id}>
            {label}
          </label>
        )}
        <RenderInput id={id} input={input} meta={meta} renderer={renderer} label={label} {...rest} />
        {hint && <div className="col-xs-12 offset-sm-3 col-sm-9 form-text text-muted">{hint}</div>}
      </div>
    );
  }
}

class InputBase extends React.Component {
  static propTypes = {
    isEditing: PropTypes.bool,
    editing: PropTypes.bool
  };

  static contextTypes = {
    isEditing: PropTypes.bool
  };

  static defaultProps = {
    isEditing: false,
    editing: false
  };
}

export class Input extends InputBase {
  render() {
    if (this.props.type === "hidden") {
      const {
        input,
        meta,
        renderer,
        label,
        editOnly,
        isEditing,
        editing,
        ...rest // eslint-disable-line no-unused-vars
      } = this.props;
      return <Field component="input" {...rest} {...input} />;
    }
    let { parse } = this.props;
    if (!parse && this.props.type === "number") {
      parse = parseInt;
    }
    return <Field component={FormField} {...this.props} parse={parse} isEditing={this.context.isEditing} />;
  }
}

export class TextArea extends InputBase {
  render() {
    return <Field component={FormField} renderer="textarea" {...this.props} isEditing={this.context.isEditing} />;
  }
}

export class Select extends InputBase {
  render() {
    return <Field component={FormField} renderer="select" {...this.props} isEditing={this.context.isEditing} />;
  }
}

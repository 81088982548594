import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import ReactTooltip from "react-tooltip";
import { Element } from "react-scroll";
import Immutable from "immutable";

import {
  initialize as initializeApp,
  fetchLocale,
  resetScrollTop,
  onWindowResize,
  onWindowScroll
} from "../../store/modules/App";
import { fetchTags } from "../../routes/Order/modules/Order";
import "../../styles/core.scss";
import Header from "./components/Header";
import Loader from "../../components/Loader";

export class AppLayout extends React.Component {
  static propTypes = {
    isInitializing: PropTypes.bool.isRequired,
    hasInitialized: PropTypes.bool.isRequired,
    initializeError: PropTypes.any,
    modalOverlay: PropTypes.any,
    children: PropTypes.node.isRequired,
    expanded: PropTypes.bool,
    scrollToTop: PropTypes.bool,
    resetScrollTop: PropTypes.func,
    scrollToBottom: PropTypes.bool,
    initializeApp: PropTypes.func,
    onWindowResize: PropTypes.func,
    onWindowScroll: PropTypes.func,
    scrollY: PropTypes.number,
    fetchLocale: PropTypes.func,
    fetchTags: PropTypes.func,
    features: PropTypes.instanceOf(Immutable.List).isRequired
  };

  static childContextTypes = {
    features: PropTypes.instanceOf(Immutable.List)
  };

  getChildContext() {
    return {
      features: this.props.features
    };
  }

  componentDidMount() {
    this.props.initializeApp();
    window.addEventListener("resize", this.onResize);
    if (this.contentEl) {
      this.contentEl.scrollTop = this.props.scrollY;
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.scrollToTop) {
      const el = document.getElementById("scrollToTop");
      if (el) {
        document.getElementById("scrollToTop").scrollTop = 0;
        this.props.resetScrollTop();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.props.onWindowResize();
  };

  onScroll = () => {
    this.props.onWindowScroll({ scrollY: this.contentEl.scrollTop, scrollX: this.contentEl.scrollLeft });
  };

  setRef = el => {
    this.content = el;
    // eslint-disable-next-line react/no-find-dom-node
    this.contentEl = ReactDOM.findDOMNode(this.content);
  };

  render() {
    const confirmOptions = {
      okText: "Ok",
      cancelText: "Annuleren",
      transitionIn: "fadeIn",
      transitionOut: "fadeOut"
    };

    const { isInitializing, hasInitialized } = this.props;

    if (isInitializing || !hasInitialized) {
      return (
        <div className="centered-dialog0">
          <img alt="" src="/img/boeketcadeau2014/sprite/logo.png" />
          <Loader message="Even geduld a.u.b. Initieel gegevens worden geladen." messagePosition="top" />
        </div>
      );
    }

    return (
      <div className="app viewport layout-column flex-100">
        {this.props.modalOverlay ? (
          <div className="app-modal-overlay">
            <div>
              <i className="fa fa-warning" />
              &nbsp;
              {this.props.modalOverlay}
            </div>
          </div>
        ) : null}
        <ReduxToastr
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          confirmOptions={confirmOptions}
        />
        <div className="md-content layout-column flex">
          <Header />
          <ReactTooltip />
          <Element
            ref={this.setRef}
            onScroll={this.onScroll}
            className="md-content layout-column flex"
            name="scrollToTop"
            id="scrollToTop"
          >
            <div className={`content layout-column flex${this.props.expanded ? "" : " collapsed"}`}>
              {this.props.children}
            </div>
          </Element>
        </div>
      </div>
    );
  }
}

export default hot(module)(
  connect(
    state => ({
      isInitializing: state.app.get("isInitializing"),
      hasInitialized: state.app.get("hasInitialized"),
      initializeError: state.app.get("initializeError"),
      features: state.app.get("features"),
      scrollY: state.app.get("scrollY", 0),
      modalOverlay: state.app.get("MODAL_OVERLAY", ""),
      expanded: state.app.get("sidebar").get("expanded"),
      scrollToTop: state.app.get("scrollToTop", false),
      scrollToBottom: state.app.get("scrollToBottom", false)
    }),
    {
      initializeApp,
      onWindowResize,
      onWindowScroll,
      fetchLocale,
      resetScrollTop,
      fetchTags
    }
  )(AppLayout)
);

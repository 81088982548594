import { hot } from "react-hot-loader/root";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { browserHistory, Router } from "react-router";
import { I18nextProvider } from "react-i18next";

import { initialize as initializeAuth } from "../routes/Auth/modules/Auth";
import Routes from "../routes/index";
import i18n from "../lib/i18n";

class AppContainer extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log("initializeAuth");
    this.props.store.dispatch(initializeAuth());
  }

  shouldComponentUpdate() {
    // https://github.com/reactjs/react-router-redux/issues/179#issuecomment-281437927
    return false;
  }

  render() {
    const { store } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <I18nextProvider i18n={i18n}>
          <Router history={browserHistory}>{Routes(store)}</Router>
        </I18nextProvider>
      </div>
    );
  }
}

export default hot(AppContainer);

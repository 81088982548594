import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import isNumber from "lodash/isNumber";
import { DropTarget } from "react-dnd";
import { createSelector } from "reselect";
import Immutable from "immutable";

import "react-virtualized/styles.css";

import { getParams } from "../../../lib/RouteUtil";
import { setActiveTab, tagItem, addTag } from "../../../routes/Order/modules/Order";

class Tab extends React.Component {
  render() {
    const { connectDropTarget } = this.props;

    const canDrop = this.props.isOver && this.props.canDrop;

    const className = `Tag${this.props.className ? ` ${this.props.className}` : ""}${canDrop ? " is-over" : ""}${
      this.props.active ? " active" : ""
    }${this.props.hasNewCount ? " hasNewCount" : ""}`;

    return connectDropTarget(
      <div className={className}>
        {this.props.to ? (
          <Link to={this.props.to} onClick={this.onClick}>
            {this.props.children}
          </Link>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

Tab.propTypes = {
  connectDropTarget: PropTypes.func,
  hasNewCount: PropTypes.bool,
  className: PropTypes.string,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  to: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.any
};

const DroppableTab = DropTarget(
  "OrderListItem",
  {
    canDrop(props /*, monitor*/) {
      if (!props["data-tags"]) {
        return true;
      }
      return props["data-tags"].filter(tag => isNumber(tag)).length > 0;
    },
    drop(props, monitor /*, component*/) {
      if (monitor.didDrop()) {
        // If you want, you can check whether some nested
        // target already handled drop
        return;
      }

      if (props.addTag) {
        // props.addTag(tag, monitor.getItem().order.get('id'), 'Order');
        return { dropped: true };
      }

      // get the last "id" tag (should only be one)
      const tag = props["data-tags"].filter(tag => isNumber(tag)).pop();
      if (!tag) {
        return;
      }

      props.tagItem(tag, monitor.getItem().order.get("id"), "Order");

      return { dropped: true };
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop()
  })
)(Tab);

class TabContainer extends React.Component {
  onClick = e => {
    e.preventDefault();

    const tags = e.target.getAttribute("data-tags");

    browserHistory.push(`/order/t[${tags}]`);

    return false;
  };

  render() {
    const { isSearchView } = this.props;
    const tags = this.props.availableTags.valueSeq();
    const isUnprocessed = this.props.activeTags.includes("unprocessed");
    const isDeliveryExpired = this.props.activeTags.includes("deliveryexpired");

    const params = getParams();
    return (
      <div className="layout-column TabContainer">
        <div className="layout-row layout-wrap layout-align-start-center TabBar">
          {tags.map(tag => {
            const id = tag.get("id");
            let _tags = [];

            if (!isSearchView) {
              if (isUnprocessed && id !== "unprocessed") {
                _tags.push("unprocessed");
              }
              if (!isUnprocessed && id !== "processed" && !isDeliveryExpired) {
                _tags.push("processed");
              }
              if (isDeliveryExpired && id !== "deliveryexpired") {
                _tags.push("deliveryexpired");
              }
            }
            _tags.push(id);

            if (isSearchView) {
              _tags = _tags.filter(t => t !== "unprocessed");
            }

            let url = _tags.length === 0 ? "/order" : `/order/t[${_tags}]`;
            if (params.query) {
              url += `/q[${params.query}]`;
            }

            const active = this.props.tags.equals(new Immutable.List(_tags));

            return (
              <DroppableTab
                tagItem={this.props.tagItem}
                key={_tags}
                to={url}
                active={active}
                data-tags={_tags}
                hasNewCount={tag.get("hasNewCount", false)}
              >
                {tag.get("title")} {tag.has("count") ? <span>({tag.get("count", 0)})</span> : null}
              </DroppableTab>
            );
          })}
          {tags.size === 0 ? <div style={{ padding: "6px", marginBottom: "5px" }}>&nbsp;</div> : null}
          <div className="flex" />
        </div>
        {this.props.children}
      </div>
    );
  }
}

TabContainer.propTypes = {
  tags: PropTypes.object, // immutable.list
  activeTags: PropTypes.object, // immutable.list
  tagItem: PropTypes.func,
  addTag: PropTypes.func,
  children: PropTypes.any,
  isSearchView: PropTypes.bool,
  availableTags: PropTypes.object // immutable.list
};

const activeTagSelector = createSelector(
  [state => state.location],
  location => location.getIn(["params", "tags"], new Immutable.List())
);

const tagSelector = createSelector(
  [state => state.order, state => activeTagSelector(state)],
  (order, activeTags) => {
    if (activeTags.includes("deliveryexpired")) {
      return order.getIn(["deliveryexpired", "data"], []);
    }
    if (activeTags.includes("processed")) {
      return order.getIn(["tagsProcessed", "data"], []);
    }
    return order.getIn(["tags", "data"], []);
  }
);

export default connect(
  state => ({
    availableTags: tagSelector(state),
    activeTags: activeTagSelector(state),
    query: state.location.getIn(["params", "query"])
  }),
  {
    setActiveTab,
    tagItem,
    addTag
  }
)(TabContainer);

import PropTypes from "prop-types";
import React from "react";
import { price } from "lib/Formatters";
import classes from "./OrderItems.module.scss";

// TODO fix alignment
export const OrderItems = ({ order }) => (
  <div className={classes.OrderItemsContainer}>
    <div className={`layout-row ${classes.OrderItem}`}>
      <div className="flex-9">&nbsp;</div>
      <div className="flex-1">aantal</div>
      <div className="flex-3">prijs p.s.</div>
      <div className="flex-3">prijs</div>
    </div>

    {order
      .get("orderItems")
      .valueSeq()
      .map(item => {
        if (item.get("productId") === 943233 || item.get("productId") === 943234) {
          return null;
        }
        return (
          <div key={item} data-product-id={item.get("productId")} className={`layout-row ${classes.OrderItem}`}>
            <div className="flex-9">{item.get("title")}</div>
            <div className="flex-1">{item.get("quantity")}</div>
            <div className="flex-3">{price(item.get("pricePerProduct"))}</div>
            <div className="flex-3">{price(item.get("price"))}</div>
          </div>
        );
      })}
    {order.get("deliveryCosts") ? (
      <div className={`top-margin-20 layout-row ${classes.OrderItem}`}>
        <div className="flex-9">Bezorgkosten</div>
        <div className="flex-1">1</div>
        <div className="flex-3">{price(order.get("deliveryCosts"))}</div>
        <div className="flex-3">{price(order.get("deliveryCosts"))}</div>
      </div>
    ) : null}
    <div className={`layout-row ${classes.OrderItem}`}>
      <div className="flex-9">Factuurkosten</div>
      <div className="flex-1">1</div>
      <div className="flex-3">{price(order.get("invoiceCosts", 0))}</div>
      <div className="flex-3">{price(order.get("invoiceCosts", 0))}</div>
    </div>
    <div className={`top-margin-20 layout-row ${classes.OrderItem}`}>
      <div className="flex-9">Totaal exclusief btw</div>
      <div className="flex-1" />
      <div className="flex-3" />
      <div className="flex-3">{price(order.get("totalEx"))}</div>
    </div>
    <div className={`layout-row ${classes.OrderItem}`}>
      <div className="flex-9">Totaal inclusief btw</div>
      <div className="flex-1" />
      <div className="flex-3" />
      <div className="flex-3">{price(order.get("totalIn"))}</div>
    </div>
  </div>
);

OrderItems.propTypes = {
  order: PropTypes.object.isRequired // Immutable Map
};

export default OrderItems;

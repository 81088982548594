import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";
import ModuleView from "components/ModuleView";
import { Input } from "components/Form";
import Paper from "components/Paper";
import Button from "components/Button";
// import isNumber from 'lodash/isNumber';
import { SmallLoader } from "components/Loader";
import { undeleteOrder, clearUndeleteOrder } from "../modules/Admin";

export class DeletedView extends React.Component {
  onUndelete = data => {
    this.props.undeleteOrder(data.orderId).then(() => {
      this.props.clearUndeleteOrder();
    });
  };

  onKeyDown = e => {
    // if (e.keyCode === 13) { // VK_ENTER
    //   this.onUndelete({orderId: e.target.value});
    // }
  };

  render() {
    return (
      <ModuleView title="Verwijderde order">
        <Paper>
          <h2>Zoek verwijderde order</h2>

          <form role="form" onSubmit={this.props.handleSubmit(this.onUndelete)}>
            <div className="row">
              <div className="col-sm-6">
                <Input name="orderId" placeholder="Verwijderde ordernummer" onKeyDown={this.onKeyDown} />
                <Button type="submit" disabled={this.props.isProcessing || this.props.pristine}>
                  {this.props.isProcessing ? <SmallLoader /> : null} Verwijderen ongedaan maken
                </Button>
              </div>
            </div>

            {this.props.apiError ? (
              <div className="row">
                <div className="col-sm-6">
                  <div>&nbsp;</div>
                  <div className="alert alert-danger">{this.props.apiError.message}</div>
                </div>
              </div>
            ) : null}

            {!this.props.apiError && this.props.orderId ? (
              <div className="row">
                <div className="col-sm-6">
                  <div>&nbsp;</div>
                  <div className="alert alert-success">Order {this.props.orderId} verwijderen is ongedaan gemaakt!</div>
                </div>
              </div>
            ) : null}
          </form>
        </Paper>
      </ModuleView>
    );
  }
}

DeletedView.propTypes = {
  ...reduxFormPropTypes,
  orderId: PropTypes.number,
  apiError: PropTypes.string,
  isProcessing: PropTypes.bool,
  undeleteOrder: PropTypes.func,
  clearUndeleteOrder: PropTypes.func
};

export default connect(
  state => ({
    isProcessing: state.admin.getIn(["undelete", "isProcessing"], false),
    orderId: state.admin.getIn(["undelete", "data"], 0),
    apiError: state.admin.getIn(["undelete", "error"])
  }),
  {
    undeleteOrder,
    clearUndeleteOrder
  }
)(
  reduxForm({
    form: "adminUndeleteOrder",
    validate: data => {
      const errors = {};
      if (!data) {
        return errors;
      }

      if (!data.orderId) {
        errors.orderId = "Dit veld is vereist";
      }

      if (!/^\d+$/.test(data.orderId) || isNaN(parseInt(data.orderId, 10))) {
        errors.orderId = "Dit veld moet een id-nummer zijn";
      }

      return errors;
    }
  })(DeletedView)
);

import { hot } from "react-hot-loader";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isFinite from "lodash/isFinite";
import { createSelector } from "reselect";

import {
  fetchTags,
  fetchDeliveryExpiredTags,
  fetchAttributes,
  find as findOrders,
  fetch,
  sendToEuroFloristEmail
} from "../modules/Order";

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the counter:   */

import OrderView from "../components/OrderView";

import Immutable from "immutable";
import { getParams } from "lib/RouteUtil";

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

 import { createSelector } from 'reselect'
 const counter = (state) => state.counter
 const tripleCount = createSelector(counter, (count) => count * 3)
 const mapStateToProps = (state) => ({
 counter: tripleCount(state)
 })

 Selectors can compute derived data, allowing Redux to store the minimal possible state.
 Selectors are efficient. A selector is not recomputed unless one of its arguments change.
 Selectors are composable. They can be used as input to other selectors.
 https://github.com/reactjs/reselect    */

const selectOrderListFeatures = createSelector(
  [state => state.app.get("features")],
  features => features.filter(f => f.get("extraAttribute_28088960") === "orderlist")
);

const selectOrderListFeature = createSelector(
  [state => selectOrderListFeatures(state), (state, title) => title],
  (features, title) => {
    if (features.size === 0) {
      return null;
    }
    return features.find(f => f.get("title") === title);
  }
);

/**
 * Defaults to true
 */
const selectIsFeatureActive = createSelector(
  [(state, title) => selectOrderListFeature(state, title)],
  feature => {
    if (!feature) {
      return true;
    }
    return feature.get("extraAttribute_28088961", true);
  }
);

const mapStateToProps = (state, props) => {
  const query = props.params.query || getParams().query;
  return {
    isZoomed: Math.round(window.devicePixelRatio * 100) !== 100,
    kmtUrl: state.auth.getIn(["url"], ""),
    isSearchView: query && query.length > 0,
    orderId: props.params.id ? parseInt(props.params.id, 10) : 0,
    orderAction: props.params.action,
    tags: new Immutable.List(
      props.params.tags
        ? props.params.tags.split(",").map(tag => {
            const n = parseInt(tag, 10);
            return isFinite(n) ? n : tag;
          })
        : []
    ),
    page: props.params.page ? props.params.page : 1,
    query: query,
    pagination: new Immutable.Map({}),
    //features: selectOrderListFeatures(state),
    displayMorningDeliveryIcon: selectIsFeatureActive(state, "displayMorningDeliveryIcon"),
    displayAfternoonDeliveryIcon: selectIsFeatureActive(state, "displayAfternoonDeliveryIcon"),
    displayEveningDeliveryIcon: selectIsFeatureActive(state, "displayEveningDeliveryIcon"),
    displayBcCardIcon: selectIsFeatureActive(state, "displayBcCardIcon"),
    displayMourningIcon: selectIsFeatureActive(state, "displayMourningIcon"),
    displayTransporterIcon: selectIsFeatureActive(state, "displayTransporterIcon"),
    displayPaymentIcon: selectIsFeatureActive(state, "displayPaymentIcon"),
    displayOrderCount: selectIsFeatureActive(state, "displayOrderCount"),
    displayOpenInvoices: selectIsFeatureActive(state, "displayOpenInvoices"),
    displayBlacklisted: selectIsFeatureActive(state, "displayBlacklisted"),
    displayPhotoServiceIcon: selectIsFeatureActive(state, "displayPhotoServiceIcon"),
    data: state.order.get("data"),
    isFetching: state.order.getIn(["data", "isFetching"]),
    location: state.location,
    error: state.order.get("data").get("error"),
    searchString: state.location.getIn(["query", "q"], ""),
    searchString2: state.order.getIn(["data", "query", "query"], "")
  };
};

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapActionCreators = {
  fetchTags,
  fetchAttributes,
  findOrders,
  fetchDeliveryExpiredTags,
  fetch,
  sendToEuroFloristEmail
};

export class OrderViewContainer extends React.Component {
  componentDidMount() {
    this.props.fetchAttributes();
  }

  render() {
    // console.log(this.props.params);
    return <OrderView {...this.props} />;
  }
}

OrderViewContainer.propTypes = {
  kmtUrl: PropTypes.string,
  isSearchView: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchDeliveryExpiredTags: PropTypes.func.isRequired,
  fetchAttributes: PropTypes.func.isRequired,
  sendToEuroFloristEmail: PropTypes.func.isRequired,
  data: PropTypes.any,
  tags: PropTypes.object, // immutable.list
  page: PropTypes.any,
  query: PropTypes.string,
  orderId: PropTypes.any,
  orderAction: PropTypes.any,
  error: PropTypes.any
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapActionCreators
  )(OrderViewContainer)
);

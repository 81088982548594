import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";
import Button from "components/Button";
import Loader, { SmallLoader } from "components/Loader";
import { Input, Select, SubmissionError } from "components/Form";
import { fetchZorgeloosBezorgdOptions, sendToZorgeloosBezorgd } from "../../modules/Order";
import moment from "lib/moment";
import { toastr } from "react-redux-toastr";
import { TIMEFRAME_ID_EVENING } from "../../../../lib/constants";

const getSelectCompatArray = imap => {
  if (!imap || !imap.size) {
    return [];
  }
  return imap
    .map(item => ({
      value: item.get("id"),
      label: item.get("title")
    }))
    .toArray();
};

export class SendToZorgeloosBezorgd extends React.Component {
  componentDidMount() {
    this.props.fetchZorgeloosBezorgdOptions(this.props.orderId);
  }

  onSubmit = data =>
    // validated data
    this.props
      .sendToZorgeloosBezorgd(this.props.orderId, data)
      .then(() => {
        this.props.doClose(`Order ${this.props.orderId} is bij ZorgeloosBezorgd aangemeld`);
        this.props.doAddToPrintQueue("zorgeloosbezorgd");
      })
      .catch(err => {
        toastr.error("Kan niet bij ZorgeloosBezorgd aanmelden!", err.message);
        console.log(err);
        throw new SubmissionError({ _error: err.message });
      });

  getDeliveryTimes = () => getSelectCompatArray(this.props.config.getIn(["timeframes", "options"]));

  render() {
    if (this.props.isFetching) {
      return (
        <div>
          <h2>Meld aan bij ZorgeloosBezorgd</h2>
          <Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />
        </div>
      );
    }

    return (
      <div>
        <h2>Meld aan bij ZorgeloosBezorgd</h2>
        <form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Input type="hidden" name="orderId" />

          <Input label="Bezorgdatum" required type="date" name="preferredDeliveryDate" />

          <Select label="Bezorgtijd" required name="preferredDeliveryTime" options={this.getDeliveryTimes()} />

          <Input label="Coli" name="coli" type="number" />

          <Input label="Opmerking voor bestuurder" name="remark" />

          <Input label="Telefoon" name="phone" />

          <Input label="Bedrijfsnaam" name="companyName" />

          <Input label="Naam" required name="name" />

          <div className="offset-sm-3" style={{ textAlign: "center" }}>
            <Button className="col-sm-8" type="submit" disabled={this.props.submitting}>
              {this.props.submitting ? <SmallLoader /> : ""}
              {!this.props.submitting && this.props.error ? (
                <i className="fa fa-exclamation-circle" title={this.props.error}>
                  &nbsp;
                </i>
              ) : (
                ""
              )}
              Meld aan bij ZorgeloosBezorgd
            </Button>
          </div>

          {this.props.error ? (
            <div className="offset-sm-3">
              <br />
              <div className="alert alert-danger layout-row">
                <div>
                  <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                </div>
                <div>
                  <strong>Er is een fout opgetreden</strong>
                  <br />
                  {this.props.error}
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
SendToZorgeloosBezorgd.propTypes = {
  ...reduxFormPropTypes,
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object, // immutable.map
  config: PropTypes.object, // immutable.map
  fetchZorgeloosBezorgdOptions: PropTypes.func,
  doAddToPrintQueue: PropTypes.func,
  isFetching: PropTypes.bool
};

const getRemark = order => {
  if (order.get("deliveryDate") && moment(order.get("deliveryDate")).format("MMDD") === "1224") {
    // return 'In verband met kerst dit versproduct altijd afgeven, meerdere omwonenden proberen.';
    return (
      "Graag altijd leveren, is kerstcadeau!. " +
      "Indien niet thuis meerdere buren/omwonenden proberen met kaart in bus. " +
      "Bij problemen bellen: 023-7370619"
    );
  }

  if (order.get("deliveryDateTime") || order.get("isMourning")) {
    // church or funeral service
    return "Indien problemen bij afleveren, bel naar BoeketCadeau 023 526 5909";
  }

  return "Bij niet aanwezig afleveren bij buren met kaart in brievenbus bij de ontvanger waar geleverd";
};

const initialValuesSelector = createSelector(
  [state => state.get("data"), state => state.get("zorgeloosbezorgd")],
  (order, zorgeloosbezorgd) => ({
    orderId: order.get("id"),
    preferredDeliveryTime: zorgeloosbezorgd.getIn(["config", "timeframes", "selected"], TIMEFRAME_ID_EVENING),
    preferredDeliveryDate: order.get("deliveryDate"),
    remark: getRemark(order),
    coli: 1,
    phone: order.getIn(["recipient", "phoneNumber"]),
    emailAddress: order.getIn(["recipient", "emailAddress"]),
    companyName: order.getIn(["recipient", "companyTitle"]),
    name: order.getIn(["recipient", "name"])
  })
);

export default connect(
  (state, props) => {
    const order = state.order.get("order");
    return {
      isFetching: order.get("isFetching") || order.getIn(["zorgeloosbezorgd", "isFetching"]),
      initialValues: initialValuesSelector(order),
      config: order.getIn(["zorgeloosbezorgd", "config"], {})
    };
  },
  {
    fetchZorgeloosBezorgdOptions,
    sendToZorgeloosBezorgd
  }
)(
  reduxForm({
    form: "SendToZorgeloosBezorgd",
    enableReinitialize: true,
    validate: data => {
      const errors = {};
      if (!data) {
        return errors;
      }

      if (!data.preferredDeliveryDate) {
        errors.preferredDeliveryDate = "Dit veld is vereist";
      }

      if (!data.preferredDeliveryTime) {
        errors.preferredDeliveryTime = "Dit veld is vereist";
      }

      if (!data.coli) {
        errors.coli = "Dit veld is vereist";
      }
      if (data.coli && data.coli > 5) {
        errors.coli = "Dit veld is te groot (max 5)";
      }

      if (data.remark && data.remark.length > 100) {
        errors.remark = "Dit veld is te lang (max 100 karakters)";
      }

      if (data.phone && data.phone.length > 15) {
        errors.phone = "Dit veld is te lang (max 15 karakters)";
      }
      if (data.emailAddress && data.emailAddress.length > 50) {
        errors.emailAddress = "Dit veld is te lang (max 50 karakters)";
      }

      if (!data.name) {
        errors.name = "Dit veld is vereist";
      }
      if (data.name && data.name.length > 100) {
        errors.name = "Dit veld is te lang (max 100 karakters)";
      }
      if (data.companyName && data.companyName.length > 100) {
        errors.companyName = "Dit veld is te lang (max 100 karakters)";
      }

      return errors;
    },
    warn: values => {
      const warnings = {};

      // if (values.card && values.card.text) {
      //   warnings.card = {};
      // }

      return warnings;
    }
  })(SendToZorgeloosBezorgd)
);

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { IndexLink, Link } from "react-router";

import classes from "./SideBar.module.scss";
import { toggleSidebarExpanded } from "store/modules/App";

const menuItems = [
  {
    title: "Mappen beheren",
    route: "/admin/tags",
    icon: "local_offer",
    updated: false
  },
  {
    title: "Locatie configuratie",
    route: "/admin/location",
    icon: "home",
    updated: false
  },
  {
    title: "Verwijderde order",
    route: "/admin/deleted",
    icon: "deleted",
    updated: false
  },
  {
    title: "Code Generator",
    route: "/admin/BarCodeGenerator",
    icon: "code",
    updated: false
  },
  {
    title: "Features",
    route: "/admin/features",
    icon: "extension",
    updated: false
  }
];

const iconClassPrefix = `${classes.navIcon} material-icons`;

let nullIndex = 0;

// does not work with events... onClick etc
// export const SideBar = ({ toggle, expanded }) => (<xml/>);

export class SideBar extends React.Component {
  toggleSidebar = () => {
    this.props.toggleSidebarExpanded(!this.props.expanded);
  };

  render() {
    const { expanded } = this.props;
    return (
      <div className={classes.container + (expanded ? "" : ` ${classes.collapsed}`)}>
        <nav className={classes.nav}>
          <div className={`${classes.navGroup} ${classes.navGroupRoot}`}>
            <div className={classes.navItem}>
              <IndexLink to="/admin" className={`${classes.mdButton} md-button`} activeClassName={classes.activeRoute}>
                <div className={classes.navItemLockUp}>
                  <i className={iconClassPrefix}>settings</i>
                  <span className={classes.displayName}>Beheer Dashboard</span>
                </div>
              </IndexLink>
            </div>
          </div>

          {menuItems.map(item => {
            if (item === null) {
              return <div key={nullIndex++} className={classes.spacer} />;
            }
            return (
              <div key={item.title} className={classes.navGroup}>
                <div className={classes.navItem}>
                  <Link
                    to={item.route}
                    className={`${classes.mdButton} md-button`}
                    activeClassName={classes.activeRoute}
                  >
                    <div className={classes.navItemLockUp}>
                      <i className={iconClassPrefix}>{item.icon}</i>
                      {item.updated ? <i className={`fa fa-circle ${classes.iconUpdated}`} /> : null}
                      <span className={classes.displayName}>{item.title}</span>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </nav>
        <a className={`${classes.mdButton} md-button ${classes.navExpando}`} onClick={this.toggleSidebar}>
          <i className={`${iconClassPrefix} ${classes.materialIcons}`}>{expanded ? "chevron_left" : "chevron_right"}</i>
        </a>
      </div>
    );
  }
}

// !expanded scroll fix?
// http://stackoverflow.com/questions/16670931/hide-scroll-bar-but-still-being-able-to-scroll

SideBar.propTypes = {
  toggleSidebarExpanded: PropTypes.func,
  expanded: PropTypes.bool
};

export default connect(
  state => ({
    expanded: state.app.get("sidebar").get("expanded"),

    // just to force re-render for activeClassName when the route changes
    // react-redux-router loc: state.location && state.location.pathname ? state.location.pathname : null
    loc: state.location.get("pathname")
  }),
  {
    toggleSidebarExpanded
  }
)(SideBar);

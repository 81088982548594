import PropTypes from "prop-types";
import React from "react";

export default class FormHorizontal extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    labels: PropTypes.any
  };

  render() {
    return (
      <form role="form" className="form-horizontal" {...this.props}>
        {this.props.children}
      </form>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import SendMailButton from "./SendMailButton";

class TransportFeedbackMails extends Component {
  static propTypes = {
    order: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  render() {
    const isTransport = ["Nieuw", "Euroflorist", "Collega bloemist"].indexOf(this.props.order.get("treatment")) === -1;
    return (
      <div>
        <h3 className="margin-top-5">Feedback mails</h3>
        <div className="flex layout-row gutter-5">
          {isTransport && (
            <SendMailButton mailType="KiB" className="flex-1">
              Niemand aanwezig
            </SendMailButton>
          )}

          {isTransport && (
            <SendMailButton mailType="AddressError" className="flex-1">
              Foutief adres
            </SendMailButton>
          )}

          <SendMailButton mailType="Confirmation" className="flex-1">
            Orderbevestiging
          </SendMailButton>
        </div>
      </div>
    );
  }
}

export default TransportFeedbackMails;

import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "./Button";
import { SmallLoader } from "../Loader";

class ProgressButton extends Component {
  static propTypes = {
    children: PropTypes.node,
    inProgress: PropTypes.bool
  };

  static defaultProps = {
    children: null,
    inProgress: false
  };

  render() {
    const { children, inProgress, ...props } = this.props;
    return (
      <Button {...props} disabled={props.disabled || inProgress} className="flex layout-row layout-align-center-center">
        {inProgress && <SmallLoader />}
        {children}
      </Button>
    );
  }
}

export default ProgressButton;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reduxForm, propTypes as reduxFormPropTypes, SubmissionError, formValueSelector } from "redux-form";
import { toastr } from "react-redux-toastr";
import ucFirst from "locutus/php/strings/ucfirst";
import Immutable from "immutable";
import { withTranslation } from "react-i18next";

import { reduxFormOnSubmitFailScrollToFirstError, ajvErrorMapperCreator, ajvInstance } from "~/lib/formUtils";
import Button from "~/components/Button";
import Loader, { SmallLoader } from "~/components/Loader";
import SchemaField from "~/components/SchemaField";
import { hydrateTransportSchema, hydrateTransportOptions, registerTransport } from "../../modules/Order";

export class SendToTransporter extends React.Component {
  static propTypes = {
    ...reduxFormPropTypes,
    transporter: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    orderId: PropTypes.number,
    order: PropTypes.object, // immutable.map
    config: PropTypes.object, // immutable.map
    hydrateTransportSchema: PropTypes.func.isRequired,
    hydrateTransportOptions: PropTypes.func.isRequired,
    registerTransport: PropTypes.func.isRequired,
    doAddToPrintQueue: PropTypes.func.isRequired,
    isFetching: PropTypes.bool
  };

  static childContextTypes = {
    schema: PropTypes.object, // immutable.map
    t: PropTypes.func // immutable.map
  };

  getChildContext() {
    return {
      t: this.props.t,
      schema: this.props.schema
    };
  }

  componentDidMount() {
    this.props.hydrateTransportSchema(this.props.transporter, this.props.orderId || 0);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.transporter !== this.props.transporter ||
      prevProps.zipcode !== this.props.zipcode ||
      prevProps.countryCode !== this.props.countryCode ||
      prevProps.deliveryDate !== this.props.deliveryDate
    ) {
      const { deliveryDate, zipcode, countryCode } = this.props;
      if (prevProps.transporter !== this.props.transporter) {
        this.props.hydrateTransportSchema(this.props.transporter, this.props.orderId || 0);
      }
      this.props.hydrateTransportOptions(this.props.transporter, this.props.orderId || 0, {
        deliveryDate,
        zipcode,
        countryCode
      });
    }
  }

  onManualReset = () => {
    // hackity hack
    // https://github.com/erikras/redux-form/issues/2971#issuecomment-485730043
    this.props.destroy();
    this.props.initialize(this.props.initialValues);
  };

  onSubmit = data =>
    // validated data
    this.props
      .registerTransport(this.props.transporter, this.props.orderId || 0, data)
      .then(response => {
        if (!this.props.orderId) {
          this.props.doClose(`Pakket ${response.id} is bij ${this.props.title} aangemeld`);
        } else {
          this.props.doClose(`Order ${this.props.orderId || 0} is bij ${this.props.title} aangemeld`);
        }
        return this.props.doAddToPrintQueue(this.props.transporter, response.id);
      })
      .catch(err => {
        toastr.error(`Kan niet bij ${this.props.title} aanmelden!`, err.message);
        // eslint-disable-next-line no-console
        console.log(err);
        throw new SubmissionError({ _error: err.message });
      });

  renderSchema(schema, path = [], name) {
    if (schema.get("type") === "object") {
      const subPath = path.slice(0);
      if (name) {
        subPath.push(name);
      }
      return schema
        .get("properties")
        .keySeq()
        .map(name => {
          const subSchema = schema.getIn(["properties", name]).set(
            "name",
            []
              .concat(subPath)
              .concat(name)
              .join(".")
          );
          return this.renderSchema(subSchema, subPath, name);
        });
    }
    return <SchemaField key={schema.get("name")} name={schema.get("name")} />;
  }

  render() {
    const title = `Aanmelden bij ${this.props.title}`;

    if (!this.props.hasFetched || this.props.isFetching) {
      return (
        <div>
          <h2>{title}</h2>
          <Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />
        </div>
      );
    }

    return (
      <div>
        <h2>{title}</h2>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
          {this.renderSchema(this.props.schema)}

          <div className="offset-sm-3" style={{ textAlign: "center" }}>
            <Button type="submit" className="col-sm-8" disabled={this.props.submitting}>
              {this.props.submitting && <SmallLoader />}
              {!this.props.submitting && this.props.error && (
                <i className="fa fa-exclamation-circle mr-3" title={this.props.error}>
                  &nbsp;
                </i>
              )}
              {title}
            </Button>
          </div>

          {this.props.error && (
            <div className="offset-sm-3">
              <br />
              <div className="alert alert-danger layout-row">
                <div>
                  <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                </div>
                <div>
                  <strong>Er is een fout opgetreden</strong>
                  <br />
                  {this.props.error}
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const selectOrder = createSelector(
  [state => state.order.get("order")],
  order => order || EMPTY_MAP
);

const selectSchema = createSelector(
  [(state, transporter) => state.order.getIn(["order", transporter, "schema", "data"])],
  schema => (schema ? schema : EMPTY_MAP)
);

const selectConfig = createSelector(
  [(state, transporter) => state.order.getIn(["order", transporter, "config", "data"])],
  schema => (schema ? schema : EMPTY_MAP)
);

const EMPTY_MAP = Immutable.Map();

const initialValuesSelector = createSelector(
  [schema => schema.get("default")],
  values => (values || EMPTY_MAP).toJS()
);

const formName = "SendToTransporter";
const selectFormValue = formValueSelector(formName);

export default withTranslation()(
  connect(
    (state, props) => {
      const { transporter } = props;
      const title = ucFirst(transporter);
      const order = selectOrder(state);
      return {
        title,
        transporter,
        deliveryDate: selectFormValue(state, "extraAttribute_944437"),
        zipcode: selectFormValue(state, "deliveryAddress.zipcode"),
        countryCode: selectFormValue(state, "deliveryAddress.countryCode"),
        form: formName,
        isFetching: order.get("isFetching") || order.getIn([transporter, "schema", "isFetching"]),
        hasFetched: order.getIn([transporter, "schema", "hasFetched"]),
        initialValues: initialValuesSelector(selectSchema(state, transporter)),
        schema: selectSchema(state, transporter),
        config: selectConfig(state, transporter)
      };
    },
    {
      hydrateTransportSchema,
      hydrateTransportOptions,
      registerTransport
    }
  )(
    reduxForm({
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
      onSubmitFail: reduxFormOnSubmitFailScrollToFirstError,
      validate: (data, props) => {
        const errors = {};
        if (!data || props.schema.size === 0) {
          return errors;
        }

        if (!ajvInstance.getSchema(props.transporter)) {
          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log("[ajv] compiling api schema");
          }
          ajvInstance.compile(props.schema.set("$id", props.transporter).toJS(), props.transporter);
        }

        if (__DEV__) {
          // eslint-disable-next-line no-console
          console.log("data", data);
        }
        const isValid = ajvInstance.validate(props.transporter, data);
        if (__DEV__) {
          // eslint-disable-next-line no-console
          console.log("isValid", isValid);
        }
        if (isValid) {
          return {};
        }

        if (__DEV__) {
          // eslint-disable-next-line no-console
          console.error("ajv.errors", ajvInstance.errors);
        }

        ajvInstance.errors.map(ajvErrorMapperCreator(errors, props.schema, props.t));

        if (__DEV__) {
          // eslint-disable-next-line no-console
          console.error("form.errors", errors);
        }
        return errors;
      }
    })(SendToTransporter)
  )
);

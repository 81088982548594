// Initial source https://gist.github.com/AubreyHewes/6aceb2bfb1b201512506dfb8b568d0ab
import React, { Component } from "react";
import PropTypes from "prop-types";
import ChartJS from "chart.js";
import "chartjs-plugin-datalabels";

// const datalabels = ChartJS.defaults.global.plugins.datalabels;
// delete ChartJS.defaults.global.plugins.datalabels;

/**
 * React Wrapper Component for ChartJS charts
 * Based on comment by @MatthewHerbst @https://github.com/reactjs/react-chartjs/issues/112
 *
 * @depend chart-js
 * @depend chartjs-plugin-datalabels TODO; make this a soft depend
 *
 * For chart documentation @see http://www.chartjs.org/docs/latest
 */
class ReactChartJS extends Component {
  static propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    datalabels: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
    options: PropTypes.shape({
      scales: PropTypes.any,
      animation: PropTypes.any
    }),
    type: PropTypes.oneOf(["line", "bar", "radar", "doughnut", "pie", "polarArea", "bubble", "scatter"]),
    data: PropTypes.shape({
      labels: PropTypes.array,
      datasets: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.array
        })
      )
    }).isRequired,
    onAfterRender: PropTypes.func
  };

  static defaultProps = {
    type: "line",
    datalabels: false,
    onAfterRender: null,
    options: {},
    width: 600,
    height: 400
  };

  componentDidMount() {
    if (this.props.type) {
      const options = { ...this.props.options };
      options.plugins = options.plugins || {};
      if (!options.datalabels) {
        options.plugins.datalabels = options.plugins.datalabels || {};
        options.plugins.datalabels.display = false;
      }
      this.chart = new ChartJS(this.canvasEl, {
        type: this.props.type,
        data: this.props.data,
        options
      });
      if (this.props.onAfterRender) {
        this.props.onAfterRender(this.chart);
      }
    }
  }

  componentDidUpdate() {
    if (this.chart) {
      if (this.props.data && this.props.data.datasets) {
        this.chart.data.datasets = this.props.data.datasets;
      }
      if (this.props.data && this.props.data.labels) {
        this.chart.data.labels = this.props.data.labels;
      }
      // this.chart.options = this.props.options;
      this.chart.update();
      if (this.props.onAfterRender) {
        this.props.onAfterRender(this.chart);
      }
    }
  }

  componentWillReceiveProps(props) {
    if ((this.chart && this.props.width !== props.width) || this.props.height !== props.height) {
      this.chart.width = props.width;
      this.chart.height = props.height;
      this.chart.update();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  setRef = el => {
    this.canvasEl = el;
  };

  render() {
    return <canvas ref={this.setRef} height={this.props.height} width={this.props.width} />;
  }
}

export default ReactChartJS;

import PropTypes from "prop-types"; // eslint-disable-line no-unused-vars
import React from "react";

export const DataList = props => {
  if (!props.renderer) {
    return <div className="alert alert-warning">NO RENDERER CONFIGURED</div>;
  }
  return props.renderer();
};

DataList.propTypes = {
  renderer: PropTypes.func,
  items: PropTypes.any,
  className: PropTypes.any,
  selected: PropTypes.any,
  linkToTpl: PropTypes.any
};

export default DataList;

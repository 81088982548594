import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reduxForm, propTypes as reduxFormPropTypes, SubmissionError } from "redux-form";
import Button from "components/Button";
import moment from "lib/moment";
import { price } from "lib/Formatters";
import chunk_split from "locutus/php/strings/chunk_split"; // eslint-disable-line camelcase
import { Input } from "components/Form";
import { toastr } from "react-redux-toastr";
import { sendToEuroFloristEmail } from "../../modules/Order";
import { scrollToTop } from "store/modules/App";
import { CARD_PRODUCT_ID, CARD_RIBBON_ID, PRODUCT_ID_DISCOUNT } from "../../../../lib/constants";

export class SendToEurofloristEmail extends React.Component {
  onSubmit = data =>
    // validated data
    this.props
      .sendToEuroFloristEmail(this.props.orderId, data)
      .then(() => {
        this.props.doFinalised(`Order ${this.props.orderId} naar EuroFlorist`);
      })
      .catch(err => {
        toastr.error("Kan niet naar EuroFlorist versturen!", err.message);
        console.log(err);
        throw new SubmissionError({ _error: err.message });
      });

  render() {
    return (
      <div>
        <h2>Order naar EuroFlorist (Email)</h2>
        <form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Input type="hidden" name="01" />
          <Input type="hidden" name="02" />
          <Input type="hidden" name="03" />
          <Input type="hidden" name="05" />

          <Input label="Afleverdatum" required type="date" name="06" />

          <Input type="hidden" name="7" />
          <Input type="hidden" name="10" />

          <Input label="Bloemenwaarde" required name="12" />
          <Input label="Orderkosten" required name="13" />
          <Input label="Afleverkosten" required name="14" />
          <Input label="Totaal" required name="15" />
          <Input type="hidden" name="16" />

          <Input label="Naam Ontvanger 1" required name="21" />
          <Input label="Naam Ontvanger 2" name="22" />

          <Input label="Adres 1" required name="23" />
          <Input label="Adres 2" name="24" />
          <Input label="Postcode" required name="31" />
          <Input label="Plaats" required name="32" />
          <Input label="Land" name="34" />

          <Input label="Telefoonnummer" name="35" />

          <Input label="Productbeschrijving" required name="41" />
          <Input label="Beschrijving 2" maxLength="66" name="42" />
          <Input label="Beschrijving 3" maxLength="66" name="43" />
          <Input label="Beschrijving 4" maxLength="66" name="44" />

          <Input label="Code verwerkende bloemist (optioneel)" name="39" />
          <Input type="hidden" name="46" />
          <Input type="hidden" name="50" />

          <Input label="Kaarttekst 1" maxLength="66" name="51" />
          <Input label="Kaarttekst 2" maxLength="66" name="52" />
          <Input label="Kaarttekst 3" maxLength="66" name="53" />
          <Input label="Kaarttekst 4" maxLength="66" name="54" />
          <Input label="Kaarttekst 5" maxLength="66" name="55" />

          <Input label="Speciale instructies 1" maxLength="66" name="61" />
          <Input label="Speciale instructies 2" maxLength="66" name="62" />
          <Input label="Speciale instructies 3" maxLength="66" name="63" />

          <Input label="Afbeelding code" name="64" />
          <Input label="Afbeelding code" name="65" />
          <Input label="Afbeelding code" name="66" />

          <div className="offset-sm-3" style={{ textAlign: "center" }}>
            <Button type="submit" className="col-sm-8" disabled={this.props.invalid || this.props.submitting}>
              {this.props.submitting ? <i className="fa fa-spin fa-circle-o-notch">&nbsp;</i> : ""}
              {!this.props.submitting && this.props.error ? (
                <i className="fa fa-exclamation-circle" title={this.props.error}>
                  &nbsp;
                </i>
              ) : (
                ""
              )}
              Stuur naar EuroFlorist
            </Button>
          </div>

          {this.props.error ? (
            <div className="offset-sm-3">
              <br />
              <div className="alert alert-danger layout-row">
                <div>
                  <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                </div>
                <div>
                  <strong>Er is een fout opgetreden</strong>
                  <br />
                  {this.props.error}
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
SendToEurofloristEmail.propTypes = {
  ...reduxFormPropTypes,
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object, // immutable.map
  florists: PropTypes.object, // immutable.map
  fetchFlorists: PropTypes.func,
  sendToEuroFloristEmail: PropTypes.func,
  isFetching: PropTypes.bool
};

const initialValuesSelector = createSelector(
  [state => state],
  order => {
    const orderNumber = order.get("orderNumber") ? order.get("orderNumber").replace("BLB", "") : "";

    if (!order.get("id")) {
      return order.toJS();
    }

    const orderItemCosts = order.get("orderItems").reduce((total, orderItem) => {
      total += orderItem.get("price");
      return total;
    }, 0);
    const orderCosts = 1.35;
    const deliveryCosts = 4.4;

    const IGNORABLE_PRODUCT_IDS = [CARD_PRODUCT_ID, PRODUCT_ID_DISCOUNT];

    const productDescriptionLines = order
      .get("orderItems")
      .filter(item => IGNORABLE_PRODUCT_IDS.indexOf(item.get("productId")) === -1)
      .map(item => item.get("title"))
      .join(", ")
      .split(" ")
      .reduce(
        (lines, word) => {
          if (`${lines[lines.length - 1] + word} `.length > 66) {
            lines.push(`${word} `);
          } else {
            lines[lines.length - 1] += `${word} `;
          }
          return lines;
        },
        [""]
      )
      .slice(0, 4); // in case more are added...

    let cardTextLines = `Graag een "${order.getIn(["card", "title"])}" er aan. \n${order.getIn(["card", "text"])}`
      .split(" ")
      .reduce(
        (lines, word) => {
          if (`${lines[lines.length - 1] + word} `.length > 66) {
            lines.push(`${word} `);
          } else {
            lines[lines.length - 1] += `${word} `;
          }
          return lines;
        },
        [""]
      )
      .slice(0, 5); // in case more are added...

    if (order.getIn(["card", "id"]) === CARD_RIBBON_ID) {
      cardTextLines = [`Graag een bijpassend "${order.getIn(["card", "title"])}" er aan.`];
      const left = `Lint 1: ${order.getIn(["card", "left"])}`;
      const right = `Lint 2: ${order.getIn(["card", "right"])}`;
      cardTextLines.push(left.substring(0, 60));
      if (left.substring(60).length > 0) {
        cardTextLines.push(left.substring(60));
      }
      cardTextLines.push(right.substring(0, 60));
      if (right.substring(60).length > 0) {
        cardTextLines.push(right.substring(60));
      }
    }

    const imageLines = chunk_split(`<img src="${order.get("imageUrl")}"/>`, 68, "\n").split("\n");

    return {
      "01": "01",
      "02": "2972", // TODO externalise
      "03": "TFH",
      "05": moment().format("DDMMYYYY"),

      "06": moment(order.get("deliveryDate")).format("YYYY-MM-DD"),
      "07": "N",
      "10": orderNumber,

      "12": price(orderItemCosts, false),
      "13": price(orderCosts, false),
      "14": price(deliveryCosts, false),
      "15": price(orderItemCosts + orderCosts + deliveryCosts, false),
      "16": "EUR",

      "21": order.getIn(["recipient", "companyName"])
        ? order.getIn(["recipient", "companyName"])
        : order.getIn(["recipient", "name"]),
      "22": order.getIn(["recipient", "companyName"]) ? order.getIn(["recipient", "name"]) : "",

      "23": `${order.getIn(["recipient", "address", "street"])} ${order.getIn([
        "recipient",
        "address",
        "streetNumber"
      ])}`,
      "24": "",
      "31": order.getIn(["recipient", "address", "zipcode"]),
      "32": order.getIn(["recipient", "address", "city"]),
      "34":
        order.getIn(["recipient", "address", "countryCode"]) === "NL"
          ? ""
          : order.getIn(["recipient", "address", "countryCode"]),
      "35": order.getIn(["recipient", "phoneNumber"]),

      "39": "",
      "46": "B",
      "50": "9",

      // product
      "41": productDescriptionLines[0] || "",
      "42": productDescriptionLines[1] || "",
      "43": productDescriptionLines[2] || "",
      "44": productDescriptionLines[3] || "",

      // card
      "51": cardTextLines[0] || "",
      "52": cardTextLines[1] || "",
      "53": cardTextLines[2] || "",
      "54": cardTextLines[3] || "",
      "55": cardTextLines[4] || "",

      // instructions
      "61": `Referentie: ${order.get("id")}`,
      "62": "",
      "63": "",

      // image
      "64": imageLines[0] || "",
      "65": imageLines[1] || "",
      "66": imageLines[2] || ""
    };
  }
);

export default connect(
  (state, props) => {
    const order = state.order.get("order");
    return {
      isFetching: order.get("isFetching"),
      orderError: order.get("error"),
      action: order.get("euroflorist"),
      submittedOrderId: order.getIn(["order", "euroflorist", "data"]),
      initialValues: initialValuesSelector(order.get("data"))
    };
  },
  {
    sendToEuroFloristEmail,
    scrollToTop
  }
)(
  reduxForm({
    form: "SendToEurofloristEmail",
    enableReinitialize: true,
    validate: data => {
      const errors = {};
      //
      // if (!data.floristId) {
      //   errors.floristId = 'Dit veld is vereist';
      // }
      return errors;
    },
    warn: values => {
      const warnings = {};

      // if (values.card && values.card.text) {
      //   warnings.card = {};
      //   warnings.card.text = 'Hmm, you seem a bit young...';
      // }

      return warnings;
    }
  })(SendToEurofloristEmail)
);

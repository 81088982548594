import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import Chart from "../../../../components/Chart";

const data2ChartData = data => ({
  labels: getLabels(data.get("data")),
  datasets: data2DataSets(data)
});

const getLabels = data => {
  if (!data.size) {
    return [];
  }
  return (Immutable.List.isList(data) ? data.getIn([0, "data"]) : data)
    .keySeq()
    .map(month => month)
    .toJS();
};

const colors = [
  "rgba(219,197,142,0.5)",
  "rgba(215,174,234,0.5)",
  "rgba(156,196,141,0.5)",
  "rgba(161,187,236,0.5)",
  "rgba(222,239,181,0.5)",
  "rgba(209,182,223,0.5)",
  "rgba(108,201,177,0.5)",
  "rgba(233,166,183,0.5)",
  "rgba(94,198,207,0.5)",
  "rgba(234,164,140,0.5)",
  "rgba(120,197,231,0.5)",
  "rgba(238,189,147,0.5)",
  "rgba(140,228,238,0.5)",
  "rgba(198,166,117,0.5)",
  "rgba(167,232,218,0.5)",
  "rgba(203,178,146,0.5)",
  "rgba(182,234,193,0.5)",
  "rgba(241,216,177,0.5)",
  "rgba(145,192,170,0.5)",
  "rgba(191,196,145,0.5)"
];

const data2DataSets = data => {
  if (!data || data.size === 0) {
    return [];
  }
  const list = !Immutable.List.isList(data.get("data")) ? new Immutable.List([data]) : data.get("data");
  return list
    .map((dataset, idx) => {
      //
      // const color = idx === 0 ? '220,220,220' : '151,187,205';
      let color = dataset.get("color");

      let backgroundColor = color;
      let borderColor = color;

      const fillOptions = {};
      if (list.size > 1) {
        color = colors[idx];
        backgroundColor = color;
        borderColor = color;
        fillOptions.fill = false;
      } else {
        fillOptions.fillColor = backgroundColor;
      }
      return {
        label: dataset.get("name"),
        borderCapStyle: "butt",
        ...fillOptions,
        backgroundColor,
        borderColor,
        pointColor: borderColor,
        strokeColor: borderColor,
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: borderColor,
        data: dataset
          .get("data")
          .valueSeq()
          .toJS()
      };
    })
    .filter(v => !!v)
    .toArray();
};

class Graph extends Component {
  static propTypes = {
    data: PropTypes.instanceOf(Immutable.Map).isRequired,
    width: PropTypes.number,
    height: PropTypes.number
  };

  static defaultProps = {
    width: 400,
    height: 150
  };

  shouldComponentUpdate(props) {
    return (
      this.props.width !== props.width || this.props.height !== props.height || !this.props.data.equals(props.data)
    );
  }

  render() {
    const { data, ...rest } = this.props;

    const chartData = data2ChartData(data);

    // responsive
    return <Chart data={chartData} {...rest} />;
  }
}

export default Graph;

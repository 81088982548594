import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { toastr } from "react-redux-toastr";
import { reduxForm, FieldArray, FormSection, propTypes as reduxFormPropTypes, formValueSelector } from "redux-form";
import isNumber from "lodash/isNumber";

import Alert from "../../../../components/Alert";
import Button from "../../../../components/Button";
import Loader, { SmallLoader } from "../../../../components/Loader";
import { Input, TextArea, Select, SubmissionError } from "../../../../components/Form";
import { fetchFlorists, sendToFlorist } from "../../modules/Order";
import { price } from "../../../../lib/Formatters";
import {
  PRODUCT_ID_DISCOUNT,
  PRODUCT_ID_INVOICE,
  SHIPPING_PRODUCT_ID,
  VASE_PRODUCT_IDS
} from "../../../../lib/constants";

export const Address = connect(state => ({
  countryCodes: state.app.getIn(["locale", "countryCodes"])
}))(({ countryCodes }) => (
  <FormSection name="address">
    <Input label="Straat" required name="street" />
    <Input label="Huisnummer" required name="streetNumber" />
    <Input label="Postcode" required name="zipcode" />
    <Input label="Plaats" required name="city" />
    <Select
      label="Land"
      required
      name="countryCode"
      autocomplete="section-workbench florist countryCode"
      options={countryCodes}
    />
  </FormSection>
));
Address.propTypes = {
  countryCodes: PropTypes.object // Immutable.List
};

export const OrderItems = ({ fields, meta: { touched, error }, orderItems }) => {
  let total = 0;
  return (
    <div className="row">
      <div className="col-sm-12 col-xs-12">
        <div className="form-group row table-header orderline">
          <div className="col-xs-7">Titel</div>
          <div className="col-xs-1">Aantal</div>
          <div className="col-xs-2">Prijs p/s</div>
          <div className="col-xs-2">Totaal</div>
        </div>
        {fields.map((member, idx) => {
          // orderItems are the redux-form orderItems
          if (!orderItems || !orderItems[idx]) {
            return;
          }
          let itemTotal = 0;
          if (orderItems && orderItems[idx] && orderItems[idx].pricePerProduct) {
            if (isNumber(orderItems[idx].pricePerProduct)) {
              orderItems[idx].pricePerProduct = `${orderItems[idx].pricePerProduct.toFixed(2)}`;
            }
            const pricePerProduct = parseFloat(orderItems[idx].pricePerProduct.replace(",", "."));
            itemTotal = parseInt(orderItems[idx].quantity, 10) * pricePerProduct;
          }
          total += itemTotal;
          return (
            <div className="form-group row orderline" key={member + idx}>
              <div className="col-xs-7">
                <Input name={`${member}.title`} wrap={false} />
              </div>
              <div className="col-xs-1">
                <Input type="number" min="0" lang="en" step="any" name={`${member}.quantity`} wrap={false} />
              </div>
              <div className="col-xs-2">
                <Input
                  type="text"
                  pattern="^[\d]+([,.][\d]+)?$"
                  name={`${member}.pricePerProduct`}
                  wrap={false}
                  normalize={value => value.replace(".", ",")}
                />
              </div>
              <div className="col-xs-2">
                <div className="form-control-static">{price(itemTotal)}</div>
              </div>
            </div>
          );
        })}
        <div className="form-group row table-footer orderline">
          <div className="col-xs-7">&nbsp;</div>
          <div className="col-xs-1">&nbsp;</div>
          <div className="col-xs-2">&nbsp;</div>
          <div className="col-xs-2">
            <div className="form-control-static">{price(total)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
OrderItems.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.object,
  orderItems: PropTypes.array,
  order: PropTypes.object // Immutable.Map
};

export const getFloristDeliveryCity = (florist, recipientCity) => {
  recipientCity = recipientCity.toLowerCase();

  const floristDeliveryCities = florist.get("deliveryCities");
  return floristDeliveryCities.filter(city => city.get("city", "CITY").toLowerCase() === recipientCity);
};

export const getFloristDeliveryPrice = (florist, recipientCity) => {
  recipientCity = recipientCity.toLowerCase();

  const floristDeliveryCities = florist.get("deliveryCities");
  const floristDeliveryCitiesSize = floristDeliveryCities.size;

  const floristDeliveryCity = floristDeliveryCities.filter(
    city => city.get("city", "CITY").toLowerCase() === recipientCity
  );

  return parseFloat(
    floristDeliveryCity.getIn(
      [0, "price"],
      floristDeliveryCitiesSize > 0 ? floristDeliveryCities.last().get("price", 0) : 0
    )
  );
};

export class SendToColleague extends React.Component {
  componentDidMount() {
    this.props.fetchFlorists(this.props.orderId);
  }

  getFlorists = () => {
    const recipientCity = this.props.order.getIn(["recipient", "address", "city"], "");

    return this.props.florists
      .valueSeq()
      .map(florist => {
        const floristDeliveryCity = getFloristDeliveryCity(florist, recipientCity);

        const deliveryTimes = `${floristDeliveryCity.getIn([0, "startTime"], "")} - ${floristDeliveryCity.getIn(
          [0, "endTime"],
          ""
        )}`;

        return {
          value: florist.get("id"),
          label: [
            florist.get("name"),
            florist.getIn(["address", "city"]),
            getFloristDeliveryPrice(florist, recipientCity),
            florist.get("phoneNumber"),
            deliveryTimes === " - " ? "" : deliveryTimes,
            florist.get("deliveryCount")
          ].join(" | ")
        };
      })
      .toArray();
  };

  onSubmit = data =>
    // validated data
    this.props
      .sendToFlorist(this.props.orderId, data)
      .then(() => this.props.doFinalised(`Order ${this.props.orderId} naar collega bloemist`))
      .catch(err => {
        toastr.error("Kan niet naar Bloemist versturen!", err.message);
        console.log(err);
        throw new SubmissionError({ _error: err.message });
      });

  render() {
    if (this.props.isFetching) {
      return (
        <div>
          <h2>Order naar collega bloemist</h2>
          <Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />
        </div>
      );
    }
    if (this.props.florists.size === 0) {
      return (
        <div>
          <h2>Order naar collega bloemist</h2>
          <div className="alert alert-warning">
            Helaas hebben we geen collega bloemist voor deze order kunnen vinden.
          </div>
        </div>
      );
    }

    const floristComment = this.props.florist && this.props.florist.get("comment");
    return (
      <div>
        <h2>Order naar collega bloemist</h2>
        <form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Input type="hidden" name="orderNumber" />

          <Select label="Kies een collega bloemist" required options={this.getFlorists()} name="floristId" />

          {floristComment ? <Alert type="info">{floristComment}</Alert> : null}

          <Input label="Afleverdatum" required type="date" name="deliveryDate" />

          <FieldArray name="orderItems" component={OrderItems} orderItems={this.props.orderItems} />

          <FormSection name="recipient">
            <Input label="Bezorgbedrijf" name="company" />
            <Input label="Bezorgnaam" name="name" />
            <Address name="address" />
            <Input label="Telefoonnummer" name="phoneNumber" />
          </FormSection>

          <TextArea label="Kaarttekst" name="card.text" />

          <TextArea label="Omschrijving" name="description" />

          <TextArea label="Opmerking" name="comment" />

          <div className="offset-sm-3" style={{ textAlign: "center" }}>
            <Button type="submit" className="col-sm-8" disabled={this.props.invalid || this.props.submitting}>
              {this.props.submitting ? <SmallLoader /> : ""}
              {!this.props.submitting && this.props.error ? (
                <span>
                  <i className="fa fa-exclamation-circle" title={this.props.error} />
                  &nbsp;
                </span>
              ) : (
                ""
              )}
              Stuur naar collega bloemist
            </Button>
          </div>

          {this.props.error ? (
            <div className="offset-sm-3">
              <br />
              <div className="alert alert-danger layout-row">
                <div>
                  <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                </div>
                <div>
                  <strong>Er is een fout opgetreden</strong>
                  <br />
                  {this.props.error}
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
SendToColleague.propTypes = {
  ...reduxFormPropTypes,
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object, // immutable.map
  florists: PropTypes.object, // immutable.map
  fetchFlorists: PropTypes.func,
  isFetching: PropTypes.bool
};

import Immutable from "immutable";

const initialValuesSelector = createSelector(
  [(state, form) => state.get("data"), (state, form) => state.get("florists").get("data"), (state, form) => form],
  (order, florists, formData) => {
    order = order.set("comment", "");

    const orderNumber = order.get("orderNumber") ? order.get("orderNumber").replace("BLB", "") : "";

    if (florists.size === 1) {
      order = order.set("floristId", florists.get(0).get("id"));
    }

    if (order.get("orderItems") && formData && formData.values && formData.values.orderItems) {
      const form = Immutable.fromJS(formData.values);

      const floristId = parseInt(form.get("floristId", 0));
      if (floristId) {
        order = order.set("floristId", floristId);
      }

      order = order.set(
        "orderItems",
        order
          .get("orderItems")
          .filter((orderItem, idx) => {
            const productId = orderItem.get("productId", 0);
            return productId !== PRODUCT_ID_INVOICE && productId !== PRODUCT_ID_DISCOUNT;
          })
          .map((orderItem, idx) => {
            if (VASE_PRODUCT_IDS.indexOf(orderItem.get("productId", 0)) !== -1) {
              orderItem = orderItem.set("title", "Graag een bijpassende glazen vaas van ongeveer:");
            }

            if (orderItem.get("productId", 0) === SHIPPING_PRODUCT_ID) {
              if (floristId) {
                const florist = florists.find(florist => florist.get("id") === floristId);
                if (florist) {
                  const price = `${getFloristDeliveryPrice(
                    florist,
                    form.getIn(["recipient", "address", "city"], "")
                  ).toFixed(2)}`.replace(".", ",");
                  if (price) {
                    return orderItem
                      .set("price", price)
                      .set("pricePerProduct", price)
                      .set("title", "Bezorgkosten");
                  }
                }
              }
              return orderItem
                .set("pricePerProduct", `${orderItem.get("pricePerProduct", 0.0).toFixed(2)}`.replace(".", ","))
                .set("title", "Bezorgkosten");
            }

            return orderItem.set(
              "pricePerProduct",
              `${orderItem.get("pricePerProduct", 0.0).toFixed(2)}`.replace(".", ",")
            );
          })
      );
    }

    if (!order.getIn(["card", "text"])) {
      return order.set("orderNumber", orderNumber).toJS();
    }

    let text = order.getIn(["card", "text"]);

    if (order.getIn(["card", "left"])) {
      text = `Lint 1: ${order.getIn(["card", "left"])}`;
    }
    if (order.getIn(["card", "right"])) {
      text += `\nLint 2: ${order.getIn(["card", "right"])}`;
    }

    return order
      .set("orderNumber", orderNumber)
      .setIn(["card", "text"], `Graag een bijpassend "${order.getIn(["card", "title"])}" er aan. \n${text}`)
      .toJS();
  }
);

export const validate = data => {
  const errors = {};

  if (!data) {
    return errors;
  }

  if (!data.floristId) {
    errors.floristId = "Dit veld is vereist";
  }

  if (
    data.recipient &&
    data.recipient.address &&
    data.recipient.address.countryCode &&
    data.recipient.address.countryCode === "NL" &&
    data.recipient.address.zipcode &&
    !/^[1-9][0-9]{3}\s?[A-Z]{2}$/i.test(data.recipient.address.zipcode)
  ) {
    errors.recipient = { address: { zipcode: "Dit is geen geldig nederlands postcode" } };
  }

  if (data.orderItems) {
    errors.orderItems = [];
    data.orderItems.map((item, idx) => {
      if (item.pricePerProduct && !/^[\d]+([,.][\d]+)?$/.test(item.pricePerProduct)) {
        errors.orderItems[idx] = {};
        errors.orderItems[idx].pricePerProduct = "Geen getal";
      }
    });
  }

  // ^[\d]+[,\.][\d]+$

  return errors;
};

const floristSelector = createSelector(
  [(florists, form) => florists, (florists, form) => form && form.values && form.values.floristId],
  (florists, floristId) => {
    if (florists && florists.size && floristId) {
      return florists.find(item => item.get("id") === floristId);
    }
    return null;
  }
);

const formSelector = formValueSelector("sendToColleague");

export default connect(
  (state, props) => {
    const order = state.order.get("order");
    return {
      order: order.get("data"),
      florists: order.get("florists").get("data"),
      isFetching: order.get("isFetching") || order.get("florists").get("isFetching"),
      initialValues: initialValuesSelector(order, state.form.sendToColleague),
      florist: floristSelector(order.getIn(["florists", "data"]), state.form.sendToColleague),
      orderItems: formSelector(state, "orderItems")
    };
  },
  {
    fetchFlorists,
    sendToFlorist
  }
)(
  reduxForm({
    form: "sendToColleague",
    enableReinitialize: true,
    validate: validate,
    warn: values => {
      const warnings = {};
      if (!values) {
        return warnings;
      }

      return warnings;
    }
  })(SendToColleague)
);

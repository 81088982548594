import React from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";

import classes from "./Paper.module.scss";

export const Paper = ({ title, children, className, name, style }) => (
  <Element className={`${classes.container} ${className}`} name={name} style={style}>
    {title ? <div className={classes.title}>{title}</div> : null}
    {children}
  </Element>
);

Paper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object
};

export default Paper;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formPropTypes, reduxForm, SubmissionError, Field } from "redux-form";
import { toastr } from "react-redux-toastr";
import ReactTooltip from "react-tooltip";

import Button from "components/Button";
import { setEditing, updateOrder } from "../modules/Order";
// import { createSelector } from 'reselect';

export class EditableBlock extends React.Component {
  static propTypes = {
    ...formPropTypes,
    titleClassName: PropTypes.string,
    editIcon: PropTypes.string,
    contentClassName: PropTypes.string
  };

  static defaultProps = {
    titleClassName: "",
    editIcon: "pencil",
    contentClassName: ""
  };

  static childContextTypes = {
    isEditing: PropTypes.bool.isRequired
  };

  getChildContext() {
    return {
      isEditing: this.props.isEditing
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isEditing === false && this.props.isEditing) {
      ReactTooltip.rebuild();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = e => {
    if (e.keyCode === 27) {
      // Esc
      this.onCancel();
      e.preventDefault();
    }
  };

  onSubmit = data => {
    data.form = this.props.form;
    // console.log('onSubmit', data);
    return this.props
      .updateOrder(data)
      .catch(err => {
        toastr.error(err.message);

        // console.error(err);
        throw new SubmissionError({
          _error: err.message
        });
      })
      .then(() => {
        this.onCancel();
      });
  };

  onCancel = () => {
    ReactTooltip.hide();
    this.props.reset();
    this.props.setEditing(this.props.form, false);
  };

  onEdit = () => {
    this.props.setEditing(this.props.form, true);
  };

  render() {
    const isNew = true; // this.props.isNew;
    const isEditing = isNew && this.props.isEditing;
    const { editIcon } = this.props;
    return (
      <div className={`EditableBlock editable editable-inline${isEditing ? " is-editing" : ""}`}>
        <h3 className={this.props.className}>
          {this.props.title}
          {isEditing ? (
            <span className="silver ml-5 small">(Sluit met Escape)</span>
          ) : isNew ? (
            <i className={`edit-icon fa fa-${editIcon}`} onClick={this.onEdit} />
          ) : null}
        </h3>
        <Field component="input" type="hidden" name="id" />
        {this.props.children}
        {isEditing ? (
          <div className="editable-buttons">
            <Button
              disabled={this.props.pristine || this.props.submitting}
              onClick={this.props.handleSubmit(this.onSubmit)}
            >
              {this.props.error ? <i className="fa fa-exclamation-circle" title={this.props.error} /> : null}
              {this.props.submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : null} Opslaan
            </Button>
            <Button onClick={this.onCancel}>Annuleren</Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    isEditing: state.order.getIn(["order", "editing", props.form], false)
  }),
  {
    updateOrder,
    setEditing
  }
)(
  reduxForm({
    enableReinitialize: true,
    validate: data => {
      const errors = {};

      if (
        data.card &&
        data.card.text &&
        data.card.text.replace &&
        data.card.text.replace(/[^\n]+/gim, "").length >= 10
      ) {
        errors.card = {};
        errors.card.text = "Bevat meer dan 10 regels";
      }

      return errors;
    }
  })(EditableBlock)
);

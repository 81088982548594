import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";

import classes from "./Button.module.scss";

class Button extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    large: PropTypes.bool,
    fullWidth: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onClick: PropTypes.func
  };

  static defaultProps = {
    type: "button",
    large: false,
    fullWidth: false,
    children: null,
    className: null,
    linkTo: null,
    onClick: null
  };

  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    if (this.props.linkTo) {
      browserHistory.push(this.props.linkTo);
    }
  };

  render() {
    const { fullWidth, linkTo, onClick, className, large, ...rest } = this.props;

    const classNames = [classes.button];
    if (large) {
      classNames.push(classes.large);
    }
    if (className) {
      classNames.push(className);
    }

    return (
      <button onClick={this.onClick} className={classNames.join(" ")} {...rest}>
        {this.props.children}
      </button>
    );
  }
}

export default Button;

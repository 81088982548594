import LoginView from "./containers/Login";
import LogoutView from "./containers/Logout";
import ResetContainer from "./containers/Reset";
// import moment from 'lib/moment';
// import { store } from 'store/createStore';
// import { refreshToken } from './modules/Auth';

/**
 *
 * @param auth
 *
 * @returns {boolean}
 */
export function isAuthExpired(auth) {
  if (!auth || !auth.get("token")) {
    return true;
  }
  // if (moment().isAfter(moment(auth.get('expires')).subtract(5, 'minutes'))) {
  //   store.dispatch(refreshToken(auth.get('token')));
  // }
  return false;
}

/**
 *
 * @param store
 *
 * @returns {Function}
 */
function applyAuth(store) {
  return function(nextState, replace) {
    const state = store.getState();
    if (!isAuthExpired(state.auth)) {
      if (__DEV__) {
        console.info("auth is NOT required");
      }
      // replace({
      //   pathname: nextState.location.pathname
      // });
      return;
    }
    if (__DEV__) {
      console.info("auth IS required");
    }
    replace({
      pathname: "/auth/login",
      state: { nextPathname: nextState.location.pathname }
    });
  };
}

/**
 *
 * @param store
 *
 * @returns {Function}
 */
function checkNeedsAuth(store) {
  return function(nextState, replace) {
    const state = store.getState();
    if (isAuthExpired(state.auth)) {
      return;
    }
    if (__DEV__) {
      console.log("redirecting to /order/t[unprocessed]");
    }
    replace({
      pathname: "/order/t[unprocessed]"
    });
  };
}

/**
 * Encapsulate an auth-required route
 *
 * @param store
 * @param Route
 *
 * @returns {*}
 *
 * @constructor
 */
export function RequireAuthRoute(store, Route) {
  Route.onEnter = applyAuth(store);
  Route.onChange = applyAuth(store);
  return Route;
}

/**
 * @param store
 */
export default store => ({
  path: "auth",
  childRoutes: [
    {
      path: "login",
      component: LoginView,
      onEnter: checkNeedsAuth(store)
    },
    {
      path: "logout",
      component: LogoutView
    },
    {
      path: "reset",
      component: ResetContainer
    }
  ]
});

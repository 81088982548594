import React, { Component } from "react";
import PropTypes from "prop-types";

import ProgressButton from "./ProgressButton";

class ProgressButtonWithStatus extends Component {
  static propTypes = {
    status: PropTypes.node,
    showStatusOnly: PropTypes.bool
  };

  static defaultProps = {
    status: null,
    showStatusOnly: false
  };

  render() {
    const { status, showStatusOnly, ...props } = this.props;
    return (
      <div className="flex layout-column">
        {showStatusOnly && (
          <div className="layout-column margin-bottom-5">
            <ProgressButton {...props} />
          </div>
        )}
        <div className="layout-column layout-align-center-center margin-bottom-5">{status}</div>
      </div>
    );
  }
}

export default ProgressButtonWithStatus;

import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datetime";
import moment from "lib/moment";

export const DatePickerWrapper = ({ field, format, isValidDate }) => {
  const inputFormat = format || "YYYY-MM-DD";
  const displayFormat = format || "DD-MM-YYYY";

  const { input, ...rest } = field; /*, meta, schema, */

  const onChange = value => {
    input.onChange(moment.isMoment(value) ? value.format(inputFormat) : value);
  };

  let { value } = input;
  if (value !== moment(value).format(inputFormat)) {
    value = null;
  }

  return (
    <div>
      <DatePicker
        input
        closeOnSelect
        locale="nl"
        isValidDate={isValidDate}
        inputProps={{ placeholder: rest.placeholder }}
        dateFormat={displayFormat}
        timeFormat={false}
        value={value && !moment.isMoment(value) ? moment(value) : value}
        onChange={onChange}
      />
      <input {...input} type="hidden" />
    </div>
  );
};

DatePickerWrapper.propTypes = {
  field: PropTypes.object.isRequired,
  format: PropTypes.string,
  isValidDate: PropTypes.func
};

export default DatePickerWrapper;

import PropTypes from "prop-types";
import React from "react";

export default class ButtonGroup extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    label: PropTypes.bool
  };

  render() {
    return (
      <div className="form-group row">
        <div className="col-xs-12">
          {this.props.label ? (
            <div className={this.props.label ? "offset-sm-4 col-sm-offset-4 col-sm-8 col-xs-12" : "col-xs-12"}>
              {this.props.children}
            </div>
          ) : (
            this.props.children
          )}
        </div>
      </div>
    );
  }
}

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";
import Button from "components/Button";
import { Input, SubmissionError } from "components/Form";
import { SmallLoader } from "components/Loader";

import { toastr } from "react-redux-toastr";
import { sendToPostNL } from "../../modules/Order";

export class SendToPostNL extends React.Component {
  onSubmit = data =>
    // validated data
    this.props
      .sendToPostNL(this.props.orderId, data)
      .then(() => {
        this.props.doClose(`Order ${this.props.orderId} is bij PostNL aangemeld`);
        this.props.doAddToPrintQueue("postnl");
      })
      .catch(err => {
        toastr.error("Kan niet bij PostNL aanmelden!", err.message);
        console.log(err);
        throw new SubmissionError({ _error: err.message });
      });

  render() {
    return (
      <div>
        <h2>Meld aan bij PostNL</h2>
        <form role="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Input type="hidden" name="orderNumber" />

          <Input label="Lengte" name="dimension.Length" type="number" />
          <Input label="Breedte" name="dimension.Width" type="number" />
          <Input label="Hoogte" name="dimension.Height" type="number" />
          <Input label="Gewicht" name="dimension.Weight" type="number" />
          <Input label="Volume" name="dimension.Volume" type="number" />
          <Input label="Opmerking" name="remark" />

          <div className="offset-sm-3" style={{ textAlign: "center" }}>
            <Button type="submit" className="col-sm-8" disabled={this.props.invalid || this.props.submitting}>
              {this.props.submitting ? <SmallLoader /> : ""}
              {!this.props.submitting && this.props.error ? (
                <i className="fa fa-exclamation-circle" title={this.props.error}>
                  &nbsp;
                </i>
              ) : (
                ""
              )}
              Meld aan bij PostNL
            </Button>
          </div>

          {this.props.error ? (
            <div className="offset-sm-3">
              <br />
              <div className="alert alert-danger layout-row">
                <div>
                  <i className="fa fa-exclamation-circle" data-tip={this.props.error} />
                </div>
                <div>
                  <strong>Er is een fout opgetreden</strong>
                  <br />
                  {this.props.error}
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
SendToPostNL.propTypes = {
  ...reduxFormPropTypes,
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object, // immutable.map
  sendToPostNL: PropTypes.func,
  doAddToPrintQueue: PropTypes.func,
  isFetching: PropTypes.bool
};

const initialValuesSelector = createSelector(
  [state => state],
  order => ({
    dimension: {
      Length: 1,
      Width: 1,
      Height: 1,
      Weight: 1,
      Volume: 1
    },
    remark: `Order: ${order.get("id")}`
  })
);

export default connect(
  (state, props) => {
    const order = state.order.get("order");
    return {
      isFetching: order.get("isFetching"),
      initialValues: initialValuesSelector(order.get("data"))
    };
  },
  {
    sendToPostNL
  }
)(
  reduxForm({
    form: "SendToPostNL",
    enableReinitialize: true,
    validate: data => {
      const errors = {};
      //
      // if (!data.floristId) {
      //   errors.floristId = 'Dit veld is vereist';
      // }
      return errors;
    },
    warn: values => {
      const warnings = {};

      // if (values.card && values.card.text) {
      //   warnings.card = {};
      //   warnings.card.text = 'Hmm, you seem a bit young...';
      // }

      return warnings;
    }
  })(SendToPostNL)
);

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { propTypes as ReduxFormPropTypes } from "redux-form";
import { Input } from "components/Form";
import Button from "components/Button";
import classes from "./LoginReset.module.scss";

// De instructies zijn succesvol verstuurd per e-mail. Deze ontvangt u binnen enkele minuten.
export class LoginReset extends React.Component {
  static propTypes = {
    ...ReduxFormPropTypes,
    requestReset: PropTypes.func.isRequired,
    isReset: PropTypes.bool.isRequired,
    error: PropTypes.string
  };

  handleSubmit = data => this.props.requestReset(data.login);

  renderError() {
    if (!this.props.authError) {
      return null;
    }
    return (
      <div className={`row ${classes.error}`}>
        <div className="col-xs-12">
          <div className="alert alert-danger">{this.props.authError}</div>
        </div>
      </div>
    );
  }

  renderFeedback() {
    if (!this.props.isReset) {
      return null;
    }
    return (
      <div className={`row ${classes.error}`}>
        <div className="col-xs-12">
          <div className="alert alert-info">Één mail met verdere instructies is verstuurd.</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className={classes.icon}>
          <img src="/img/boeketcadeau2014/sprite/logo.png" />
        </div>

        <div className={`layout-column ${classes.container}`}>
          <Input type="text" name="login" placeholder="Gebruikersnaam" />
          <Button
            onClick={this.props.handleSubmit(this.handleSubmit)}
            style={{ padding: "10px" }}
            disabled={this.props.pristine || this.props.submitting}
          >
            {this.props.submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : "Wachtwoord resetten"}
          </Button>
          {this.renderError()}
          {this.renderFeedback()}
        </div>

        <div className={`layout-column ${classes.centered}`} style={{ fontSize: "22px" }}>
          <Link to="/">
            <i className="fa fa-home" />
          </Link>
        </div>
      </div>
    );
  }
}

export default LoginReset;

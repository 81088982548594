import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, SubmissionError, propTypes } from "redux-form";
import md5 from "blueimp-md5";

import { TextArea } from "../Form";
import Button from "../Button/Button";
import { printEfCard } from "../../routes/Order/modules/Order";
import Alert from "../Alert";
import { SmallLoader } from "../Loader";
import { browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import { confirm } from "../../routes/Order/components/OrderDetail";

class PrintCard extends Component {
  static propTypes = {
    ...propTypes,
    printEfCard: PropTypes.func.isRequired,
    onAfterSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {};

  onSubmit = data => {
    if (!data.printer) {
      confirm({
        title: "Configuratie fout",
        message: (
          <div>
            Er is geen of een onjuist printer geconfigureerd.
            <br />
            Wil je naar de locatie configuratie?
          </div>
        ),
        onOk: () => browserHistory.push("/admin/location")
      });
      return Promise.reject(new Error("Configuration error"));
    }
    return this.props
      .printEfCard({ ...data, id: md5(data.text) })
      .then(res => {
        if (data.printer === "_popup") {
          window.open(
            `/print/${res.id}/${data.type}/${data.printer}`,
            `${data.type}print2`,
            "width=500,height=500,scrollbars=yes,toolbar=no"
          );
          this.props.onAfterSubmit();
          return Promise.resolve(true);
        }
        toastr.success("Kaartje is in de printer wachtrij");
        this.props.onAfterSubmit();
      })
      .catch(err => {
        throw new SubmissionError({
          _error: err.message
        });
      });
  };

  render() {
    const { pristine, submitting, error } = this.props;

    const invalid = this.props.invalid && !error;

    return (
      <div>
        <TextArea name="text" placeholder="Kaart tekst (max 250 chars op 10 regels)" autoFocus />
        <div>
          <Button disabled={pristine || invalid || submitting} onClick={this.props.handleSubmit(this.onSubmit)}>
            {submitting && <SmallLoader />}
            Afdrukken
          </Button>
        </div>
        {error && (
          <Alert type="danger" className="mt-4">
            {error}
          </Alert>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    initialValues: {
      type: "card2",
      printer: state.app.getIn(["config", "printers", "card2"]),
      text: ""
    }
  }),
  {
    printEfCard
  }
)(
  reduxForm({
    form: "printEfCard",
    validate: data => {
      const errors = {};
      if (data.text && data.text) {
        if (data.text.length >= 250) {
          errors.text = "Bevat meer dan 250 karakters";
        }
        if (data.text.replace && data.text.replace(/[^\n]+/gim, "").length >= 10) {
          errors.text = "Bevat meer dan 10 regels";
        }
      }
      return errors;
    }
  })(PrintCard)
);

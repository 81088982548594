import { injectReducer } from "../../store/reducers";

import RegisterArbitraryShipmentContainer from "./RegisterArbitraryShipmentContainer";

export default store => {
  injectReducer(store, { key: "shipment", reducer: require("./redux").default });
  injectReducer(store, { key: "order", reducer: require("../Order/modules/Order").default });
  return {
    path: "shipment",
    childRoutes: [
      {
        path: ":transporter",
        component: RegisterArbitraryShipmentContainer
      }
    ]
  };
};

export const round = (number, precision) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

// myNamespace.round(1234.5678, 1); // 1234.6
// myNamespace.round(1234.5678, -1); // 1230

export const price = (number, currency) => {
  if (!number || isNaN(number)) {
    number = 0;
  }

  currency = currency === false ? "" : currency;

  if (currency !== "") {
    currency = currency || "€";
    currency += " ";
  }
  return currency + number.toFixed(2).replace(/\./g, ",");
};

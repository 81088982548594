import PropTypes from "prop-types";
import React from "react";
import Loader from "components/Loader";

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const printIt = () => {
  if (isIE11) {
    const pdfObject = document.getElementById("pdfObject");
    if (pdfObject) {
      window.setTimeout(() => {
        pdfObject.print();

        window.setTimeout(() => {
          window.onfocus = function() {
            window.close();
          };
          window.focus();
        }, 250);
      }, 750);
    } else {
      window.setTimeout(() => {
        window.print();

        window.setTimeout(() => {
          window.onfocus = function() {
            window.close();
          };
          window.focus();
        }, 250);
      }, 750);
    }
    return;
  }
  window.setTimeout(() => {
    const iframe = document.getElementById("prntModalIframe");
    const { contentWindow } = iframe;
    contentWindow.print();
    // contentWindow.setTimeout(() => {
    //   contentWindow.onfocus = function() {
    //     window.close();
    //   };
    //   contentWindow.focus();
    // }, 250);
  }, 750);
};

export class PrintModalContainer extends React.Component {
  state = {
    error: null,
    isFetching: true
  };

  getUri() {
    const { id, jobType, printerId } = this.props.params;
    return `/gateway/api/workbench/order/${id}/print/?type=${jobType}&printerId=${printerId}&file=.pdf`;
  }

  componentDidMount() {
    this.setState({ isFetching: true });
    const img = document.getElementById("prntModalIframe");
    if (!img) {
      const pdfObject = document.getElementById("pdfObject");
      pdfObject.onload = () => {
        this.setState({ isFetching: false });
      };
      pdfObject.addEventListener("load", function(/*e*/) {
        this.setState({ isFetching: false });
      });
      this.setState({ isFetching: false });
      return;
    }

    img.src = this.getUri();
    if (__DEV__) {
      console.log(img.src);
    }

    img.onload = () => {
      this.setState({ isFetching: false });
    };
    img.onerror = () => {
      this.setState({ isFetching: false, error: "Fout" });
    };
    img.onloadend = () => {
      this.setState({ isFetching: false });
    };

    img.src = this.getUri();
  }

  componentDidUpdate() {
    if (!this.state.isFetching && !this.state.error) {
      window.setTimeout(() => {
        printIt();
      }, 250);
    }
  }

  renderPdf = () => {
    const url = this.getUri();
    if (!isIE11 || this.props.params.jobType === "order") {
      return (
        <iframe
          title="prntModalIframe"
          id="prntModalIframe"
          width="100%"
          height="100%"
          style={this.state.isFetching ? { display: "none" } : {}}
          frameBorder="0"
        />
      );
    }
    return <embed src={url} type="application/pdf" style={{ width: "100%", height: "100%" }} id="pdfObject" />;
  };

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={!this.state.isFetching ? { display: "none" } : {}} className="centerized">
          {this.state.isFetching ? (
            <Loader />
          ) : this.state.error ? (
            <div className="alert alert-danger"> {this.state.error.message}</div>
          ) : null}
        </div>
        {this.renderPdf()}
      </div>
    );
  }
}

PrintModalContainer.propTypes = {
  params: PropTypes.object
};

export default PrintModalContainer;

import PropTypes from "prop-types";
import React from "react";
import FeatureBar from "components/FeatureBar";

export const ModuleView = ({ title, children, className }) => (
  <div className={`layout-fill layout-align-start-center layout-column ${className || ""}`}>
    <div className="layout-fill">
      {title ? <FeatureBar title={title} /> : null}
      <div className="module-canvas layout-column">{children}</div>
    </div>
  </div>
);

ModuleView.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};

export default ModuleView;

import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ucfirst from "locutus/php/strings/ucfirst";
import isEqual from "lodash/isEqual";
import { hot } from "react-hot-loader";

import { toggleDashboardWidget } from "../../modules/Admin";
import Paper from "../../../../components/Paper";
import Graph from "./Graph";

import "./Widget.scss";
import Spinner from "../../../../components/Loader/Spinner";

// Chart.defaults.global.responsive = true;

const Loader = () => <Spinner color="gray" style={{ maxWidth: 30 }} />;

class Widget extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(["graph"]).isRequired,
    toggleDashboardWidget: PropTypes.func.isRequired,
    hasFetched: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    style: PropTypes.object,
    data: PropTypes.object,
    config: PropTypes.object
  };

  state = {
    showMenu: false
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  shouldComponentUpdate2(props, state) {
    if (!this.props.hasFetched || !this.props.data) {
      return true;
    }
    return !this.props.data.equals(props.data) || !isEqual(this.state, state);
  }

  componentDidMount = () => {
    window.addEventListener("click", this.onClickOutside);
  };

  componentWillUnmount = () => {
    window.removeEventListener("click", this.onClickOutside);
  };

  onClickOutside = e => {
    if (!e.target.classList.contains("MenuButton") && !e.target.parentNode.classList.contains("MenuButton")) {
      this.setState({ showMenu: false });
    }
  };

  getTitle = key => {
    if (key === "florist") {
      return "Collega bloemist";
    }
    if (key === "postnl") {
      return "Post NL";
    }
    return ucfirst(key);
  };

  onToggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };

  onHideWidget = () => {
    this.props.toggleDashboardWidget(this.props.id);
  };

  renderWrapper(children) {
    return (
      <Paper className="statistic-paper" style={this.props.style} ref={this.containerRef}>
        <div className="Widget__Toolbar">
          <h3 className="Widget__Title">{this.getTitle(this.props.title)}</h3>
          <div className="Widget__Control">
            <button className="MenuButton" onClick={this.onToggleMenu}>
              <i className="fa fa-cog" />
              {this.state.showMenu && (
                <div className="MenuButton__Menu">
                  <div className="MenuButton__Menu__MenuItem" onClick={this.onHideWidget}>
                    <div className="Icon">
                      <i className="fa fa-eye-slash mr-3" />
                    </div>
                    <div>Verbergen</div>
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>
        {children}
      </Paper>
    );
  }

  render() {
    if (this.state.hidden) {
      return null;
    }

    const { hasFetched, data, type, config } = this.props;

    const style = { minWidth: config.width, height: config.height, display: "inline-block", color: "gray" };
    if (!hasFetched || !data) {
      return this.renderWrapper(
        <div style={{ minWidth: config.width, height: config.height, display: "inline-block" }}>
          <Loader />
        </div>
      );
    }

    if (data.get("data").size === 0) {
      return this.renderWrapper(<div style={style}>Geen data</div>);
    }

    if (type === "graph") {
      const graphProps = {
        id: this.props.id,
        data: data.set("color", config.color),
        width: config.width,
        height: config.height,
        type: config.type || "line",
        options: config.options
      };

      if (this.containerRef && this.containerRef.current) {
        graphProps.height = this.containerRef.current.offsetHeight - 65;
        graphProps.width = this.containerRef.current.offsetWidth - 30;
      }

      return this.renderWrapper(<Graph {...graphProps} />);
    }

    return this.renderWrapper();
  }
}

export default hot(module)(
  connect(
    (state, props) => ({
      hasFetched: state.admin.getIn(["stats", props.id, "hasFetched"], false),
      data: state.admin.getIn(["stats", props.id, "data"])
    }),
    {
      toggleDashboardWidget
    }
  )(Widget)
);

import Immutable from "immutable";
import { getParams } from "../../lib/RouteUtil";

// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = "@app/route/LOCATION_CHANGE";

// ------------------------------------
// Actions
// ------------------------------------
export function locationChange(nextLocation) {
  return {
    type: LOCATION_CHANGE,
    payload: nextLocation
  };
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch, getState }) => nextLocation => {
  const state = getState();

  nextLocation.prevLocation = state.location.delete("prevLocation").toJS();
  return dispatch(locationChange(nextLocation));
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [LOCATION_CHANGE]: (state, { payload }) => {
    payload.params = getParams(payload.pathname);
    return Immutable.fromJS(payload);
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = Immutable.Map({
  params: Immutable.Map(),
  prevLocation: Immutable.Map(),
  pathname: ""
});

// ------------------------------------
// Reducer
// ------------------------------------
export default function locationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

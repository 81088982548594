import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";

import moment from "../../../../../lib/moment";
import Loader from "../../../../../components/Loader";
import { deleteLogEntry } from "../../../modules/Order";

import classes from "./InternalLogEntry.module.scss";

class InternalLogEntry extends Component {
  static propTypes = {
    orderId: PropTypes.number.isRequired,
    entry: PropTypes.instanceOf(Immutable.Map),
    deleteLogEntry: PropTypes.func.isRequired,
    deletable: PropTypes.bool
  };

  static defaultProps = {
    deletable: true,
    entry: null
  };

  onClickDeleteEntry = () => {
    this.props.deleteLogEntry(this.props.orderId, this.props.entry.get("id"));
  };

  render() {
    const { entry } = this.props;
    if (!entry) {
      return null;
    }

    const entryMoment = moment(entry.get("createDatetime"));
    const startOfDay = moment().set("hour", 0);
    const isSameYear = startOfDay.year() === entryMoment.year();

    let format = `dddd, D MMM ${isSameYear ? "" : "YYYY,"} HH:mm:ss`;
    if (entryMoment.isAfter(startOfDay)) {
      format = "HH:mm:ss";
    }

    const isDeleting = this.props.entry.get("isDeleting", false);

    return (
      <div key={entry.get("id")} className={classes.InternalLogEntry}>
        <div className={classes.InternalLogEntry__Meta}>
          <div className={classes.InternalLogEntry__Meta__TitleContainer}>
            <div className={classes.InternalLogEntry__Meta__DateTime}>{entryMoment.format(format)}</div>
            <div className="ml-2 mr-3">door</div>
            <div className={classes.InternalLogEntry__Meta__User}>{entry.get("createUserName")}</div>
          </div>
          <div>
            {isDeleting && <Loader spinnerSize={16} />}
            {this.props.deletable && !isDeleting && (
              <i className="fa fa-times mr-1 mt-1" onClick={this.onClickDeleteEntry} role="button" tabIndex="-1" />
            )}
          </div>
        </div>
        <div className={classes.InternalLogEntry__Content}>{entry.get("content")}</div>
      </div>
    );
  }
}

export default connect(
  () => ({}),
  {
    deleteLogEntry
  }
)(InternalLogEntry);

import Immutable, { ListAndOrderedMapReviver } from "lib/Immutable";
import api from "lib/Api";

// ------------------------------------
// Actions
// ------------------------------------

export const ORDER_UNDELETE = "@app/admin/ORDER_UNDELETE";
export const ORDER_UNDELETE_RECEIVE = "@app/admin/ORDER_UNDELETE_RECEIVE";
export const ORDER_UNDELETE_ERROR = "@app/admin/ORDER_UNDELETE_ERROR";

export const CLEAR_ORDER_UNDELETE = "@app/admin/CLEAR_ORDER_UNDELETE";

export const STATS_FETCH_ALL = "@app/admin/STATS_FETCH_ALL";
export const STATS_FETCH = "@app/admin/STATS_FETCH";
export const STATS_RECEIVE = "@app/admin/STATS_RECEIVE";
export const STATS_FETCH_ERROR = "@app/admin/STATS_FETCH_ERROR";

export const HYDRATE_STATISTICS = "@app/admin/HYDRATE_STATISTICS";
export const HYDRATE_STATISTIC = "@app/admin/HYDRATE_STATISTIC";

// ------------------------------------
// Action Generators
// ------------------------------------

export const undeleteOrder = id => dispatch => {
  dispatch({ type: ORDER_UNDELETE, payload: id });
  return api(`/workbench/order/${id}/undelete`)
    .create()
    .then(data => {
      dispatch({ type: ORDER_UNDELETE_RECEIVE, payload: data });
    })
    .catch(err => {
      dispatch({ type: ORDER_UNDELETE_ERROR, payload: err });
      throw err;
    });
};

export const clearUndeleteOrder = () => ({ type: CLEAR_ORDER_UNDELETE });

export const hydrateStatistics = () => (dispatch, getState) => {
  const promises = getState()
    .app.features.map(feature => {
      if (feature.get("extraAttribute_28088960") === "transporter") {
        return dispatch(hydrateStatistic(`${feature.get("title")}/monthly`));
      }
      return null;
    })
    .filter(v => !!v)
    .concat(
      getState()
        .app.features.map(feature => {
          if (feature.get("extraAttribute_28088960") === "transporter") {
            return dispatch(hydrateStatistic(`${feature.get("title")}/daily`));
          }
          return null;
        })
        .filter(v => !!v)
    );

  promises.push(dispatch(hydrateStatistic("secondChance")));
  promises.push(dispatch(hydrateStatistic("paymentMethod/monthly")));
  promises.push(dispatch(hydrateStatistic("paymentMethod/daily")));
  promises.push(dispatch(hydrateStatistic("print/monthly")));
  promises.push(dispatch(hydrateStatistic("print/daily")));

  return dispatch({
    type: HYDRATE_STATISTICS,
    payload: Promise.all(promises)
  });
};

export const hydrateStatistic = id => ({
  type: HYDRATE_STATISTIC,
  statisticId: id,
  payload: api(`/workbench/stats/${id}`).find()
});

const TOGGLE_DASHBOARD_WIDGET = "@admin/dashboard/TOGGLE_WIDGET";
export const toggleDashboardWidget = id => (dispatch, getState) => {
  const isHidden = getState().admin.getIn(["dashboard", "widgets", id, "hidden"], false);
  return dispatch({
    type: TOGGLE_DASHBOARD_WIDGET,
    payload: {
      id,
      hidden: !isHidden
    }
  });
};

// ------------------------------------
// Initial State
// ------------------------------------
const State = new Immutable.Record({
  undelete: Immutable.Map({
    isProcessing: false,
    data: 0,
    error: ""
  }),
  isFetchingStats: false,
  hasFetchedStats: false,
  stats: Immutable.Map(),
  dashboard: Immutable.fromJS(JSON.parse(window.localStorage.getItem("admin.dashboard")) || {})
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [TOGGLE_DASHBOARD_WIDGET]: (state, { payload }) =>
    state.setIn(["dashboard", "widgets", payload.id, "hidden"], payload.hidden),
  [ORDER_UNDELETE]: (state, { payload }) =>
    state
      .setIn(["undelete", "isProcessing"], true)
      .setIn(["undelete", "data"], payload)
      .setIn(["undelete", "error"], ""),
  [ORDER_UNDELETE_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["undelete", "isProcessing"], false)
      .setIn(["undelete", "data"], payload) // is an id
      .setIn(["undelete", "error"], ""),
  [ORDER_UNDELETE_ERROR]: (state, { payload }) =>
    state.setIn(["undelete", "isProcessing"], false).setIn(["undelete", "error"], Immutable.fromJS(payload)),
  [HYDRATE_STATISTICS]: (state, { status, payload }) => {
    switch (status) {
      case "pending": {
        return state
          .setIn(["stats", "transporters_per_month", "isFetching"], true)
          .setIn(["stats", "transporters_per_day", "isFetching"], true);
      }
      case "success": {
        return state
          .setIn(["stats", "transporters_per_month", "isFetching"], false)
          .setIn(["stats", "transporters_per_month", "hasFetched"], true)
          .setIn(["stats", "transporters_per_month", "data", "name"], "Vervoerder uitzet p/m")
          .setIn(
            ["stats", "transporters_per_month", "data", "data"],
            Immutable.fromJS(
              payload.filter(i => i.type === "transporter" && i.period === "monthly"),
              ListAndOrderedMapReviver
            )
          )
          .setIn(["stats", "transporters_per_day", "isFetching"], false)
          .setIn(["stats", "transporters_per_day", "hasFetched"], true)
          .setIn(["stats", "transporters_per_day", "data", "name"], "Vervoerder uitzet p/d")
          .setIn(
            ["stats", "transporters_per_day", "data", "data"],
            Immutable.fromJS(
              payload.filter(i => i.type === "transporter" && i.period === "daily"),
              ListAndOrderedMapReviver
            )
          );
      }
      case "error": {
        return state
          .setIn(["stats", "transporters_per_month", "isFetching"], false)
          .setIn(["stats", "transporters_per_day", "isFetching"], false);
      }
      default:
        return state;
    }
  },
  [HYDRATE_STATISTIC]: (state, { status, statisticId, payload }) => {
    switch (status) {
      case "pending": {
        return state.setIn(["stats", statisticId, "isFetching"], true);
      }
      case "error": {
        return state.setIn(["stats", statisticId, "isFetching"], false).setIn(["stats", statisticId, "error"], payload);
      }
      case "success": {
        return state
          .setIn(["stats", statisticId, "isFetching"], false)
          .setIn(["stats", statisticId, "hasFetched"], true)
          .setIn(["stats", statisticId, "data"], Immutable.fromJS(payload, ListAndOrderedMapReviver));
      }
      default:
        return state;
    }
  },
  [STATS_RECEIVE]: (state, { payload }) =>
    state
      .setIn(["stats", payload.id, "isFetching"], false)
      .setIn(["stats", payload.id, "isLoaded"], true)
      .setIn(["stats", payload.id, "name"], payload.data.name)
      .setIn(["stats", payload.id, "data"], Immutable.fromJS(payload.data.data, ListAndOrderedMapReviver))
      .setIn(["stats", payload.id, "error"], ""),
  [STATS_FETCH_ERROR]: (state, { payload }) =>
    state
      .setIn(["stats", payload.id, "isFetching"], false)
      .setIn(["stats", payload.id, "error"], Immutable.fromJS(payload.err))
};

// ------------------------------------
// Selectors
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  const newState = handler ? handler(state, action) : state;
  if (action.type === TOGGLE_DASHBOARD_WIDGET) {
    window.localStorage.setItem("admin.dashboard", JSON.stringify(newState.get("dashboard").toJS()));
  }
  return newState;
}

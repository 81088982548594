import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import classes from "./Pagination.module.scss";
import { buildUrl, getParams } from "lib/RouteUtil";
import { find as findOrders, fetchTags } from "routes/Order/modules/Order";
import Immutable from "immutable";

export const Button = ({ disabled, children }) => (
  <button style={{ cursor: disabled ? "default" : "pointer" }} disabled={disabled}>
    {children}
  </button>
);
Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.any
};

export const buildLink = (page, orderId) => buildUrl({ page: page, id: orderId });

export const Pagination = ({ meta, objectId, findOrders, fetchTags, hasTotal }) => {
  const pageSize = meta.get("pageSize", 0) ? Math.ceil(meta.get("total", 0) / meta.get("pageSize", 0)) : 0;

  const onRefresh = () => {
    const params = getParams();
    findOrders(Immutable.fromJS(params.tags), params.page);
    fetchTags();
  };

  // console.log(meta.get('page'));

  return (
    <div className={`layout-row ${classes.pagination}`}>
      <div className={"layout-row "}>
        <Button disabled={meta.get("prevPage") > 0}>
          {meta.get("prevPage") ? (
            <Link to={buildLink(1, objectId)}>
              <i className="fa fa-step-backward" />
            </Link>
          ) : (
            <i className="fa fa-step-backward" />
          )}
        </Button>

        <Button disabled={meta.get("prevPage") > 0}>
          {meta.get("prevPage") ? (
            <Link to={buildLink(meta.get("prevPage"), objectId)}>
              <i className="fa fa-chevron-left" />
            </Link>
          ) : (
            <i className="fa fa-chevron-left" />
          )}
        </Button>

        <span>
          Pagina {meta.get("page", 0)} van {pageSize}
        </span>

        <Button disabled={meta.get("nextPage") > 0}>
          {meta.get("nextPage") ? (
            <Link to={buildLink(meta.get("nextPage"), objectId)}>
              <i className="fa fa-chevron-right" />
            </Link>
          ) : (
            <i className="fa fa-chevron-right" />
          )}
        </Button>

        <Button disabled={meta.get("nextPage") > 0}>
          {meta.get("nextPage") ? (
            <Link to={buildLink(pageSize, objectId)}>
              <i className="fa fa-step-forward" />
            </Link>
          ) : (
            <i className="fa fa-step-forward" />
          )}
        </Button>

        <Button disabled={meta.get("total") > 0}>
          {meta.get("total") ? (
            <Link onClick={onRefresh}>
              <i className="fa fa-refresh" />
            </Link>
          ) : (
            <i className="fa fa-refresh" />
          )}
        </Button>
      </div>

      <div className={"layout-row"}>
        {hasTotal ? (
          <div className={"layout-row"}>
            {meta.get("page") ? (
              <span>
                Getoond {(meta.get("total") ? 1 : 0) + meta.get("page") * meta.get("pageSize") - meta.get("pageSize")} -{" "}
                {meta.get("page") * meta.get("pageSize") > meta.get("total")
                  ? meta.get("total")
                  : meta.get("page") * meta.get("pageSize")}{" "}
                van {meta.get("total")}
              </span>
            ) : (
              "Geen gegevens om weer te geven"
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
Pagination.propTypes = {
  hasTotal: PropTypes.bool,
  findOrders: PropTypes.func,
  fetchTags: PropTypes.func,
  meta: PropTypes.object,
  objectId: PropTypes.number
};

export default connect(
  () => ({}),
  {
    findOrders,
    fetchTags
  }
)(Pagination);

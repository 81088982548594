import React from "react";
import Loader from "components/Loader";
import classes from "./LogoutView.module.scss";

export class LogoutView extends React.Component {
  render() {
    return (
      <div className={`${classes.container} layout-column layout-align-center-center`}>
        <h2>U word uitgelogd</h2>
        <div>
          <Loader />
        </div>
      </div>
    );
  }
}

export default LogoutView;

import PropTypes from "prop-types";
import React from "react";
import Immutable from "immutable";

import DataList from "../DataList";
import Pagination from "./components/Pagination";
import Loader from "~/components/Loader";

const EMPTY_MAP = new Immutable.Map();

const PaginatedDataList = props => {
  const isFetching = props.data.get("isFetching");
  return (
    <div className="flex layout-column">
      <div className="layout-column align-content-stretch">
        {isFetching ? (
          <Loader className="padding-20" text="Even geduld a.u.b. Gegevens worden geladen..." />
        ) : (
          <DataList {...props} />
        )}
      </div>
      <div className="layout-column">
        <Pagination
          activeObjectId={props.activeObjectId}
          isFetching={isFetching}
          hasTotal={props.hasTotal}
          meta={isFetching ? EMPTY_MAP : props.data.getIn(["items", "meta"], EMPTY_MAP)}
        />
      </div>
    </div>
  );
};

PaginatedDataList.propTypes = {
  hasTotal: PropTypes.bool,
  data: PropTypes.any,
  activeObjectId: PropTypes.any
};

export default PaginatedDataList;

const __DEV__ = process.env.NODE_ENV === "development";

// https://github.com/websockets/ws#expressjs-example

function WebSocketClient(url) {
  this.autoReconnectInterval = 5 * 1000; // ms
  // url = 'ws://localhost.kingsquare.eu:5000/test';
  this.open(url);
}

WebSocketClient.prototype.open = function(url) {
  this.url = url;
  this.instance = new WebSocket(this.url);

  this.instance.onopen = e => {
    console.info("WebSocket: opened", url);
    this.onopen && this.onopen(e);
  };

  this.instance.onmessage = e => {
    // console.info('WebSocket: onmessage', e);
    this.onmessage && this.onmessage(e);
  };

  this.instance.onclose = e => {
    switch (e) {
      case 1000: // CLOSE_NORMAL
        console.info("WebSocket: closed");
        break;
      default:
        // Abnormal closure
        this.reconnect(e);
        break;
    }
    this.onclose && this.onclose(e);
  };

  this.instance.onerror = e => {
    if (__DEV__) {
      console.error(e);
    }
    switch (e.code) {
      case "ECONNREFUSED":
        this.reconnect(e);
        break;
      default:
        this.onerror && this.onerror(e);
        break;
    }
  };
};

WebSocketClient.prototype.send = function(data, option) {
  try {
    this.instance.send(data, option);
  } catch (e) {
    this.instance.emit("error", e);
  }
};

WebSocketClient.prototype.reconnect = function(e) {
  if (__DEV__) {
    console.log(`WebSocketClient: retry in ${this.autoReconnectInterval}ms`, e);
  }
  this.instance && this.instance.removeAllListeners && this.instance.removeAllListeners();
  const that = this;
  setTimeout(() => {
    console.info("WebSocketClient: reconnecting...");
    that.open(that.url);
  }, this.autoReconnectInterval);
};

export default WebSocketClient;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setAttributeValue } from "~/routes/Order/modules/Order";
import { toastr } from "react-redux-toastr";

export class ExtraAttributeToggle extends React.Component {
  static propTypes = {
    header: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    orderId: PropTypes.number.isRequired,
    attributeId: PropTypes.number.isRequired,
    setAttributeValue: PropTypes.func.isRequired
  };

  static defaultProps = {
    header: null
  };

  state = {
    status: ""
  };

  onChange = e => {
    this.setState({ status: "pending" });
    this.props
      .setAttributeValue(this.props.orderId, this.props.attributeId, e.target.checked)
      .then(() => {
        this.setState({ status: "success" });
      })
      .catch(err => {
        this.setState({ status: "error" });
        toastr.error("Kan eigenschap niet opslaan!", err.message);
      });
  };

  render() {
    return (
      <div className="margin-bottom-5">
        {this.props.header && <h3>{this.props.header}</h3>}
        <label className="layout-row">
          <input
            disabled={this.state.status === "pending"}
            onChange={this.onChange}
            type="checkbox"
            defaultChecked={!!this.props.value}
          />
          {this.props.title}
        </label>
      </div>
    );
  }
}

export default connect(
  () => ({}),
  {
    setAttributeValue
  }
)(ExtraAttributeToggle);
